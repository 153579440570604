import React from "react";
import { useEffect, useState } from "react";
import { Route, Navigate, useNavigate } from "react-router-dom";
import authServices from "../../services/auth.services";
import apiServices from "../../services/api.services";

const ProtectedRoute = () => {
  const [route, setRoute] = useState(false);
  const navigate = useNavigate();

  const getLatestVersion = async () => {
    const response = await apiServices.getVersion();
    if (response) {
      setRoute(response.maintenance_mode);
    }
  };

  useEffect(() => {
    getLatestVersion();
  }, []);
  if (route === "true") {
    return <Navigate to="/maintenance" replace></Navigate>;
  } else {
    return <></>;
  }
};

export default ProtectedRoute;
