import { FieldArray, useFormikContext } from "formik";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import {
  handleChargePrice,
  handleSelectedPackage,
} from "../../redux/reducer/checkoutReducer";
import { useEffect } from "react";
export default function InputCount({ item, index }) {
  const dispatch = useDispatch();
  // const { values,setFieldValue } = useFormikContext()
  const { serviceQuantity } = useSelector((state) => state.checkout);
  const { ticket } = useSelector((state) => state.checkout.checkoutData);
  const { values, setValues, setFieldValue } = useFormikContext();
  const [number, setNumber] = useState(0);

  const handleIncrement = (id) => {
    if (values.room_service_category_item_id.length > 0) {
      values.room_service_category_item_id.map((service, index) => {
        if (id in service) {
          setFieldValue(`room_service_category_item_id[${index}]`, {
            [id]: number + 1,
          });
        } else {
          const item = [
            ...values.room_service_category_item_id,
            { [id]: number + 1 },
          ];
          setFieldValue("room_service_category_item_id", item);
        }
      });
    } else {
      const item = [
        ...values.room_service_category_item_id,
        { [id]: number + 1 },
      ];
      setFieldValue("room_service_category_item_id", item);
    }
  };

  const handleDecrement = (id) => {
    values.room_service_category_item_id.map((service, index) => {
      if (id in service) {
        if (number <= 1) {
          setValues({
            ...values,
            room_service_category_item_id:
              values.room_service_category_item_id.filter(
                (newItem) => newItem[id] !== 1
              ),
          });
        } else {
          setFieldValue(`room_service_category_item_id[${index}]`, {
            [id]: number - 1,
          });
        }
      }
    });
  };

  return (
    <MDBRow className="count-field">
      <MDBCol className="service-label col-5">
        <label>{item.title}</label>
      </MDBCol>
      <MDBCol className="price col-3">
        <label>RM {parseFloat(item.current_price).toFixed(2)}</label>
      </MDBCol>
      <MDBCol className="button col-4">
        <Icon
          onClick={() => {
            if (!number <= 0) {
              setNumber(number - 1);
              handleDecrement(item.id);
              dispatch(
                handleChargePrice({
                  type: "decrement",
                  price: item.current_price,
                })
              );
            }
          }}
          icon="fa-regular:minus-square"
          color="#707070"
          width="30"
          height="25"
        />
        <label>{number}</label>
        <Icon
          onClick={() => {
            setNumber(number + 1);
            handleIncrement(item.id);
            dispatch(
              handleChargePrice({
                type: "increment",
                price: item.current_price,
              })
            );
          }}
          icon="material-symbols:add-box-outline-rounded"
          color="#707070"
          width="30"
          height="29"
        />
      </MDBCol>
      <MDBRow className="description">
        <label>{item.description}</label>
      </MDBRow>
    </MDBRow>
  );
}
