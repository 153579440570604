import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/full.modal.box";
import { RemoveScroll } from "react-remove-scroll";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../../components/header/header-five";

export default function ModalHistoryDetalis() {
  const {
    order_type,
    name,
    last_name,
    phone_number,
    email,
    payment_info,
    payment_items,
    room_info,
    total_price,
  } = useSelector((state) => state.payment.payment);

  const navigate = useNavigate();
  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="profile-modal-container">
            <Header title={"Details"} icon="icon" />
            <MDBContainer className="info-background">
              <MDBRow>
                <label>
                  <div></div>Detalis
                </label>
              </MDBRow>
              {room_info ? (
                <>
                  <MDBRow>
                    <label className="package-name">{room_info.title}</label>
                  </MDBRow>
                  <MDBRow>
                    <span>Date : {room_info.date}</span>
                  </MDBRow>
                  <MDBRow>
                    <p>Roow Pax : {room_info.pax} </p>
                  </MDBRow>
                  <MDBRow>
                    <p>{room_info.nights} Nights</p>
                  </MDBRow>
                  <MDBRow>
                    <p>
                      Paid per night : RM
                      {parseFloat(room_info.average).toFixed(2)}
                    </p>
                  </MDBRow>
                </>
              ) : payment_items ? (
                payment_items.map((history, index) => {
                  return (
                    <div key={index}>
                      <MDBRow>
                        <label className="package-name">
                          {history.ticket_package_title}
                        </label>
                      </MDBRow>
                      <MDBRow>
                        <span>{history.ticket_package_type_title}</span>
                      </MDBRow>
                      {history.ticket_package_type_item.map((item, index) => {
                        return (
                          <MDBRow key={index}>
                            <span>
                              {item.quantity}x&nbsp;
                              {item.ticket_package_type_item_title}
                            </span>
                          </MDBRow>
                        );
                      })}
                      {/* <MDBRow>
                        <label className="detalis-money">
                          RM&nbsp;{parseFloat(history.unit_price).toFixed(2)}
                        </label>
                      </MDBRow> */}
                    </div>
                  );
                })
              ) : (
                ""
              )}

              <label className="detalis-money">
                Total Paid: RM {parseFloat(total_price).toFixed(2)}
              </label>
            </MDBContainer>
            <MDBContainer className="info-background">
              <label>
                <div></div>Contact info
              </label>
              <MDBContainer className="info-data">
                <MDBRow>
                  <MDBCol>
                    <span>First name</span>
                  </MDBCol>
                  <MDBCol>{name}</MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <span>Last name</span>
                  </MDBCol>
                  <MDBCol>{last_name}</MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <span>Phone number</span>
                  </MDBCol>
                  <MDBCol>{phone_number}</MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <span>Email</span>
                  </MDBCol>
                  <MDBCol>{email}</MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBContainer>
            {payment_info ? (
              <MDBContainer className="info-background">
                <label>
                  <div></div>Payment info
                </label>
                <MDBContainer className="info-data">
                  <MDBRow>
                    <MDBCol>
                      <span>Pay by</span>
                    </MDBCol>
                    <MDBCol className="data">
                      {payment_info.payment_method}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <span>Bank</span>
                    </MDBCol>
                    <MDBCol className="data">
                      {payment_info.payment_name}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <span>Date and time</span>
                    </MDBCol>
                    <MDBCol className="data">{payment_info.payment_at}</MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <span>Transaction</span>
                    </MDBCol>
                    <MDBCol className="data">
                      {payment_info.transaction_id}
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBContainer>
            ) : (
              ""
            )}
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
