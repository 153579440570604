import { MDBCardImage, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

//component
import Layout from "../../components/common/screen-two";
import Header from "../../components/header/header-four";
import PlaningQR from "./component/qr.code";
import PlaningTicket from "./component/ticket.detalis";

//image
import { Promotion1 } from "../../assets/images";
import LoaderSvg from "../../assets/images/svg/loader.svg";
//redux
import { useSelector, useDispatch } from "react-redux";
import { planningInfo } from "../../redux/reducer/planingReducer";

export default function PlaningDetail() {
  const { planningId } = useParams();
  const { isOpenPlanningPayment } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const { image } = useSelector((state) => state.planning.planningInfo);
  const { loading } = useSelector((state) => state.planning);

  useEffect(() => {
    dispatch(planningInfo({ id: planningId }));
  }, []);

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#FFFFFF");
  }

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  return (
    <MDBContainer className="planing-listing-container" breakpoint="none">
      <Layout />
      <MDBCardImage className="header-image" src={image} width="100%" />
      <div className="scroll-component">
        <MDBContainer className="header-container">
          <Header title="My Ticket" />
          {loading ? (
            <div className="loader-container">
              <img src={LoaderSvg} height="50" width="50" />
            </div>
          ) : (
            <>
              <PlaningQR planningId={planningId} />
              <PlaningTicket />
            </>
          )}
        </MDBContainer>
      </div>
    </MDBContainer>
  );
}
