import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from '@iconify/react';
// import { handleModalboxAgent } from "../../../redux/reducer/agentReducer";
import { closeModalAgent } from "../../../redux/reducer/modalReducer";

export default function BannerAgent({activeTab,handleChangeTab}){
    const dispatch = useDispatch()
    const { comission } = useSelector((state) => state.agent)

    return(
        <div className="banner-agent-container">
            <MDBContainer>
                <MDBRow className='header-row'>
                    <MDBCol className='col-2 d-flex justify-content-center p-0'>
                        <div className='round-background-icon' onClick={() => dispatch(closeModalAgent())}>
                            <Icon icon="entypo:cross" color="#707070" width="30" height="27" />
                        </div>
                    </MDBCol>
                    <MDBCol className="col-8 text-center">
                            <label>Welcome Back</label>
                    </MDBCol>
                    <MDBCol className="col-2">
                            <label>Help</label>
                    </MDBCol>
                </MDBRow>
                <MDBContainer className="available-container">
                    <MDBRow className="header-label"><label>Available Commission</label></MDBRow>
                    <MDBRow className="comission-label"><label><span>$</span>{comission}</label></MDBRow>
                </MDBContainer>
                <MDBRow className="toggle-container">
                    <div className="background">
                        <div 
                            className={activeTab === "tab-1" ? "tab-1 active" : "tab-1"} 
                            onClick={()=> handleChangeTab("tab-1")}
                        >
                            <label>Wallet</label>
                        </div>
                        <div 
                            className={activeTab === "tab-2" ? "tab-2 active" : "tab-2" }
                            onClick={()=> handleChangeTab("tab-2")}
                        >
                            <label>Room</label>
                        </div>
                    </div>
                </MDBRow>
            </MDBContainer>
        </div>
    )
}