import { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import LoaderSvg from "../../assets/images/svg/loader.svg";

//common component
import Header from "../../components/header/header-two";
import Layout from "../../components/common/screen-two";

//component
import Banner from "../Home/component/banner";
import PromotionCard from "./component/promotion.card";
import Login from "../Login";
//modalbox
import ModalBox from "../../components/modal/half.modal.box";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/reducer/modalReducer";
import { promotion } from "../../redux/reducer/promotionReducer";

export default function Promotion() {
  const dispatch = useDispatch();
  const { isOpenLogin } = useSelector((state) => state.modal);
  const { loading } = useSelector((state) => state.promotion);
  useEffect(() => {
    dispatch(promotion());
  }, []);

  useEffect(() => {
    document.body.style.background = "#ffffff";
  }, []);

  return (
    <div className="promotion-page-container">
      <MDBContainer className="promotion-desktop">
        <Header title={"Happening now"} home="home" />
        <Layout />

        <MDBContainer className="content-component" data-aos="fade-up">
          <MDBRow>
            <MDBCol className="promotion-title-text">Limited Deals</MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="promotion-title-text2">
              Egestas quis amet neque, eu ullamcorper velit. Semper id ac nibh
              diam lorem rhoncus faucibus lectus amet.
            </MDBCol>
          </MDBRow>
          <div className="fixed-container" breakpoint="none">
            {loading ? (
              <div className="loader-container">
                <img src={LoaderSvg} height="50" width="50" />
              </div>
            ) : (
              <PromotionCard />
            )}
          </div>
        </MDBContainer>
      </MDBContainer>
      {isOpenLogin && <Login show={isOpenLogin} />}
    </div>
  );
}
