import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const event = createAsyncThunk("get/event",
    async() => {
        const response = await apiServices.getEvent()
        return response
    }  
)

const initialState = {
    history: [],
    listing: [],
    place: [],
    activity: [],
    loading: false
}

const eventSlice = createSlice({
    name: 'event',
    initialState,
    extraReducers:{
        [event.pending]:(state,action)=>{
            state.loading = true
        },
        [event.fulfilled]:(state,action)=>{
            state.loading = false
            state.history = action.payload.history
            state.listing = action.payload.ticket
            state.place = action.payload.place_to_stay
            state.activity = action.payload.activity
        },
    }
})

const { reducer } = eventSlice
export default reducer