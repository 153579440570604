import { useEffect } from "react";
import { MDBCardImage, MDBContainer } from "mdb-react-ui-kit";

//common
import Layout from "../../components/common/screen-two";
import Header from "../../components/header/header-two";

//component
import Place from "./component/place";
import ComingEvent from "./component/coming.event";
import EventStory from "./component/event.story";
import Activity from "./component/activity";
import Login from "../Login";

//images
import { EventHeader } from "../../assets/images";
import LoaderSvg from "../../assets/images/svg/loader.svg";

//redux
import { useDispatch, useSelector } from "react-redux";
import { event } from "../../redux/reducer/eventReducer";
export default function Event() {
  const { isOpenLogin } = useSelector((state) => state.modal);
  const { loading } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.body.style.background = "#ffffff";
  }, []);

  useEffect(() => {
    dispatch(event());
    scrollToTop();
  }, []);

  return (
    <MDBContainer className="event-container" breakpoint="none">
      <Layout />
      <div className="header-event">
        <Header title="Gold Coast Event" home="home" />
      </div>
      <MDBCardImage className="header-image" src={EventHeader} width="100%" />
      <div className="scroll-component" style={{ marginTop: "7em" }}>
        {loading ? (
          <div className="loader-container">
            <img src={LoaderSvg} height="50" width="50" />
          </div>
        ) : (
          <MDBContainer className="event-desktop">
            {/* <EventStory /> */}
            <ComingEvent />
            {/* <Place />
          <Activity /> */}
          </MDBContainer>
        )}
      </div>
      {isOpenLogin && <Login show={isOpenLogin} />}
    </MDBContainer>
  );
}
