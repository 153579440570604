import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import useWindowDimensions from "../../components/common/window-dimension";
import { Icon } from "@iconify/react";
import { LogoMainRound } from "../../assets/images";

//common component

export default function ErrorPage() {
  const [openQrCodeScan, setOpenQrCodeScan] = useState(false);
  const [payment, setPayment] = useState("11");

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container-maintenance">
        <div className="page-container-maintenance">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <img src={LogoMainRound} className="logo" /> &nbsp;
                <label className="title">gcm.ax-tech.co</label>
              </MDBCol>
            </MDBRow>
            <Icon icon="mdi:scheduled-maintenance" className="icon" />
            <MDBRow>
              <label>The site is currently down for maintenance</label>
            </MDBRow>
            <MDBRow>
              <p>We'll be back up and running again shortly</p>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    </MDBContainer>
  );
}
