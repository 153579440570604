import { configureStore } from "@reduxjs/toolkit";

//reducer
import modalReducer from "./reducer/modalReducer";
import homeReducer from "./reducer/homeReducer";
import activityReducer from "./reducer/activityReducer";
import eventReducer from "./reducer/eventReducer";
import placeReducer from "./reducer/placeReducer";
import promotionReducer from "./reducer/promotionReducer";
import planingReducer from "./reducer/planingReducer";
import bookingReducer from "./reducer/booking.reducer";
import serviceReducer from "./reducer/serviceReducer";
import checkoutReducer from "./reducer/checkoutReducer";
import paymentReducer from "./reducer/paymentReducer";
import profileReducer from "./reducer/profileReducer";
import detailReducer from "./reducer/detailReducer";
import agentReducer from "./reducer/agentReducer";
import walletReducer from "./reducer/walletReducer";
import service_history from "./reducer/serviceHistoryReducer";
import loaderReducer from "./reducer/loaderReducer";
const reducer = {
  modal: modalReducer,
  home: homeReducer,
  activity: activityReducer,
  event: eventReducer,
  place: placeReducer,
  promotion: promotionReducer,
  service: serviceReducer,
  checkout: checkoutReducer,
  payment: paymentReducer,
  booking: bookingReducer,
  planning: planingReducer,
  profile: profileReducer,
  detail: detailReducer,
  agent: agentReducer,
  wallet: walletReducer,
  service_history: service_history,
  loader:loaderReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
