import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";

import { LogoMain, LogoMainRound } from "../../assets/images";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { openModalServiceHistory } from "../../redux/reducer/modalReducer";

export default function Header({ setSubmit }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { room_no } = useSelector((state) => state.service.room_detail);
  const room = localStorage.getItem("room_no");

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#FFFFFF");
  }

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  return (
    <MDBNavbar className="header-three-container">
      <MDBContainer>
        <MDBRow className="header-row">
          <MDBCol className="col-2 d-flex justify-content-center p-0">
            <div className="round-background-icon">
              <Icon
                icon="entypo:cross"
                color="#707070"
                width="30"
                height="27"
                onClick={() => {
                  setSubmit(false);
                  localStorage.removeItem("room_no");
                  localStorage.removeItem("contact_no");
                }}
              />
            </div>
          </MDBCol>
          <MDBCol className="col-6 col-md-8 text-end">
            <div className="badge">
              <label>Room: {room_no || room}</label>
            </div>
          </MDBCol>
          <MDBCol className="col-4 col-md-2">
            <div className="badge">
              <label onClick={() => dispatch(openModalServiceHistory())}>
                History
              </label>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBNavbar>
  );
}
