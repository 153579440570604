import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//component
import Header from "../../components/header/header-five";
import Contact from "./component/contact";
import ContactForm from "./component/contact.form";
import Detail from "./component/detail";
import PaymentMethod from "./component/payment.method";
import ConfirmModal from "./component/confirm.modal";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  completePayment,
  paymentInfo,
} from "../../redux/reducer/paymentReducer";
import { openModalConfirmPayment } from "../../redux/reducer/modalReducer";

export default function Payment() {
  const [credit, setCredit] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { paymentId } = useParams();
  const { isOpenContact, isOpenConfirmPayment } = useSelector(
    (state) => state.modal
  );
  const { order_type, name, last_name, phone_number, email } = useSelector(
    (state) => state.payment.payment
  );
  const dataProfile = useSelector((state) => state.profile.data);

  const [data, setData] = useState({
    reference_no: paymentId ? paymentId : "",
    name: dataProfile.first_name ? dataProfile.first_name : name ? name : "",
    last_name: dataProfile.last_name
      ? dataProfile.last_name
      : last_name
      ? last_name
      : "",
    email: dataProfile.email ? dataProfile.email : email ? email : "",
    phone_number: dataProfile.phone_number
      ? dataProfile.phone_number
      : phone_number
      ? phone_number
      : "",
    payment_method: credit ? credit : "",
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
    if (dataProfile) {
      setData({
        reference_no: paymentId,
        name: dataProfile.first_name,
        last_name: dataProfile.last_name,
        email: dataProfile.email,
        phone_number: dataProfile.phone_number,
        payment_method: credit,
      });
    }
  }, [dataProfile]);

  useEffect(() => {
    document.body.style.background = "#ffffff";
  }, []);

  useEffect(() => {
    if (name && order_type === "room service") {
      setData({
        reference_no: paymentId,
        name: name,
        last_name: last_name,
        email: email,
        phone_number: phone_number,
        payment_method: credit,
      });
    }
  }, [name]);

  useEffect(() => {
    dispatch(paymentInfo({ payload: paymentId }));
  }, [paymentId]);

  const schema = Yup.object({
    reference_no: Yup.string().required(),
    name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().required(),
    phone_number: Yup.string().required(),
  });

  const handleSubmit = (values) => {
    dispatch(
      completePayment({
        payload: {
          payment_method: credit,
          reference_no: paymentId,
          name: values.name,
          last_name: values.last_name,
          email: values.email,
          phone_number: values.phone_number,
        },
      })
    )
      .unwrap()
      .then((res) => {
        if (credit === "credit") {
          if (order_type == "room service") {
            navigate("/service");
            toast.success("Order Successful", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
          } else {
            navigate("/planning");
            toast.success("Booking Successful", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
          }
        }
        if (credit === "online payment") {
          let link = res.data;
          window.location.replace(link);
          toast.info("Redirecting", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        } else {
        }
        // dispatch(handleModalBoxCheckOut());
      })
      .catch((ex) => {
        const errors = ex.response.data.errors;
        let errMsg = "";
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item) => {
            errMsg = errors[item];
          });
        }
        toast.error(errMsg[0], {
          theme: "colored",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      });
  };

  return (
    <MDBContainer className="payment-container">
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={data}
        validationSchema={order_type === "ticket" ? schema : ""}
        onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
          handleSubmit(values, setFieldError, setSubmitting);
          // resetForm({ values: ""})
        }}
      >
        {({ setFieldValue, errors, status, values }) => (
          <Form className="form">
            <Header title="Complete payment" payment />
            <Detail />
            <Contact data={data} />
            {/* <Contact data={data} /> */}
            {isOpenContact && (
              <ContactForm
                show={isOpenContact}
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
            {isOpenConfirmPayment && (
              <ConfirmModal
                show={isOpenConfirmPayment}
                handleSubmit={handleSubmit}
                values={values}
              />
            )}
            <PaymentMethod setCredit={setCredit} credit={credit} />
            <MDBContainer className="bottom-payment">
              <MDBRow className="notice-container">
                <label>
                  <span className="t-and-c">Term & condition </span> <br />
                  <span className="t-and-c-list">
                    Cancellation and Revision{""}.
                  </span>
                  {""}
                  &nbsp;Order cannot be changed or canceled after successful
                  payment
                </label>
                <label>
                  <span>Must </span> enter your info carefully. Once submitted
                  it cannot be change.
                </label>
              </MDBRow>
            </MDBContainer>
          </Form>
        )}
      </Formik>
      <MDBContainer>
        <MDBRow className="form-group btn-buy-now">
          <MDBBtn onClick={() => dispatch(openModalConfirmPayment())}>
            Continue to payment
          </MDBBtn>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  );
}
