import { FieldArray, useFormikContext } from "formik";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import {
  handleChargePrice,
  handleSelectedPackage,
} from "../../redux/reducer/checkoutReducer";
import { useEffect } from "react";
export default function InputCheckbox({ item, index }) {
  const dispatch = useDispatch();
  // const { values,setFieldValue } = useFormikContext()
  const { serviceQuantity } = useSelector((state) => state.checkout);
  const { ticket } = useSelector((state) => state.checkout.checkoutData);
  const { values, setValues, setFieldValue } = useFormikContext();
  const [number, setNumber] = useState(0);
  const [checked, setChecked] = useState(false);

  const handleCheckBox = (id, checkboxValues, current_price) => {
    if (checkboxValues) {
      const item = [...values.room_service_category_item_id, { [id]: 1 }];
      setFieldValue("room_service_category_item_id", item);
      dispatch(
        handleChargePrice({
          type: "increment",
          price: current_price,
        })
      );
    } else {
      setValues({
        ...values,
        room_service_category_item_id:
          values.room_service_category_item_id.filter(
            (newItem) => newItem[id] !== 1
          ),
      });
      dispatch(
        handleChargePrice({
          type: "decrement",
          price: current_price,
        })
      );
    }
  };

  return (
    <MDBRow className="count-field">
      <MDBCol className="service-label col-5">
        <label>{item.title}</label>
      </MDBCol>
      <MDBCol className="price col-5">
        <label>RM {parseFloat(item.current_price).toFixed(2)}</label>
      </MDBCol>
      <MDBCol className="checkbox col-2 text-end">
        <Icon
          onClick={() => {
            setChecked(!checked);
            handleCheckBox(item.id, !checked, item.current_price);
          }}
          icon={checked ? "ci:checkbox-checked" : "ci:checkbox"}
          color="#707070"
          width="40"
          height="35"
        />
      </MDBCol>
      <MDBRow className="description">
        <label>{item.description}</label>
      </MDBRow>
    </MDBRow>
  );
}
