import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const home = createAsyncThunk("get/homepage",
    async() => {
        const response = await apiServices.getHome()
        return response
    }  
)

export const banner = createAsyncThunk("get/banner",
    async() => {
        const response = await apiServices.getBanner()
        return response
    }  
)

const initialState = {
    promotion: [],
    place: [],
    banner:[],
    event: [],
    activity: [],
    room:[],
    loading: false
}

const homeSlice = createSlice({
    name: 'home',
    initialState,
    extraReducers:{
        [home.pending]:(state,action)=>{
            state.loading = true
        },
        [home.fulfilled]:(state,action)=>{
            state.loading = false
            state.room = action.payload.room
            state.promotion = action.payload.promotion
            state.place = action.payload.place
            state.event = action.payload.event
            state.activity = action.payload.activity
        },
        [banner.pending]:(state,action)=>{
            state.loading = true
        },
        [banner.fulfilled]:(state,action)=>{
            state.loading = false
            state.banner = action.payload
        },
    }
})

const { reducer } = homeSlice
export default reducer