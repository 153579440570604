import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const booking = createAsyncThunk("get/booking", async () => {
  const response = await apiServices.getBooking();
  return response;
});

export const bookingInfo = createAsyncThunk(
  "get/bookingInfo",
  async ({ id }) => {
    const response = await apiServices.getBookingInfo(id);
    return response;
  }
);

const initialState = {
  listing: [],
  loading: false,
  roomInfo: {},
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers:{
    sortingLisitng: (state,action) => {
      state.listing = action.payload
    }
  },
  extraReducers: {
    [booking.pending]: (state, action) => {
      state.loading = true;
    },
    [booking.fulfilled]: (state, action) => {
      state.loading = false;
      state.listing = action.payload;
    },
    [bookingInfo.pending]: (state, action) => {
      state.loading = true;
      state.roomInfo = {};
    },
    [bookingInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.roomInfo = action.payload;
    },
  },
});
export const { sortingLisitng } = bookingSlice.actions;
const { reducer } = bookingSlice;
export default reducer;
