import { useEffect } from "react";

import { MDBContainer, MDBCol } from "mdb-react-ui-kit";
import Login from "../Login";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/common/screen-two";
import Header from "../../components/header/header-two";
import ActivityListing from "./component/activity.listing";
import { activity } from "../../redux/reducer/activityReducer";
import { openModalLogin } from "../../redux/reducer/modalReducer";

export default function Activity() {
  const { isOpenLogin } = useSelector((state) => state.modal);

  const dispatch = useDispatch();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.body.style.background = "#ffffff";
  }, []);

  useEffect(() => {
    dispatch(activity());
    scrollToTop();
  }, []);

  return (
    <MDBContainer className="activity-container" breakpoint="none">
      <div className="container-desktop">
        <Header title="Activity" home="home" />
        <Layout />
        <MDBContainer className="container-listing-activity">
          <label className="header-activity">
            What can do in your Morib Trip
          </label>
          <MDBCol className="activity-title-text2">
            Egestas quis amet neque, eu ullamcorper velit. Semper id ac nibh
            diam lorem rhoncus faucibus lectus amet.
          </MDBCol>
        </MDBContainer>
        <div className="scroll-container">
          <ActivityListing />
        </div>
      </div>
      {isOpenLogin && <Login show={isOpenLogin} />}
    </MDBContainer>
  );
}
