import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/full.modal.box";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import * as Yup from "yup";
import { Formik, Field, Form, FormikConsumer } from "formik";
import { Promotion1 } from "../../../assets/images";
import Header from "../../../components/header/header-five";
import { RemoveScroll } from "react-remove-scroll";
import InputField from "../../../components/element/inputField";

export default function ModalReport() {
  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="profile-modal-container">
            <Header title={"Report"} />
            <MDBContainer className="no-data p-5">
              <label>No record yet ~</label>
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
