import { MDBContainer, MDBModalBody } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import ModalBox from "../../components/modal/full.modal.box";
import BannerWallet from "./component/banner.container";
import CreditDetail from "./component/topup";
import CreditHistory from "./component/credit.history";
import { RemoveScroll } from "react-remove-scroll";

//redux
import { useDispatch, useSelector } from "react-redux";

import { wallet } from "../../redux/reducer/walletReducer";
export default function Wallet() {
  const dispatch = useDispatch();
  const [type, setType] = useState("credit");
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date

  useEffect(() => {
    dispatch(wallet({ search, startDate, endDate, length, start, type }));
  }, []);

  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        className="wallet-container"
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody>
            <BannerWallet />
            <div className="wallet-content-container">
              <CreditDetail />
              <CreditHistory />
            </div>
            {/* {modalboxAddBank && <ModalBoxBank show={modalboxAddBank}/>} */}
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
