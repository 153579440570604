import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";

import { LogoMain, LogoMainRound } from "../../assets/images";
import HeaderThree from "./header-three";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalLogin } from "../../redux/reducer/modalReducer";
import Login from "../../pages/Login";

export default function Header({ headerThree, setSubmit }) {
  const [animation, setAnimation] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpenLogin } = useSelector((state) => state.modal);
  const token = localStorage.getItem("token");

  useEffect(() => {
    desktopResolutionInit();
  }, []);

  const desktopResolutionInit = () => {
    const { innerWidth: width, innerHeight: height } = window;

    if (width > 0) {
      setAnimation(!animation);
    }
  };
  const toggleVisible = () => {
    const { innerWidth: width, innerHeight: height } = window;
    const scrolled = document.documentElement.scrollTop;
    if (width < 1000) {
      if (scrolled > 100000) {
        setAnimation(true);
      } else if (scrolled <= 100000) {
        setAnimation(false);
      }
    }
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <header className="common-header-main fixed-top">
      {headerThree ? (
        <HeaderThree setSubmit={setSubmit} />
      ) : (
        <MDBNavbar className="header-one-container">
          <MDBContainer>
            <MDBRow className="header-row">
              <MDBCol
                className="col-2 logo-container"
                onClick={() => setAnimation(!animation)}
              >
                <img
                  className={animation ? "logo-button after" : "logo-button"}
                  src={LogoMainRound}
                />
              </MDBCol>
              <div className={animation ? "animation after" : "animation"}>
                <MDBCol className="col-2 text-start">
                  {/* <img src={LogoMainRound} width="45em"/> */}
                </MDBCol>
                <MDBCol
                  className="col-2 justify-content-center text-center align-items-center d-flex"
                  onClick={() => {
                    navigate("/room");
                  }}
                >
                  <Icon
                    icon="ion:bed"
                    // color="darkgray"
                    width="30"
                    height="27"
                  />
                </MDBCol>
                <MDBCol
                  className="col-2 justify-content-center text-center align-items-center d-flex"
                  onClick={() => {
                    navigate("/activity");
                  }}
                >
                  <Icon
                    icon="bx:joystick"
                    // color="darkgray"
                    width="24"
                    height="24"
                  />
                </MDBCol>
                <MDBCol
                  className="col-2 justify-content-center text-center align-items-center d-flex"
                  onClick={() => {
                    navigate("/place");
                  }}
                >
                  <Icon
                    icon="maki:place-of-worship"
                    // color="darkgray"
                    width="24"
                    height="24"
                  />
                </MDBCol>
                <MDBCol
                  className="col-2 justify-content-center text-center align-items-center d-flex"
                  onClick={() => {
                    navigate("/event");
                  }}
                >
                  <Icon
                    icon="ic:round-curtains"
                    // color="darkgray"
                    width="30"
                    height="27"
                  />
                </MDBCol>
                <MDBCol
                  className="col-2 justify-content-center text-center align-items-center d-flex"
                  onClick={() => {
                    navigate("/promotion");
                  }}
                >
                  <Icon
                    icon="tabler:discount-2"
                    // color="darkgray"
                    width="30"
                    height="27"
                  />
                </MDBCol>
              </div>
            </MDBRow>
          </MDBContainer>
        </MDBNavbar>
      )}
      {isOpenLogin && <Login />}
    </header>
  );
}
