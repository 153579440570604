import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const ticketInfo = createAsyncThunk("get/detail", async ({ id }) => {
  const response = await apiServices.getInfo(id);
  return response;
});

const initialState = {
  ticketInfo: {
    title: "Welcome to Morib",
    tag: [{
        text: "Must Go",
        color: "c9c9c9",
    }],
    detail: []
  },
  loading: false,
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    addAdditionalService: (state, action) => {},
  },
  extraReducers: {
    [ticketInfo.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        ticketInfo: {
          title: "Welcome to Morib",
          tag: [{
              text: "Must Go",
              color: "c9c9c9",
          }],
        },
      };
    },
    [ticketInfo.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        ticketInfo: action.payload,
      };
    },
  },
});
export const { addSelectedPackage, addAdditionalService } = ticketSlice.actions;
const { reducer } = ticketSlice;
export default reducer;
