import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useState } from "react";

//redux
import { useSelector } from "react-redux";
export default function PropertyPolicies() {
  const { policy } = useSelector((state) => state.booking.roomInfo);

  return (
    <MDBCol className="operation-container col-12">
      <MDBRow className="container-title">
        <label>
          <div></div>Property policies
        </label>
      </MDBRow>
      <MDBContainer className="content-container">
        <MDBRow className="p-1">
          <label className="d-flex">
            <Icon
              icon="ant-design:field-time-outlined"
              width="20"
              height="18"
            />
            Check-in Time {"8.00 am"}
          </label>
        </MDBRow>
        <MDBRow className="p-1">
          <label className="d-flex">
            <Icon
              icon="ant-design:field-time-outlined"
              width="20"
              height="18"
            />
            Check-out Time {"10.00 pm"}
          </label>
        </MDBRow>
      </MDBContainer>
    </MDBCol>
  );
}
