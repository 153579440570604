import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

export default function WhatToExpect() {
  const { expectation } = useSelector((state) => state.detail.ticketInfo);
  const { loading } = useSelector((state) => state.detail);

  return (
    <MDBCol className="expect-container col-12">
      {loading ? (
        ""
      ) : (
        <>
          <MDBRow className="container-title">
            <label>
              <div></div>What to Expect
            </label>
          </MDBRow>
          <MDBContainer className="content-container">
            {expectation
              ? expectation.map((item, index) => {
                  return (
                    <MDBRow key={index} className="content-container-row">
                      <MDBCol className="p-0">
                        <MDBCardImage src={item.image} width="100%" />
                        <MDBRow className="description">
                          <label>
                            <Icon
                              icon="fa6-solid:square-caret-up"
                              color="#888daa"
                              width="16"
                              height="16"
                            />
                            <p>{item.text}</p>
                          </label>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  );
                })
              : ""}
          </MDBContainer>
        </>
      )}
    </MDBCol>
  );
}
