import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

//common component
import Layout from "../../components/common/screen-one";
import Header from "../../components/header/header-one";
import Navbar from "../../components/navbar/navbar-one";
import Login from "../Login";

// component
import ActivityCard from "./component/activity";
import BannerCarousel from "./component/banner";
import EventCard from "./component/event";
import PlaceCarousel from "./component/place";
import PromotionCarousel from "./component/promotion";
import RoomCarousel from "./component/room";

//redux
import { useDispatch, useSelector } from "react-redux";
import { banner, home } from "../../redux/reducer/homeReducer";
import { openModalLogin } from "../../redux/reducer/modalReducer";
import LoaderSvg from "../../assets/images/svg/loader.svg";

export default function Home() {
  const dispatch = useDispatch();
  // const { referral_code } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(searchParams.get("paramName"));
  // setSearchParams((newParams) => {
  //   newParams.set("paramName", "newValue");
  // });
  const referral_code = searchParams.get("referral_code");
  const { isOpenLogin } = useSelector((state) => state.modal);
  const { event, place, promotion, activity, room, loading } = useSelector(
    (state) => state.home
  );
  const [image0, setImage0] = useState([]);

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      // .setAttribute("content", "#12B6DF");
      .setAttribute("content", "#fafbfb");
  }

  useEffect(() => {
    document.body.style.background = "#ffffff";
  }, []);

  const setPromotion = () => {
    if (promotion.length <= 0) {
      setImage0("");
    } else {
      setImage0(promotion);
    }
  };

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    dispatch(home());
    dispatch(banner());
    setPromotion();
    scrollToTop();
    if (referral_code !== null) {
      dispatch(openModalLogin());
    }
  }, []);

  useEffect(() => {
    setPromotion();
  }, [promotion.length <= 0]);

  return (
    <MDBContainer className="home-container">
      <Layout home={home} />
      <Header />
      <Navbar />
      {loading ? (
        <div className="loader-container">
          <img src={LoaderSvg} height="50" width="50" />
        </div>
      ) : (
        <div
          className="content-component"
          // data-aos="fade-up"
        >
          <BannerCarousel image0={image0} />
          {promotion.length <= 0 ? "" : <PromotionCarousel />}
          {room.length <= 0 ? "" : <RoomCarousel />}
          {place.length <= 0 ? "" : <PlaceCarousel />}
          <div className="grid-container">
            {activity.length <= 0 ? "" : <ActivityCard />}
            {event.length <= 0 ? "" : <EventCard />}
          </div>
        </div>
      )}
      {isOpenLogin && (
        <Login referral_code={referral_code} show={isOpenLogin} />
      )}
      {/* <ButtonScrollTop /> */}
    </MDBContainer>
  );
}
