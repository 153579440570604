import { Formik, Form, Field, replace } from "formik";
import { MDBCol, MDBContainer, MDBRadio, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { topup } from "../../../redux/reducer/paymentReducer";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { closeModalWallet } from "../../../redux/reducer/modalReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { balance } from "../../../redux/reducer/profileReducer";

import Button from "../../../components/element/button";

export default function CreditDetail() {
  const [wallet, setWallet] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({
    amount: "",
  });
  const dataSchema = Yup.object({
    amount: Yup.number().typeError(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    if (values !== "") {
      dispatch(topup({ payload: { amount: values.amount } }))
        .unwrap()
        .then((res) => {
          let link = res;
          window.location.replace(link);
          dispatch(closeModalWallet());
        })
        .catch((ex) => {
          const errors = ex.response.data.errors;
          let errMsg = "";
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item) => {
              errMsg = errors[item];
            });
          }
          toast.error(errMsg[0], {
            theme: "colored",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
        });
    } else {
      setSubmitting(false);
      toast.error("Plase enter Topup Amount", {
        theme: "colored",
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
      });
    }
  };

  const onMoneyChange = (e, setFieldValue) => {
    const money = e.target.value;
    setFieldValue("amount", money);
    setWallet(false);
  };

  return (
    <MDBContainer className="wallet-detail-container">
      <Formik
        initialValues={data}
        validationSchema={dataSchema}
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(values, { setFieldValue, setSubmitting }) => {
          handleSubmit(values, { setSubmitting });
          setFieldValue("amount", "");
        }}
      >
        {({ setFieldValue, values, isValid, isSubmitting }) => (
          <Form>
            <div className="bank-info">
              <MDBRow className="input-container m-0">
                <MDBCol className="input-field col-8">
                  <span className="wallet-topup">RM</span>
                  <Field
                    name="withdraw"
                    type="number"
                    placeholder=" 0.00"
                    value={values.amount}
                    onChange={(e) => onMoneyChange(e, setFieldValue)}
                  />
                  {wallet ? <Icon icon="lucide:edit-3" /> : ""}
                </MDBCol>
                <MDBCol className="button ">
                  <Button
                    label="Top Up"
                    type="submit"
                    isValid={isValid}
                    // isSubmitting={isSubmitting}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBRadio
                    name="options"
                    id="50"
                    label="RM 50"
                    btn
                    value="50"
                    onChange={(e) => onMoneyChange(e, setFieldValue)}
                    checked={values.amount === "50"}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBRadio
                    name="options"
                    id="100"
                    label="RM 100"
                    btn
                    value="100"
                    onChange={(e) => onMoneyChange(e, setFieldValue)}
                    checked={values.amount === "100"}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBRadio
                    name="options"
                    id="200"
                    label="RM 200"
                    btn
                    value="200"
                    onChange={(e) => onMoneyChange(e, setFieldValue)}
                    checked={values.amount === "200"}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBRadio
                    name="options"
                    id="500"
                    label="RM 500"
                    btn
                    value="500"
                    onChange={(e) => onMoneyChange(e, setFieldValue)}
                    checked={values.amount === "500"}
                  />
                </MDBCol>
              </MDBRow>
              <span className="text">* No Refund after Top up</span>
            </div>
          </Form>
        )}
      </Formik>
    </MDBContainer>
  );
}
