import "./App.scss";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

//toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css/bundle";

//pages
import Room from "./pages/Room";
import RoomDetalis from "./pages/RoomDetalis";
import Home from "./pages/Home";
import Planing from "./pages/Planing";
import Service from "./pages/Service";
import Profile from "./pages/Profile";
import Place from "./pages/Place";
import PlaceDetail from "./pages/PlaceDetail";
import Activity from "./pages/Activity";
import ActivityDetail from "./pages/ActivityDetail";
import Event from "./pages/Event";
import EventDetail from "./pages/EventDetail";
import Promotion from "./pages/Promotion";
import PromotionDetail from "./pages/Promotion.Detail";
import Payment from "./pages/Payment";
import TicketDetail from "./pages/TicketDetail";
import PlaningDetail from "./pages/PlaningDetail";
import VersionModal from "./components/modal/version.modal";
import ErrorPage from "./pages/404page";
import { useEffect } from "react";
import apiServices from "./services/api.services";
import config from "./config.json"
import { useState } from "react";

function App() {
  const [versionModal,setVersionModal] = useState(false)
  const getLatestVersion = async () => {
    const response = await apiServices.getVersion()
    if(response.api_version !== config.version){
      setVersionModal(true)
    }
  }
  useEffect(() => {
    getLatestVersion()
  },[])

  return (
    <BrowserRouter>
      <Routes>
        {/* Header */}
        <Route path="/room" element={<Room />} />
        <Route path="/room/:roomId" element={<RoomDetalis />} />
        {/* NavBar */}
        <Route path="/" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/profile" element={<Profile />} />
        {/* Palaning */}
        <Route path="/planning" element={<Planing />} />
        <Route path="/planning/:planningId" element={<PlaningDetail />} />

        {/* Place */}
        <Route path="/place" element={<Place />} />
        {/* Promotion */}
        <Route path="/promotion" element={<Promotion />} />
        {/* Activity */}
        <Route path="/activity" element={<Activity />} />
        {/* Activity */}
        <Route path="/event" element={<Event />} />
        {/* Ticket */}
        <Route path="/ticket/:ticketId" element={<TicketDetail />} />
        {/* Payment */}
        <Route path="/payment/:paymentId" element={<Payment />} />
        <Route path="/maintenance" element={<ErrorPage />} />

      </Routes>
      <ToastContainer />
      {versionModal && <VersionModal show={versionModal} setShow={setVersionModal}/>}
    </BrowserRouter>
  );
}

export default App;
