import { MDBCardImage, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//component
import Layout from "../../components/common/screen-two";
import Header from "../../components/header/header-two";

//image
import Operation from "./component/operation";
import Detail from "./component/detail";
import Package from "./component/package";
import History from "./component/history";
import WhatToExpect from "./component/what.to.expect";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ticketInfo } from "../../redux/reducer/detailReducer";
import Title from "./component/title";
import CheckOutModal from "../../components/modal/check.out.modal";
import Login from "../Login";
import { useState } from "react";
import useWindowDimensions from "../../components/common/window-dimension";
import {
  closeModalBoxCheckOut,
  handleModalBoxCheckOut,
} from "../../redux/reducer/checkoutReducer";
import LoaderSvg from "../../assets/images/svg/loader.svg";

export default function TicketDetail() {
  const { ticketId } = useParams();
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const { isOpenLogin } = useSelector((state) => state.modal);
  const { loading } = useSelector((state) => state.detail);
  const { modalBoxCheckOut } = useSelector((state) => state.checkout);
  const { image, history } = useSelector((state) => state.detail.ticketInfo);
  const packages = useSelector((state) => state.detail.ticketInfo.package);
  const [responsive, setResponsive] = useState("mobile");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.body.style.background = "#ffffff";
  }, []);

  useEffect(() => {
    dispatch(ticketInfo({ id: ticketId }));
    scrollToTop();
  }, []);

  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);

  useEffect(() => {
    dispatch(closeModalBoxCheckOut());
  }, []);

  return (
    <MDBContainer
      className={
        responsive === "mobile"
          ? "ticket-container p-0"
          : "ticket-container-desktop p-0"
      }
      breakpoint="none"
    >
      <Layout />
      <Header btnShare={true} responsive={responsive} ticketId="ticket" />
      {image ? <MDBCardImage className="header-image" src={image} /> : ""}
      <div className="scroll-component">
        {loading ? (
          <div className="loader-container">
            <img src={LoaderSvg} height="50" width="50" />
          </div>
        ) : (
          <MDBContainer className="grid-container">
            <MDBRow>
              <Title />
              {/* {responsive == "mobile" ? ( */}
              <>
                {loading ? (
                  ""
                ) : (
                  <div className="">
                    <Detail />
                    {history && history.length == 0 ? "" : <History />}
                    {packages ? <Package /> : ""}
                    {/* {packages ? (
                    <Package />
                  ) : history && history.length == 0 ? (
                    ""
                  ) : (
                    <History />
                  )} */}
                    <Operation />
                    <WhatToExpect />
                    {/* <Promotion /> */}
                  </div>
                )}
              </>
            </MDBRow>
          </MDBContainer>
        )}
      </div>
      {isOpenLogin && <Login show={isOpenLogin} />}
      {modalBoxCheckOut && <CheckOutModal show={modalBoxCheckOut} />}
    </MDBContainer>
  );
}
