import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenLogin: false,
  isOpenContact: false,
  isOpenLanguage: false,
  isOpenNotifacation: false,
  isOpenChangePassword: false,
  isOpenPurchaseHistory: false,
  isOpenReferral: false,
  isOpenProfile: false,
  isOpenHistoryDetalis: false,
  isOpenReport: false,
  isOpenPrivary: false,
  isOpenTos: false,
  isOpenPlanningPayment: false,
  isOpenAgent: false,
  isOpenWallet: false,
  isOpenServiceHistory: false,
  isOpenBookingDate: false,
  isOpenQrCode: false,
  isOpenPaymentDetail: false,
  isOpenConfirmPayment: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModalLogin: (state, action) => {
      state.isOpenLogin = true;
    },
    openModalLanguage: (state, action) => {
      state.isOpenLanguage = true;
    },
    openModalNotifacation: (state, action) => {
      state.isOpenNotifacation = true;
    },
    openModalChangePassword: (state, action) => {
      state.isOpenChangePassword = true;
    },
    openModalPurchaseHistory: (state, action) => {
      state.isOpenPurchaseHistory = true;
    },
    openModalReferral: (state, action) => {
      state.isOpenReferral = true;
    },
    openModalProfile: (state, action) => {
      state.isOpenProfile = true;
    },
    openModalHistoryDetalis: (state, action) => {
      state.isOpenHistoryDetalis = true;
    },
    openModalReport: (state, action) => {
      state.isOpenReport = true;
    },
    openModalPrivary: (state, action) => {
      state.isOpenPrivary = true;
    },
    openModalTos: (state, action) => {
      state.isOpenTos = true;
    },
    openModalPlanningPayment: (state, action) => {
      state.isOpenPlanningPayment = true;
    },
    openModalAgent: (state, action) => {
      state.isOpenAgent = true;
    },
    openModalWallet: (state, action) => {
      state.isOpenWallet = true;
    },
    openModalServiceHistory: (state, action) => {
      state.isOpenServiceHistory = true;
    },
    openModalBookingDate: (state, action) => {
      state.isOpenBookingDate = true;
    },
    openModalQrCode: (state, action) => {
      state.isOpenQrCode = true;
    },
    openModalPaymentDetail: (state, action) => {
      state.isOpenPaymentDetail = true;
    },
    openModalConfirmPayment: (state, action) => {
      state.isOpenConfirmPayment = true;
    },
    handleModalContact: (state, action) => {
      state.isOpenContact = !state.isOpenContact;
    },
    closeModalLogin: (state, action) => {
      state.isOpenLogin = false;
    },
    closeModalLanguage: (state, action) => {
      state.isOpenLanguage = false;
    },
    closeModalNotifacation: (state, action) => {
      state.isOpenNotifacation = false;
    },
    closeModalChangePasssword: (state, action) => {
      state.isOpenChangePassword = false;
    },
    closeModalPurchaseHistory: (state, action) => {
      state.isOpenPurchaseHistory = false;
    },
    closeModalReferral: (state, action) => {
      state.isOpenReferral = false;
    },
    closeModalProfile: (state, action) => {
      state.isOpenProfile = false;
    },
    closeModalHistoryDetalis: (state, action) => {
      state.isOpenHistoryDetalis = false;
    },
    closeModalReport: (state, action) => {
      state.isOpenReport = false;
    },
    closeModalPrivary: (state, action) => {
      state.isOpenPrivary = false;
    },
    closeModalTos: (state, action) => {
      state.isOpenTos = false;
    },
    closeModalPlanningPayment: (state, action) => {
      state.isOpenPlanningPayment = false;
    },
    closeModalAgent: (state, action) => {
      state.isOpenAgent = false;
    },
    closeModalWallet: (state, action) => {
      state.isOpenWallet = false;
    },
    closeModalServiceHistory: (state, action) => {
      state.isOpenServiceHistory = false;
    },
    closeModalBookingDate: (state, action) => {
      state.isOpenBookingDate = false;
    },
    closeModalQrCode: (state, action) => {
      state.isOpenQrCode = false;
    },
    closeModalPaymentDetail: (state, action) => {
      state.isOpenPaymentDetail = false;
    },
    closeModalConfirmPayment: (state, action) => {
      state.isOpenConfirmPayment = false;
    },
    closeAllModal: (state, action) => {
      state.isOpenLogin = false;
      state.isOpenRoom = false;
      state.isOpenActivity = false;
      state.isOpenEvent = false;
      state.isOpenPromotion = false;
      state.isOpenLanguage = false;
      state.isOpenNotifacation = false;
      state.isOpenChangePassword = false;
      state.isOpenPurchaseHistory = false;
      state.isOpenReferral = false;
      state.isOpenProfile = false;
      state.isOpenHistoryDetalis = false;
      state.isOpenReport = false;
      state.isOpenPrivary = false;
      state.isOpenTos = false;
      state.isOpenPlanningPayment = false;
      state.isOpenAgent = false;
      state.isOpenWallet = false;
      state.isOpenServiceHistory = false;
      state.isOpenBookingDate = false;
      state.isOpenQrCode = false;
      state.isOpenPaymentDetail = false;
      state.isOpenConfirmPayment = false;
    },
  },
});

export const {
  openModalLogin,
  handleModalContact,
  closeAllModal,
  closeModalLogin,
  openModalLanguage,
  closeModalLanguage,
  openModalNotifacation,
  closeModalNotifacation,
  openModalChangePassword,
  closeModalChangePasssword,
  openModalPurchaseHistory,
  closeModalPurchaseHistory,
  openModalReferral,
  closeModalReferral,
  openModalProfile,
  closeModalProfile,
  openModalHistoryDetalis,
  closeModalHistoryDetalis,
  openModalReport,
  closeModalReport,
  openModalPrivary,
  closeModalPrivary,
  openModalTos,
  closeModalTos,
  openModalPlanningPayment,
  closeModalPlanningPayment,
  openModalAgent,
  closeModalAgent,
  openModalWallet,
  closeModalWallet,
  openModalServiceHistory,
  closeModalopenModalServiceHistory,
  openModalBookingDate,
  closeModalBookingDate,
  openModalQrCode,
  closeModalQrCode,
  openModalPaymentDetail,
  closeModalPaymentDetail,
  openModalConfirmPayment,
  closeModalConfirmPayment
} = modalSlice.actions;
export default modalSlice.reducer;
