import { useEffect, useState } from "react";

//framework
import { MDBCardImage, MDBCol, MDBContainer } from "mdb-react-ui-kit";

//component
import Header from "../../components/header/header-two";
import PlaceListing from "./component/place.listing";
import Layout from "../../components/common/screen-two";
import Login from "../Login";

//images
import { PlaceHeader } from "../../assets/images";

//redux
import { useDispatch, useSelector } from "react-redux";
import { place } from "../../redux/reducer/placeReducer";

export default function Place() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { isOpenLogin } = useSelector((state) => state.modal);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.body.style.background = "#ffffff";
  }, []);

  useEffect(() => {
    dispatch(place());
    scrollToTop();
  }, []);

  return (
    <div className="place-container p-0" breakpoint="none">
      <Layout />
      <div className="header-place">
        <Header title="Where You Can Go" home="home" />
      </div>
      <MDBCardImage className="header-image" src={PlaceHeader} width="100%" />
      <div className="scroll-component">
        <PlaceListing setSearch={setSearch} />
      </div>
      {isOpenLogin && <Login show={isOpenLogin} />}
    </div>
  );
}
