import http from "./http.services";
import auth from "./auth.services";
import config from "../config.json";

async function getVersion() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/api_version`);
  return response.data.data;
}


async function getHome() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/home`);
  return response.data.data;
}

async function getBanner() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/setting/banner_setting`);
  return response.data.data;
}

async function getActivity() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/ticket?type=activity`);
  return response.data.data;
}

async function getCanlenderInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.post(config.apiEndPoint + `/calendar`, {
    room_id: id,
  });
  return response.data.data;
}

async function checkRoomNumber(room_no) {
  http.setToken(auth.getToken());
  const response = await http.post(
    config.apiEndPoint + `/room/check_room_number`,
    room_no
  );
  return response.data;
}

async function getEvent() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/ticket?type=event`);
  return response.data.data;
}

async function getPlace() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/ticket?type=place&search=123`);
  return response.data.data;
}

async function getPlanning(type) {
  http.setToken(auth.getToken());
  const response = await http.get(
    config.apiEndPoint + `/my_planning?type=${type}`
  );
  return response.data.data;
}
async function getPlanningInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.get(
    config.apiEndPoint + `/my_planning/${id}?id=${id}`
  );
  return response.data.data;
}

async function getBooking() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/room_page`);
  return response.data.data;
}
async function getBookingInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.get(
    config.apiEndPoint + `/room_page/${id}?id=${id}`
  );
  return response.data.data;
}

async function getPromotion() {
  http.setToken(auth.getToken());
  const response = await http.get(
    config.apiEndPoint + `/ticket?type=promotion`
  );
  return response.data.data;
}

async function getProfile() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/profile`);
  return response.data.data;
}

async function getPurchaseHistory({
  search,
  startDate,
  endDate,
  length,
  start,}) {
  http.setToken(auth.getToken());
  const response = await http.post(config.apiEndPoint + `/get_purchase_history?search=${search}&start_date=${startDate}&end_date=${endDate}&length=${length}&start=${start}`);
  return response.data.data;
}

async function getWalletBalance() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/get_wallet_balance`);
  
  return response.data;
}

async function postTopup(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(config.apiEndPoint + `/topup`,payload);
  
  return response.data;
}

async function getPaymentQr() {
  http.setToken(auth.getToken());
  const response = await http.post(config.apiEndPoint + `/get_payment_qr`);
  
  return response.data;
}

async function getPaymentUrl(paymentUrl) {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/senangpay_return_url?${paymentUrl}`);
  return response;
}
async function postPaymentUrl() {
  http.setToken(auth.getToken());
  const response = await http.post(config.apiEndPoint + `/senangpay_callback_url`);
  
  return response;
}

async function getWallet({ search,
  startDate,
  endDate,
  length,
  start,
  type,}) {
  http.setToken(auth.getToken());
  const response = await http.post(config.apiEndPoint + `/get_wallet_history?type=${type}&search=${search}&startDate=${startDate}&endDate=${endDate}&length=${length}&start=${start}`);
  return response.data.data;
}

async function getRoomServiceHistory() {
  http.setToken(auth.getToken());
  const response = await http.post(
    config.apiEndPoint + `/get_room_service_request_history`
  );
  return response.data.data;
}

async function bookingRoom(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(
    config.apiEndPoint + `/payment`,payload
  );
  return response.data;
}

//info activity,event,promotion and place
async function getInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.get(
    config.apiEndPoint + `/ticket/${id}?id=${id}`
  );
  return response.data.data;
}

async function paymentInfo(payload) {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + `/payment/${payload}`);
  return response.data.data;
}

async function checkoutTicket(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(config.apiEndPoint + `/payment`, payload);
  return response.data.data;
}

async function profileInfo() {
  http.setToken(auth.getToken());
  const response = await http.post(config.apiEndPoint + `/profile`);
  return response.data.data;
}

async function updateProfile(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(
    config.apiEndPoint + `/update_profile`,
    payload
  );
  return response.data.data;
}

async function changePasword(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(
    config.apiEndPoint + `/change_password`,
    payload
  );
  return response.data.data;
}


async function completePayment(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(
    config.apiEndPoint + `/payment/continue_to_payment`,
    payload
  );
  return response
}

async function serviceCategory() {
  http.setToken(auth.getToken());
  const response = await http.get(
    config.apiEndPoint + `/room/service/category`
  );
  return response.data.data;
}

async function checkoutService(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(
    config.apiEndPoint + `/room/service`,
    payload
  );
  return response.data.data;
}

async function activateTicket(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(
    config.apiEndPoint + `/activate_ticket`,payload
  );
  return response.status;
}

export default {
  getBanner,
  getHome,
  getInfo,
  getEvent,
  getPlace,
  getActivity,
  postTopup,
  paymentInfo,
  profileInfo,
  updateProfile,
  changePasword,
  checkoutTicket,
  checkoutService,
  completePayment,
  getPlanning,
  getPlanningInfo,
  getWalletBalance,
  getBooking,
  getBookingInfo,
  getPromotion,
  serviceCategory,
  checkRoomNumber,
  getCanlenderInfo,
  getProfile,
  getWallet,
  getRoomServiceHistory,
  bookingRoom,
  getPurchaseHistory,
  getPaymentQr,
  activateTicket,
  getPaymentUrl,
  postPaymentUrl,
  getVersion
};
