import { MDBCardImage, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowDimensions from "../../components/common/window-dimension";

//component
import Layout from "../../components/common/screen-two";
import Header from "../../components/header/header-two";
import RoomExpect from "./component/room.expect";
import PropertyPolicies from "./component/property.policies";
import SuggestPackage from "./component/suggest.package";
import FacilitiesService from "./component/service";
import BookingBtn from "./component/booking.btn";
import ModalBooking from "./component/modal.booking";

//redux
import { useSelector, useDispatch } from "react-redux";
import { bookingInfo } from "../../redux/reducer/booking.reducer";
import CheckOutModal from "../../components/modal/check.out.modal";

export default function RoomDetalis() {
  const { roomId } = useParams();
  const dispatch = useDispatch();
  const { isOpenBookingDate } = useSelector((state) => state.modal);
  const { image, name, tag } = useSelector((state) => state.booking.roomInfo);
  const { modalBoxCheckOut } = useSelector((state) => state.checkout);
  const packages = useSelector((state) => state.detail.ticketInfo.package);
  const [responsive, setResponsive] = useState("mobile");
  const { height, width } = useWindowDimensions();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    dispatch(bookingInfo({ id: roomId }));
    scrollToTop();
  }, []);

  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);

  return (
    <>
      <MDBContainer
        // className={
        //   responsive === "mobile"
        //     ? "room-listing-container"
        //     : "room-listing-container-desktop"
        // }
        className="room-listing-container-desktop"
        breakpoint="none"
      >
        <Layout />
        <Header btnShare={true} btnLike={true} roomId={roomId} />

        <Swiper loop={true} grabCursor={true}>
          {image
            ? image[0].map((image, index) => {
                return (
                  <SwiperSlide key={index}>
                    <MDBCardImage
                      className="header-image"
                      src={image}
                      width="100%"
                    />
                  </SwiperSlide>
                );
              })
            : ""}
        </Swiper>
        <div className="scroll-component">
          <MDBContainer className="grid-component">
            <MDBContainer className="header-container">
              <MDBRow className="title">
                <label className="p-0">{name}</label>
              </MDBRow>
              <MDBRow className="badge-row d-flex align-items-start">
                {tag
                  ? tag.map((tag, index) => {
                      return (
                        <>
                          <MDBCol className="badge col-2 p-0" key={index}>
                            <label style={{ background: `#${tag.color}` }}>
                              {tag.text}
                            </label>
                          </MDBCol>
                        </>
                      );
                    })
                  : ""}
              </MDBRow>
            </MDBContainer>
            <FacilitiesService />
            <PropertyPolicies />
            <RoomExpect />
            {/* <SuggestPackage /> */}
            <BookingBtn />
          </MDBContainer>
        </div>
        {modalBoxCheckOut && (
          <CheckOutModal show={modalBoxCheckOut} roomId={roomId} />
        )}
        {isOpenBookingDate && <ModalBooking title={name} tag={tag} />}
      </MDBContainer>
    </>
  );
}
