import { useEffect } from "react";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//common component
import Header from "../../components/header/header-two";
import Layout from "../../components/common/screen-two";

//component
import RoomCheck from "./component/room.navbar";
import RoomCard from "./component/room.card";
import { Room1, Room2, Room3 } from "../../assets/images";
import Login from "../Login";

//modalbox

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/reducer/modalReducer";
import { booking } from "../../redux/reducer/booking.reducer";
import { closeModalBoxCheckOut } from "../../redux/reducer/checkoutReducer";

import LoaderSvg from "../../assets/images/svg/loader.svg";

export default function RoomBooking() {
  const dispatch = useDispatch();
  const { isOpenLogin } = useSelector((state) => state.modal);
  const { listing, loading } = useSelector((state) => state.booking);

  useEffect(() => {
    document.body.style.background = "#ffffff";
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    dispatch(booking());
    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch(closeModalBoxCheckOut());
  }, []);

  return (
    <div className="room-container">
      <MDBContainer className="room-container-desktop">
        <Header btnLike={true} title={"Gold Coast Morib"} home="home" />
        <Layout />
        <MDBContainer className="content-component" data-aos="fade-up">
          <RoomCheck />
          {loading ? (
            <div className="loader-container">
              <img src={LoaderSvg} height="50" width="50" />
            </div>
          ) : (
            <MDBRow>
              {listing.map((booking, index) => (
                <RoomCard key={index} booking={booking} />
              ))}
            </MDBRow>
          )}
        </MDBContainer>
      </MDBContainer>
      {isOpenLogin && <Login show={isOpenLogin} />}
    </div>
  );
}
