import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBCol,
  MDBRow,
  MDBCardImage,
} from "mdb-react-ui-kit";

import { LogoMain, LogoMainRound } from "../../assets/images";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

//REDUX
import { useDispatch } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";
import { closeModalBoxCheckOut } from "../../redux/reducer/checkoutReducer";

export default function Header({
  title,
  btnShare,
  btnLike,
  responsive,
  home,
  roomId,
  ticketId,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [copy, setCopy] = useState(false);
  const token = localStorage.getItem("token");

  const url = window.location.href;
  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#FFFFFF");
  }
  if (responsive === "mobile") {
  }
  const shareLink = () => {
    if (navigator.share) {
      navigator.share({
        title: `${title}`,
        url: `${url}`,
      });
      console.then(() => {}).catch();
    } else {
    }
  };
  const onCopy = () => {
    setCopy(true);
    toast("Copied", {
      position: "bottom-center",
      autoClose: 300,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  };

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  return (
    <MDBNavbar className="header-two-container">
      <MDBContainer>
        <MDBRow className="header-row">
          <MDBCol className="col-2 col-md-1 d-flex justify-content-center p-0">
            <div
              className="round-background-icon"
              onClick={() => {
                dispatch(
                  closeModalBoxCheckOut({
                    title: "Choose Date",
                    type: "booking",
                  })
                );
                // home === "home";
                // ? navigate("/")
                // : roomId
                // ? navigate("/room")
                // : ticketId
                navigate(-1);
                // : navigate("/");
                dispatch(closeAllModal());
              }}
            >
              <Icon
                icon="ic:round-arrow-back-ios-new"
                color="#707070"
                width="20"
                height="27"
              />
            </div>
          </MDBCol>
          <MDBCol
            className={
              title
                ? "col-8 col-md-10 text-center p-0"
                : "col-6 text-center p-0"
            }
          >
            <label>{title}</label>
          </MDBCol>

          <MDBCol
            className={
              btnLike
                ? "d-flex justify-content-center p-0"
                : "d-flex justify-content-end"
            }
            style={{ position: "relative" }}
          >
            {btnShare && !btnLike ? (
              <div className="round-background-icon">
                <CopyToClipboard text={url} onCopy={onCopy}>
                  <Icon
                    icon="ri:share-circle-line"
                    color="#707070"
                    width="20"
                    height="27"
                    onClick={() => shareLink()}
                  />
                </CopyToClipboard>
              </div>
            ) : !btnShare && btnLike ? (
              <div className="round-background-icon">
                <Icon
                  icon="icon-park-outline:like"
                  color="#707070"
                  width="20"
                  height="27"
                />
              </div>
            ) : btnLike && btnShare ? (
              <div>
                <div
                  className="round-background-icon"
                  style={{
                    position: "absolute",
                    right: "0.5em",
                    top: "-1em",
                  }}
                >
                  <Icon
                    icon="icon-park-outline:like"
                    color="#707070"
                    width="20"
                    height="27"
                  />
                </div>
                <div
                  className="round-background-icon"
                  style={{
                    position: "absolute",
                    right: "3em",
                    top: "-1em",
                  }}
                >
                  <CopyToClipboard text={url} onCopy={onCopy}>
                    <Icon
                      icon="ri:share-circle-line"
                      color="#707070"
                      width="20"
                      height="27"
                      onClick={() => shareLink()}
                    />
                  </CopyToClipboard>
                </div>
              </div>
            ) : (
              ""
            )}
          </MDBCol>
          {/* {btnLike && (
            <MDBCol
              className={
                btnShare
                  ? "d-flex justify-content-center p-0"
                  : "d-flex justify-content-end"
              }
            >
              <div className="round-background-icon">
                <Icon
                  icon="icon-park-outline:like"
                  color="#707070"
                  width="20"
                  height="27"
                />
              </div>
            </MDBCol>
          )} */}
        </MDBRow>
      </MDBContainer>
    </MDBNavbar>
  );
}
