import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { RemoveScroll } from "react-remove-scroll";
import { ProfileAvatar } from "../../../assets/images";
import ModalBox from "../../../components/modal/full.modal.box";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
  MDBCardImage,
} from "mdb-react-ui-kit";
//redux
import { useDispatch, useSelector } from "react-redux";
import { closeModalReferral } from "../../../redux/reducer/modalReducer";
//formik
import { Formik, Field, Form, FormikConsumer } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/element/inputField";

export default function ModalReferral() {
  const dispatch = useDispatch();
  const { avatar, referral_link, referral_code } = useSelector(
    (state) => state.profile.data
  );
  const [data, setData] = useState({
    text: "",
  });
  const dataSchema = Yup.object({
    text: Yup.string(),
  });
  const navigate = useNavigate();
  const shareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "${title}",
          url: ` ${referral_link}`,
        })
        .then(() => {})
        .catch();
    } else {
    }
  };
  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="profile-modal-container">
            <Formik
              initialValues={data}
              validationSchema={dataSchema}
              //  onSubmit
            >
              {({
                errors,
                setFieldValue,
                values,
                handleChange,
                handleSubmit,
              }) => (
                <Form>
                  <MDBContainer className="banner-referral">
                    <MDBRow className="header-row">
                      <MDBCol className="col-2 d-flex justify-content-center p-0">
                        <div
                          className="round-background-icon"
                          onClick={() => dispatch(closeModalReferral())}
                        >
                          <Icon
                            icon="entypo:cross"
                            color="#707070"
                            width="30"
                            height="27"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol className="col-8 text-center">
                        <label>Referrals</label>
                      </MDBCol>
                      <MDBCol className="col-2"></MDBCol>
                    </MDBRow>
                  </MDBContainer>

                  <MDBContainer className="referral-container">
                    <MDBRow className="referral-link">
                      <MDBCol className="col-2">
                        {avatar ? (
                          <img src={avatar} className="referral-image" />
                        ) : (
                          <img src={ProfileAvatar} className="referral-image" />
                        )}
                      </MDBCol>
                      <MDBCol>
                        <label>Share My Link</label>
                        <MDBRow className="link">
                          <a>{referral_link}</a>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className="col-2">
                        <Icon
                          className="icon"
                          icon="eva:arrow-ios-back-outline"
                          color="darkgray"
                          width="25"
                          height="25"
                          hFlip={true}
                          onClick={() => shareLink()}
                        />
                      </MDBCol>
                    </MDBRow>
                    <div className="scoll-referral">
                      <MDBContainer className="margin">
                        <MDBRow>
                          <label>Did a friend refer you ?</label>
                        </MDBRow>
                        <InputField
                          className="login-field"
                          name="text"
                          type="text"
                        />
                      </MDBContainer>
                      <MDBContainer>
                        <MDBRow>
                          <label>How it works</label>
                        </MDBRow>
                        <MDBRow>
                          <p>
                            1. Share your invitaton link with your new friends
                            to Gold Coast Morib.
                          </p>
                        </MDBRow>
                        <MDBRow>
                          <p>
                            2. Your friends signup and booking room using this
                            app.
                          </p>
                        </MDBRow>
                        <MDBRow>
                          <p>
                            3. They make any purchase in their account you can
                            earn up to 15% direct sponsor bonus.
                          </p>
                        </MDBRow>
                        <MDBRow>
                          <p>
                            4. Everyone get to enjoy Gold Coast Morib offers and
                            service!
                          </p>
                        </MDBRow>
                      </MDBContainer>
                    </div>
                  </MDBContainer>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
