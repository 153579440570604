import { MDBRadio } from "mdb-react-ui-kit"
import { useState } from "react"

export default function BtnRadio({label,name,id,value,handleChange}){
    // const [ values, setValues] = useState(false)

    return(
        <MDBRadio
            btn
            id={id} 
            name={name}
            label={label}
            value={value} 
            // checked={values}
            onChange={handleChange}
        />
    )
}