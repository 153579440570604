import {
  MDBCard,
  MDBCardImage,
  MDBCol,
  MDBRow,
  MDBCardOverlay,
  MDBContainer,
} from "mdb-react-ui-kit";

import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalLogin } from "../../../redux/reducer/modalReducer";
import RoomIcon from "../../../assets/images/svg/room-icon.svg";

export default function RoomCard({ booking }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let totalPax = [];
  for (let i = 0; i < booking.pax; i++) {
    totalPax.push(i);
  }
  // const { listing } = useSelector((state) => state.booking);
  const token = localStorage.getItem("token");

  return (
    <MDBCol className="col-12 col-md-6 p-6">
      <MDBCard
        className="room-card"
        onClick={() => {
          navigate(`/room/${booking.id}`);
        }}
      >
        <MDBCardImage overlay src={booking.mainMediaUrl} />
        {/* <MDBCardOverlay> */}
        <MDBContainer className="background-room">
          {/* <MDBRow>
            <MDBCol className="col-7 card-title">{booking.name}</MDBCol>
            <MDBCol className="col-5 room-money">
              from <span>RM{parseFloat(booking.current_price).toFixed(0)}</span>
            </MDBCol>
          </MDBRow> */}
          <MDBRow>
            <label className="card-title">{booking.name}</label>
          </MDBRow>
          <MDBRow>
            <label className="room-size">Room Size: {booking.size}sf</label>
          </MDBRow>
          <MDBRow>
            <MDBCol className="col-6 room-icon text-start">
              {totalPax.map((item, index) => (
                <Icon icon="line-md:account" key={index} />
                // <img src={RoomIcon} width="20em" key={index} />
              ))}
              {/* <lord-icon
                src="https://cdn.lordicon.com/dxjqoygy.json"
                trigger="hover"
                colors="primary:#e4e4e4,secondary:#e4e4e4"
                state="hover-looking-around"
                style="width:250px;height:250px"
              ></lord-icon> */}
            </MDBCol>
            <MDBCol className="col-6 text-end">
              <label className="room-money">
                from{" "}
                <span>RM{parseFloat(booking.current_price).toFixed(0)}</span>
              </label>
            </MDBCol>
          </MDBRow>
          {/* <MDBRow between>
            <MDBCol className="room-size">Room Size: {booking.size}sf</MDBCol>
            <MDBCol className="room-icon">
              {totalPax.map((item, index) => (
                <Icon icon="ri:shield-user-line" key={index} />
              ))}
            </MDBCol>
          </MDBRow> */}
        </MDBContainer>
        {/* </MDBCardOverlay> */}
      </MDBCard>

      <br />
    </MDBCol>
  );
}
