import { MDBContainer } from "mdb-react-ui-kit";
import Header from "../header/header-one";
import Navbar from "../navbar/navbar-one";

export default function Layout(){
    return(
        <div className="layout2-component">
            <MDBContainer>
            </MDBContainer>
        </div>
    )
}