import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBBadge,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Description from "./description";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

import PackageTypeAddtional from "./package.addtional";
import PackageTypeStandard from "./package.standard.v2";
import {
  addSelectedPackage,
  handleModalBoxCheckOut,
  setCheckoutData,
} from "../../../redux/reducer/checkoutReducer";
import { useEffect } from "react";

export default function Package() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { package_title } = useSelector((state) => state.detail.ticketInfo);
  const { loading } = useSelector((state) => state.detail);
  const packages = useSelector((state) => state.detail.ticketInfo.package);

  const handleSubmit = (values) => {
    dispatch(addSelectedPackage(values));
    dispatch(
      handleModalBoxCheckOut({ title: "Booking Options", type: "ticket" })
    );
  };

  const schema = Yup.object().shape({
    standard: Yup.array()
      .min(1, "Please Select 1 Package")
      .required("required"),
  });

  const initialValues = {
    standard: [],
    additional: [],
  };

  return (
    <MDBCol className="package-container">
      {loading ? (
        ""
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          enableReinitialize={true}
          validateOnChange={true}
          onSubmit={(values, { setFieldError, resetForm, isSubmitting }) => {
            if (token) {
              handleSubmit(values);
            } else {
              dispatch(openModalLogin());
            }
          }}
        >
          {({ setFieldValue, isSubmitting, handleReset, isValid, errors }) => (
            <Form>
              <MDBRow className="container-title">
                <label>
                  <div></div>
                  {package_title}
                </label>
              </MDBRow>
              <div>
                {packages
                  ? packages.map((ticket, index) => {
                      return (
                        <MDBContainer key={index} className="content-container">
                          {ticket.package_pattern === "standard" ? (
                            <PackageTypeStandard
                              packages={ticket}
                              indexPackages={index}
                            />
                          ) : (
                            <PackageTypeAddtional
                              packages={ticket}
                              indexPackages={index}
                            />
                          )}
                        </MDBContainer>
                      );
                    })
                  : ""}
                {errors && errors["standard"] && errors["standard"] ? (
                  <div className="text-danger text-right no-padding error-message">
                    {errors["standard"]}
                  </div>
                ) : null}
                {/* {Object.keys(additional_service).length <= 0 ?
                        <PackageTypeStandard/> : 
                    additional_service.length > 0 ?
                        <PackageTypeAddtional /> : 
                    ""} */}
              </div>
              <MDBRow className="form-group btn-buy-now">
                <MDBBtn type="submit">Buy Now</MDBBtn>
              </MDBRow>
            </Form>
          )}
        </Formik>
      )}
    </MDBCol>
  );
}
