import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";

export default function WalletHistory(){

    const { comission_history } = useSelector((state) => state.agent)

    return(
        <MDBContainer className="detail-container">
            <MDBRow className="container-title">
                <MDBCol className="col-9">
                    <label><div></div>Comission History</label>
                </MDBCol>
                <MDBCol className="col-3 d-flex justify-content-end align-items-center">
                    <span>Details</span>
                </MDBCol>
            </MDBRow>
            {comission_history.map((item,index) => {
                return(
                    <div key={index} className="listing-row">
                        <MDBRow>
                            <MDBCol className="col-8">
                                <MDBRow className="title"><label>{item.title}</label></MDBRow>
                                <MDBRow className="detail"><label>{item.description}</label></MDBRow>
                                <MDBRow className="detail"><label>{item.detail}</label></MDBRow>
                            </MDBCol>
                            <MDBCol className="col-4 price">
                                <span>RM {item.price}</span>
                            </MDBCol>
                        </MDBRow>
                        <div className="single-line"></div>
                    </div>
                )
            })}
        </MDBContainer>
    )
}