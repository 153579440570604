import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const wallet = createAsyncThunk("get/wallet", async ({ search,
  startDate,
  endDate,
  length,
  start,
  type,}) => {
  const response = await apiServices.getWallet({type, search,
    startDate,
    endDate,
    length,
    start,});
  return response.aaData;
});

const initialState = {
  history: [],
  loading: false,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  extraReducers: {
    [wallet.pending]: (state, action) => {
      state.loading = true;
    },
    [wallet.fulfilled]: (state, action) => {
      state.loading = false;
      state.history = action.payload;
    },
  },
});

const { reducer } = walletSlice;
export default reducer;
