import { useState } from "react";
import { MDBContainer, MDBRow, MDBCard, MDBCol } from "mdb-react-ui-kit";

//component
import InputSearch from "../../../components/element/inputSearch";
import PlaceListingCard from "./place.listing.card";

//images
import { useSelector, useDispatch } from "react-redux";
import { searchLisitng } from "../../../redux/reducer/placeReducer";
import LoaderSvg from "../../../assets/images/svg/loader.svg";

export default function PlaceListing() {
  const [search, setSearch] = useState("");
  const [selectedId, setSelectedId] = useState();
  const { listing } = useSelector((state) => state.place);
  const { loading } = useSelector((state) => state.place);
  const dispatch = useDispatch();

  const handleClick = (id) => {
    setSelectedId(id);
  };

  const filtered = listing
    .filter(function (value) {
      return value.title.toLowerCase().includes(search);
    })
    .map((el) => el);

  return (
    <MDBContainer className="place-listing-container" breakpoint="">
      <MDBContainer className="place-desktop">
        <InputSearch setSearch={setSearch} />
        {loading ? (
          <div className="loader-container">
            <img src={LoaderSvg} height="50" width="50" />
          </div>
        ) : (
          <MDBRow className="place-listing-row">
            {filtered &&
              filtered.map((item, index) => {
                return (
                  <MDBCol key={index} className="col-12 col-md-3 p-1">
                    <PlaceListingCard
                      item={item}
                      index={index}
                      handleClick={handleClick}
                    />
                  </MDBCol>
                );
              })}
          </MDBRow>
        )}
      </MDBContainer>
    </MDBContainer>
  );
}
