import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { RemoveScroll } from "react-remove-scroll";
import ModalBox from "../../../components/modal/full.modal.box";
import { toast } from "react-toastify";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import * as Yup from "yup";
import { Formik, Field, Form, FormikConsumer } from "formik";
import Header from "../../../components/header/header-five";
import InputField from "../../../components/element/inputField";
import { ProfileAvatar } from "../../../assets/images";
import Select from "../../../components/element/selectField";
//redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { profile } from "../../../redux/reducer/profileReducer";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import { updateProfile } from "../../../redux/reducer/profileReducer";
import moment from "moment/moment";

export default function ModalProfile() {
  const dispatch = useDispatch();
  const genderList = [
    { label: "Male", id: "male" },
    { label: "Female", id: "female" },
    { label: "Another", id: "another" },
  ];
  const [disabled, setDisabled] = useState(true);
  const [showImage, setShowImage] = useState("");
  const [avatarImage, setAvatarImage] = useState(false);
  const editBtt = () => {
    setDisabled(!disabled);
  };
  const {
    gender,
    first_name,
    last_name,
    avatar,
    dob,
    email,
    phone_number,
    register_at,
  } = useSelector((state) => state.profile.data);

  const [userData, setUserData] = useState({
    avatar: avatar ? avatar : "",
    first_name: first_name ? first_name : "",
    last_name: last_name ? last_name : "",
    gender: gender ? gender : "",
    dob: dob ? moment(dob).format("YYYY-DD-MM") : "",
    email_name: email ? email : "",
    phone_number: phone_number ? phone_number : "",
  });
  const loginSchema = Yup.object({
    first_name: Yup.string().required("required"),
    last_name: Yup.string().required("required"),
    phone_number: Yup.string()
      .required("required")
      .min(10, "Please enter more than or equal to 10 character")
      .max(13, "Must be exactly 13 digits")
      .matches(/^([6]{1}|\+?[0]{1})([0-9]{9,11})$/g, "Must be phone format"),
  });

  const changeProfileImage = (e, setFieldValue) => {
    let imageFile = e.target.files[0];
    setAvatarImage(true);
    setShowImage(URL.createObjectURL(e.target.files[0]));
    setFieldValue("avatar", imageFile);
  };

  const changeGender = (e, setFieldValue) => {
    setFieldValue("gender", e.target.value);
  };

  const handleSubmit = async (values, setFieldError) => {
    const formData = new FormData();
    for (let key in values) {
      if (key === "avatar") {
        if (avatarImage) {
          formData.append(key, values[key]);
        } else {
        }
      } else {
        if (values[key]) {
          formData.append(key, values[key]);
        }
      }
    }
    dispatch(updateProfile({ payload: formData }))
      .unwrap()
      .then(() => {
        dispatch(closeAllModal());
        dispatch(profile());
        toast.success("Information Updated Successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      })
      .catch((e) => {
        if (e) {
          const errors = e.response.data.errors;
          let errMsg = "";
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errMsg = errors[item];
              setFieldError(item, errors[item]);
            });
          }

          toast.error(errMsg[0], {
            theme: "colored",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
        }
      });
  };

  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="profile-modal-container">
            <Formik
              initialValues={userData}
              validationSchema={loginSchema}
              validateOnChange={true}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError);
              }}
            >
              {({
                isSubmitting,
                errors,
                setFieldValue,
                values,
                handleChange,
                handleSubmit,
              }) => (
                <Form>
                  <Header
                    title={"Profile"}
                    label="Save"
                    values={values}
                    isSubmitting={isSubmitting}
                  />
                  <MDBContainer className="image-css">
                    <img
                      name="avatar"
                      src={
                        showImage
                          ? showImage
                          : userData.avatar
                          ? values.avatar
                          : ProfileAvatar
                      }
                      className="image img-fluid rounded-circle"
                    />
                  </MDBContainer>
                  <MDBContainer className="camera-css">
                    <MDBRow>
                      <input
                        type="file"
                        name="avatar"
                        id="file"
                        onChange={(e) => changeProfileImage(e, setFieldValue)}
                        hidden
                      />

                      <label htmlFor="file" className="camera">
                        <Icon icon="ant-design:camera-filled" />
                      </label>
                    </MDBRow>
                  </MDBContainer>
                  <MDBContainer className="edit-container">
                    <MDBRow>
                      <InputField
                        className="service-field"
                        name="first_name"
                        type="first_name"
                        errors={errors}
                        placeholder="First Name"
                      />
                    </MDBRow>
                    <MDBRow>
                      <InputField
                        className="service-field"
                        name="last_name"
                        type="last_name"
                        errors={errors}
                        placeholder="Last Name"
                      />
                    </MDBRow>
                    <MDBRow>
                      <InputField
                        className="service-field"
                        name="phone_number"
                        type="phone_number"
                        errors={errors}
                        placeholder="Phone Number"
                      />
                    </MDBRow>
                    <MDBRow>
                      <InputField
                        className="service-field"
                        name="email_name"
                        type="email"
                        errors={errors}
                        disabled
                        placeholder="Email"
                      />
                    </MDBRow>
                    {/* <MDBRow className="gender-radio"> */}
                    <Select
                      errors={errors}
                      name="gender"
                      label="Gender"
                      className="gender"
                      placeholder="Please select your gender"
                      options={genderList}
                    />
                    {/* <MDBCol className="gender-col">
                        <MDBRadio
                          label="Male"
                          id="validationFormCheck2"
                          name="gender"
                          value="male"
                          checked={values.gender === "male"}
                          onChange={(e) => changeGender(e, setFieldValue)}
                        />
                      </MDBCol>
                      <MDBCol className="gender-col">
                        <MDBRadio
                          label="Famale"
                          id="validationFormCheck3"
                          name="gender"
                          value="female"
                          checked={values.gender === "female"}
                          onChange={(e) => changeGender(e, setFieldValue)}
                        />
                      </MDBCol>
                      <MDBCol className="gender-col">
                        <MDBRadio
                          label="Another"
                          id="validationFormCheck4"
                          name="gender"
                          value="another"
                          checked={values.gender === "another"}
                          onChange={(e) => changeGender(e, setFieldValue)}
                        />
                      </MDBCol> */}
                    {/* </MDBRow> */}
                    <MDBRow>
                      <InputField
                        className="service-field"
                        name="dob"
                        type="dob"
                        errors={errors}
                        placeholder="Date of brith "
                      />
                    </MDBRow>
                  </MDBContainer>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}

// placeholder={`${moment(
//   new Date(item.package.availability_start_date)
// ).format("DD/MM/YYYY hh:mm")} - ${moment(
//   new Date(item.package.availability_end_date)
// ).format("DD/MM/YYYY hh:mm")}`}
// minDate={new Date(item.package.availability_start_date)}
// maxDate={new Date(item.package.availability_end_date)}
// onChange={(date) => setDateRange(date)}
