import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Field, ErrorMessage, useFormikContext } from "formik";

export default function Select({
  name,
  label,
  options,
  errors,
  disabled,
  placeholder,
  type,
  className,
}) {
  const { setFieldValue, handleChange } = useFormikContext();
  return (
    <MDBCol
      className={
        errors[name]
          ? `form-group error-border ${className}`
          : `form-group ${className}`
      }
    >
      <MDBRow>
        <label className="form-label">{label}</label>
      </MDBRow>
      <Field as="select" name={name} type={type} disabled={disabled}>
        <option value="">{placeholder}</option>
        {options &&
          options.map((option) => (
            <>
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            </>
          ))}
      </Field>
      {errors && errors[name] ? (
        <div className="text-danger text-right no-padding error-message form-group">
          {errors[name]}
        </div>
      ) : null}
    </MDBCol>
  );
}
