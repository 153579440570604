//framework & plugin
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

export default function ModalBox({
  className,
  show,
  screenSize,
  content,
  setShow,
}) {
  return (
    <MDBModal
      className={
        className
          ? `full-modal-box-container-${className}`
          : "full-modal-box-container"
      }
      tabIndex="-1"
      show={show}
      staticBackdrop
      setShow={setShow}
    >
      <MDBModalDialog size={`${screenSize}`}>
        <MDBModalContent>{content}</MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
