import React from "react";
import { MDBContainer, MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
// import { LoginLoader } from "../../assets/images";
import LoginLoader from "../../assets/images/svg/button.loader.svg";

export default function Button({
  label,
  isSubmitting,
  isValid,
  onClick,
  isRunLoader,
  className,
}) {
  return (
    <MDBRow className="button-container">
      <MDBBtn
        type="submit"
        className="submit-button"
        onClick={onClick ? () => onClick() : null}
        disabled={!isValid || isSubmitting}
      >
        {isRunLoader === true ? (
          <img src={LoginLoader} alt="loading..." height="100%" width="50" />
        ) : (
          label
        )}
      </MDBBtn>
    </MDBRow>
  );
}
