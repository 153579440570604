import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBCol,
  MDBRow,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//REDUX
import { useDispatch } from "react-redux";
import { clearPlaningInfo } from "../../redux/reducer/planingReducer";

export default function Header({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <MDBNavbar className="header-four-container">
      <MDBContainer>
        <MDBRow className="header-row d-flex align-items-center justify-content-center">
          <MDBCol className="col-2 d-flex justify-content-center p-0">
            <div
              className="round-background-icon"
              onClick={() => {
                navigate("/planning");
                dispatch(clearPlaningInfo());
              }}
            >
              <Icon
                icon="ic:round-arrow-back-ios-new"
                color="#707070"
                width="20"
                height="27"
              />
            </div>
          </MDBCol>
          <MDBCol className="col-8 text-center p-0">
            <label>{title}</label>
          </MDBCol>
          <MDBCol className="col-2">
            <label className="help">Help</label>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBNavbar>
  );
}
