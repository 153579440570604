import { Formik,Form, Field } from "formik";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";

export default function TransferCredit(){
    const dispatch = useDispatch()
    const { name,bank_name,bank_acc } = useSelector((state) => state.agent.wallet)
    const [data,setData] = useState({
        email: "",
        amount: "",
    })


    const handleSubmit = () => {

    }

    return(
    <MDBContainer className="transfer-credit-container">
        <Formik
            initialValues={data}
            // validationSchema={schema}
            enableReinitialize={true}
            validateOnChange={true}
            onSubmit={(values)=>{
                handleSubmit(values)
            }}
        >
            {({ setFieldValue, isSubmitting, handleReset, isValid,values,errors }) => (
            <Form> 
                <div className="bank-info">
                    <div className="border-box">
                        <MDBRow className="name-label">
                            <label>Room Credit Balance : 49</label>
                        </MDBRow>
                    </div>
                    <MDBRow className="title"><label>Transfer My Room Credit</label></MDBRow>
                    <MDBRow className="input-field"><Field name="email" type="text" placeholder="Enter Agent Register Email" /></MDBRow>
                    <MDBRow className="input-field"><Field name="amount" type="text" placeholder="Enter Amount" /></MDBRow>
                    <MDBRow className="description-label">
                        <label><span>Must </span>enterOnly able to transfer your room  your bank account same with your full name. Once submitted it cannot be change.</label>
                    </MDBRow>
                </div>
            </Form>)}
        </Formik>
    </MDBContainer>
    )
}