import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

//framework
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { Rating } from "primereact/rating";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

export default function ActivityListingCard({ item, index, handleClick }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  return (
    <MDBCol key={index} className="col-12 col-md-6 p-1 activity-card">
      <MDBCard
        className="card-container"
        onClick={() => {
          navigate(`/ticket/${item.id}`);
        }}
      >
        <MDBRipple
          rippleColor="light"
          rippleTag="div"
          className="bg-image hover-overlay"
        >
          <MDBCardImage src={item.image} alt="..." position="top" />
          <MDBCardBody>
            <MDBCardText>
              <MDBRow>
                <label className="title p-0">{item.title}</label>
              </MDBRow>
              <MDBRow>
                <MDBCol className="col-8 col-lg-8 col-md-9">
                  <MDBRow className="rating d-flex align-items-center">
                    {item.rating.map((item, index) => (
                      <>
                        <MDBCol className="p-0 col-3">
                          <label className="p-0">Extreme:</label>
                        </MDBCol>
                        <MDBCol className="col-9">
                          <Rating
                            value={parseInt(item.rating)}
                            readOnly={true}
                            start={5}
                            cancel={false}
                            onIcon={
                              <Icon
                                icon="ant-design:star-filled"
                                color="#ffcb46"
                                width="14"
                                height="14"
                              />
                            }
                            offIcon={
                              <Icon
                                icon="ant-design:star-filled"
                                color="#D6D6D6"
                                width="14"
                                height="14"
                              />
                            }
                          />
                        </MDBCol>
                      </>
                    ))}
                  </MDBRow>
                  <MDBRow>
                    {item.tag
                      ? item.tag.map((item, index) => (
                          <MDBCol
                            className="col-5 detail"
                            key={index}
                            style={{ background: `#${item.color}` }}
                          >
                            <label>{item.text}</label>
                          </MDBCol>
                        ))
                      : ""}
                  </MDBRow>
                </MDBCol>
                <MDBCol className="col-4 col-lg-4 col-md-5 btn-activity-detail ">
                  <MDBBtn>Detail</MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCardText>
          </MDBCardBody>
        </MDBRipple>
      </MDBCard>
    </MDBCol>
  );
}
