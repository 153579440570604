import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";

export default function Title() {
  const { title, tag } = useSelector((state) => state.detail.ticketInfo);
  const { loading } = useSelector((state) => state.detail);

  return (
    <MDBCol className="ticket-title-container col-12">
      {loading ? (
        ""
      ) : (
        <>
          <MDBRow className="title">
            <label>{title}</label>
          </MDBRow>
          <MDBRow className="badge-row d-flex align-items-start m-0">
            {tag
              ? tag.map((tag, index) => {
                  return (
                    <MDBCol
                      key={index}
                      className="badge col-3 p-0"
                      style={{ background: `#${tag.color}` }}
                    >
                      <label>{tag.text}</label>
                    </MDBCol>
                  );
                })
              : ""}
          </MDBRow>
        </>
      )}
    </MDBCol>
  );
}
