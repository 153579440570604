import { useState } from "react";
import {
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdb-react-ui-kit";
import { MDBContainer } from "mdb-react-ui-kit";

import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/full.modal.box";
import { useDispatch, useSelector } from "react-redux";
import { closeModalConfirmPayment } from "../../../redux/reducer/modalReducer";

export default function ConfirmModal({ show, handleSubmit, values }) {
  const dispatch = useDispatch();
  const { total_price } = useSelector((state) => state.payment.payment);

  return (
    <ModalBox
      show={show}
      screenSize="s"
      content={
        <div>
          <MDBModalHeader>
            <MDBCol
              className="col-2"
              onClick={() => dispatch(closeModalConfirmPayment())}
            >
              <Icon
                icon="fluent-emoji-high-contrast:cross-mark"
                color="#707070"
                width="15"
                height="15"
              />
            </MDBCol>
            <MDBCol className="col-8 contact-header text-align-center">
              <label>Continue to payment</label>
            </MDBCol>
            <MDBCol></MDBCol>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer className="form-group btn-buy-now">
              <MDBRow>
                <label className="_label --title">Price</label>
                <label className="_label --price">
                  RM {parseFloat(total_price).toFixed(2)}{" "}
                </label>
              </MDBRow>
              <MDBRow>
                <MDBBtn
                  type="submit"
                  onClick={() => {
                    dispatch(closeModalConfirmPayment());
                    handleSubmit(values);
                  }}
                >
                  Payment
                </MDBBtn>
                <MDBBtn
                  onClick={() => {
                    dispatch(closeModalConfirmPayment());
                  }}
                  className="cancel"
                >
                  No ,thanks
                </MDBBtn>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </div>
      }
    ></ModalBox>
  );
}
