import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const promotion = createAsyncThunk("get/promotion", async () => {
  const response = await apiServices.getPromotion();
  return response;
});

export const promotionInfo = createAsyncThunk(
  "get/promotionInfo",
  async ({ id }) => {
    const response = await apiServices.getPromotionInfo(id);
    return response;
  }
);

const initialState = {
  history: [],
  ticket: [],
  loading: false,
  promotionInfo: {},
};

const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  extraReducers: {
    [promotion.pending]: (state, action) => {
      state.loading = true;
    },
    [promotion.fulfilled]: (state, action) => {
      state.loading = false;
      state.history = action.payload.history;
      state.ticket = action.payload.ticket;
    },
    [promotionInfo.pending]: (state, action) => {
      state.loading = true;
    },
    [promotionInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.promotionInfo = action.payload;
    },
  },
});

const { reducer } = promotionSlice;
export default reducer;
