import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const place = createAsyncThunk("get/place",
    async() => {
        const response = await apiServices.getPlace()
        return response
    }  
)

const initialState = {
    history: [],
    listing: [],
    loading: false
}

const placeSlice = createSlice({
    name: 'place',
    initialState,
    reducers:{
        searchLisitng: (state,action) => {
          state.listing = action.payload
        }
      },
    extraReducers:{
        [place.pending]:(state,action)=>{
            state.loading = true
        },
        [place.fulfilled]:(state,action)=>{
            state.loading = false
            state.history = action.payload.history
            state.listing = action.payload.ticket
        },
    }
})
export const { searchLisitng } = placeSlice.actions;
const { reducer } = placeSlice
export default reducer