import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/full.modal.box";
import { RemoveScroll } from "react-remove-scroll";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import * as Yup from "yup";
import { ref } from "yup";
import { Formik, Field, Form, FormikConsumer } from "formik";
import { useDispatch } from "react-redux";
import Header from "../../../components/header/header-five";
import InputField from "../../../components/element/inputField";
import { toast } from "react-toastify";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import { updatePassword } from "../../../redux/reducer/profileReducer";
import { profile } from "../../../redux/reducer/profileReducer";

export default function ProfileChangePassword() {
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const loginSchema = Yup.object({
    password: Yup.string(),
    new_password: Yup.string(),
    confirm_new_password: Yup.string().oneOf(
      [ref("new_password")],
      "Passwords do not match"
    ),
  });

  const handleSubmit = async (values, setFieldError) => {
    dispatch(updatePassword({ payload: values }))
      .unwrap()
      .then(() => {
        dispatch(closeAllModal());
        toast.success("Change Password Successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      })
      .catch((e) => {
        if (e) {
          const errors = e.response.data.errors;
          let errMsg = "";
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errMsg = errors[item];
              setFieldError(item, errors[item]);
            });
          }

          toast.error(errMsg[0], {
            theme: "colored",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
        }
      });
  };

  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="profile-modal-container">
            <Formik
              initialValues={userData}
              validationSchema={loginSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ errors, isValid, isSubmitting, setFieldValue, values }) => (
                <Form>
                  <Header
                    title={"Change Passsord"}
                    label="Save"
                    isSubmitting={isSubmitting}
                  />
                  <MDBContainer className="edit-container">
                    <MDBRow>
                      <InputField
                        className="service-field"
                        name="password"
                        type="password"
                        errors={errors}
                        placeholder="Current Password"
                      />
                    </MDBRow>
                    <MDBRow>
                      <InputField
                        className="service-field"
                        name="new_password"
                        type="password"
                        errors={errors}
                        placeholder="New Password"
                      />
                    </MDBRow>
                    <MDBRow>
                      <InputField
                        className="service-field"
                        name="confirm_new_password"
                        type="password"
                        errors={errors}
                        placeholder="Confirm Password"
                      />
                    </MDBRow>
                  </MDBContainer>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
