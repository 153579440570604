import { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBProgress,
  MDBProgressBar,
  MDBRow,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { Formik, Form, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//redux
import { useDispatch, useSelector } from "react-redux";
import { planningInfo } from "../../../redux/reducer/planingReducer";

//component
import ModalBox from "../../../components/modal/half.modal.box";
import { handleModalBoxPayment } from "../../../redux/reducer/planingReducer";
import { useNavigate } from "react-router-dom";

export default function CheckOutModal({ show }) {
  const { id } = useSelector((state) => state.planning);
  const { title, total_paid, reference_no, package_title } = useSelector(
    (state) => state.planning.planningInfo
  );

  const dispatch = useDispatch();
  const navigator = useNavigate();

  useEffect(() => {
    dispatch(planningInfo({ id }));
  }, []);

  return (
    <ModalBox
      className="check-out-container"
      show={show}
      backDrop={false}
      header={
        <MDBRow>
          <MDBCol
            onClick={() =>
              dispatch(handleModalBoxPayment({ title: "payment" }))
            }
          >
            <Icon
              icon="fluent-emoji-high-contrast:cross-mark"
              color="#707070"
              width="20"
              height="25"
            />
          </MDBCol>
          <MDBCol className="col-8">
            <label>Total Payment</label>
          </MDBCol>
          <MDBCol></MDBCol>
        </MDBRow>
      }
      content={
        <>
          <MDBContainer className="planning-payment-container">
            <MDBRow>
              <p>Includes payment for multiple bookings</p>
            </MDBRow>
            <MDBRow className="palnning-payment">
              <MDBCol className="col-1">
                <Icon
                  icon="heroicons:ticket-20-solid"
                  color="#41B1CD"
                  width="1.2em"
                  height="1.2em"
                />
              </MDBCol>
              <MDBCol>
                <label>{package_title}</label>
              </MDBCol>
            </MDBRow>
            <MDBRow className="palnning-payment">
              <MDBCol>
                <span>Paid : {parseFloat(total_paid).toFixed(2)}</span>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      }
      footer={
        <MDBRow className="navbar-bottom">
          <MDBProgress>
            {/* <MDBProgressBar striped width={currentProcess * 100} /> */}
          </MDBProgress>
          <MDBCol className="pay  col-7">
            <label>
              <span>Sub total</span> RM {parseFloat(total_paid).toFixed(2)}
            </label>
          </MDBCol>
          <MDBCol className="button-pay">
            <MDBBtn
              type="submit"
              onClick={() => navigator(`/payment/${reference_no}`)}
            >
              Pay
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      }
    />
  );
}
