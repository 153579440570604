import config from "../../config.json";
import {
  MDBBtn,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";

export default function VersionModal({ show, setShow }) {
  return (
    <MDBModal
      className="full-modal-box-container"
      tabIndex="-1"
      show={show}
      //   setShow={setShow}
      staticBackdrop
    >
      <MDBModalDialog size="sm">
        <MDBModalContent>
          <MDBModalHeader className="modal-header bg-danger text-white d-flex justify-content-center">
            <MDBModalTitle>New Update</MDBModalTitle>
          </MDBModalHeader>
          <label className="p-3 pt-2 pb-2">
            You current version is <b>{config.version}</b> <br /> Kindy refresh
            get the latest version for better user experience.
          </label>
          <MDBModalFooter>
            <MDBBtn
              className="btn btn-outline-danger"
              color="white"
              onClick={() => setShow(false)}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              className="btn btn-danger"
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
