import { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { MDBContainer, MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import { runLoader, stopLoader } from "../../../redux/reducer/loaderReducer";
//component
import InputField from "../../../components/element/inputField";
import LoginButton from "../../../components/element/button";
import { toast } from "react-toastify";
//auth
import authServices from "../../../services/auth.services";

export default function RegisterForm({
  changeRegisterModal,
  setRegisterForm,
  setData,
  setCode,
  setCodeMessage,
  setTime,
}) {
  const dispatch = useDispatch();
  const { isRunLoader } = useSelector((state) => state.loader);

  const [userData, setUserData] = useState({
    email: "",
  });
  const loginSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Emil Address Format")
      .required("Required"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    dispatch(runLoader());
    try {
      const response = await authServices.emailExist(values);
      if (response) {
        const code = await authServices.registerCode(values.email);
        dispatch(stopLoader());
        setCodeMessage(true);
        changeRegisterModal();
        setTime(60);
        if (code) {
          setCode([code]);
        }
      }
    } catch (ex) {
      dispatch(stopLoader());
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <Formik
      initialValues={userData}
      validationSchema={loginSchema}
      onSubmit={(values, { resetForm, setFieldError }) => {
        handleSubmit({ values, setFieldError });

        setData(values);
        resetForm("");
      }}
    >
      {({ errors, isValid, isSubmitting, setFieldError }) => (
        <Form>
          <MDBContainer className="login-text">
            <MDBRow>
              <MDBCol className="login-text-text">Let’s Strat your trip</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="login-text-text2">
                Get an account to enjoy more benefit on your Selangor trip.
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <label className="login-label">Email</label>

          <InputField
            className="login-field"
            name="email"
            type="email"
            errors={errors}
            placeholder="Enter email addres"
          />
          <LoginButton
            label={"Continue"}
            isValid={isValid}
            isRunLoader={isRunLoader}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
}
