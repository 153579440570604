import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EffectCreative } from "swiper";
import useWindowDimensions from "../../../components/common/window-dimension";

//plugin & framework
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Swiper, SwiperSlide } from "swiper/react";
import { Skeleton } from "primereact/skeleton";

//redux
import { useSelector, useDispatch } from "react-redux";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

export default function PlaceCarousel() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { place, loading } = useSelector((state) => state.home);
  const { height, width } = useWindowDimensions();
  const [responsive, setResponsive] = useState("mobile");
  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);

  return (
    <MDBContainer className="place-carousel-container">
      <MDBRow className="header-container">
        <MDBCol className="col-10">
          <label className="">Nearby Gold Coast Morib</label>
        </MDBCol>
        {responsive === "mobile" ? (
          ""
        ) : (
          <MDBCol
            className={responsive === "mobile" ? "btn-row" : "btn-row  desktop"}
          >
            <MDBBtn
              className={
                responsive === "mobile"
                  ? "btn-tranparent"
                  : "btn-tranparent desktop"
              }
              onClick={() => navigate("/place")}
            >
              See more
            </MDBBtn>
          </MDBCol>
        )}
      </MDBRow>
      <Swiper
        slidesPerView={responsive === "desktop" ? 5 : 2.5}
        spaceBetween={0}
        // loop={true}
        grabCursor={true}
        creativeEffect={{
          prev: {
            shadow: false,
            translate: ["-120%", 0, -500],
          },
          next: {
            shadow: false,
            translate: ["120%", 0, -500],
          },
        }}
        modules={[EffectCreative]}
      >
        {loading ? (
          <>
            <SwiperSlide>
              <Skeleton width="100%" height="10rem" borderRadius="0.5em" />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton width="100%" height="10rem" borderRadius="0.5em" />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton width="100%" height="10rem" borderRadius="0.5em" />
            </SwiperSlide>
          </>
        ) : (
          <>
            {place.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <MDBContainer
                    style={{
                      backgroundImage: `url("${item.image}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      boxShadow: "rgb(0 0 0 / 51%) 0px -70px 25px inset",
                    }}
                    className="place-container"
                    onClick={() => {
                      navigate(`/ticket/${item.id}`);
                    }}
                  >
                    <MDBRow>
                      <MDBCol className="col-12 title">
                        <label className="p-0">{item.title}</label>
                      </MDBCol>
                      <MDBCol className="distance">
                        <label className="p-0">
                          {parseFloat(item.distance).toFixed(0)}
                          <span>km</span>
                        </label>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </SwiperSlide>
              );
            })}
          </>
        )}
      </Swiper>
      {responsive === "mobile" ? (
        <MDBRow className="btn-row">
          <MDBBtn className="btn-tranparent" onClick={() => navigate("/place")}>
            See more
          </MDBBtn>
        </MDBRow>
      ) : (
        ""
      )}
    </MDBContainer>
  );
}
