import { current,createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import apiServices from "../../services/api.services";
import { paymentInfo } from "./paymentReducer";


export const checkoutTicket = createAsyncThunk("post/checkoutTicket",
async ({ payload }, thunkAPI) => {
        try {
        const response = await apiServices.checkoutTicket(payload);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
);

export const checkoutService = createAsyncThunk("post/checkoutService",
async ({ payload }, thunkAPI) => {
        try {
        const response = await apiServices.checkoutService(payload);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  export const checkoutRoom = createAsyncThunk("post/checkoutroom",
async ({ payload }, thunkAPI) => {
        try {
        const response = await apiServices.bookingRoom(payload);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
);

const initialState = {
    checkout: {
    },
    modalBoxCheckOut: false,
    modalBoxCompletePayment: false,
    chargePrice: 0.00,
    serviceQuantity: 0,
    checkoutTitle: "booking",
    checkoutType: "booking",
    checkoutListing: [{
        id: 1,
        label: "Tower missing",
        price: "RM0.00",
    },{
        id: 2,
        label: "Add Tower",
        price: "RM50.00",
    }],
    checkoutData: {
        type: "",
        ticket: [],
        addon: [],
        select_date: []
    },
    testData: {
        ticket: {},
        addon: {},
        select_date: {}
    },
    selectedPackage: [{package:{
        avalability_date_type: "date range"
    }}],
    selectedPackageService: {},
    totalSelectedPackage: 0,
}

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setCheckoutData:(state,action) =>{
            return{
                ...state,
                checkoutData: {
                    type: action.payload.type,
                    ticket: [...state.checkoutData.ticket, action.payload.ticket],
                    addon: [...state.checkoutData.addon, action.payload.addon],
                    select_date: [...state.checkoutData.select_date, action.payload.select_date],
                }
            }
        },
        handleSelectedPackage:(state,action) => {
            const { type, ticket, selectedPackage } = action.payload;
            if(type === "addon"){
                for(var key in selectedPackage ){
                    void(state.chargePrice = parseInt(selectedPackage[key].package_addon[0].current_price))
                    if(key in state.testData.addon){
                        // delete (state.testData.addon[key])
                        // void (state.testData.addon[key].push(selectedPackage[key].package_addon[0]))
                        void (state.testData.addon[key] = [selectedPackage[key].package_addon[0]])
                    } else {
                        void (state.testData.addon[key] = [selectedPackage[key].package_addon[0]])
                    }
                }
            }else{
                for(var key in ticket ){
                    if(key in state.testData.ticket){
                        if(ticket[key].quantity < 1){
                            delete (state.testData.ticket[key])
                        }else{
                            void (state.testData.ticket[key] = ticket[key])
                        }
                    } else {
                        void (state.testData.ticket[key] = ticket[key])
                    }
                }
            }
        },
        handleSelectedDate:(state,action) => {
            const { selectedDate } = action.payload;

            for(var key in selectedDate ){
                if(key in state.testData.select_date){
                    void (state.testData.select_date[key] = selectedDate[key])
                } else {
                    void (state.testData.select_date[key] = selectedDate[key])
                }
            }
        },
        handleChargePrice:(state,action) => {
            if(action.payload.type === "increment"){
                return{
                    ...state,
                    chargePrice: state.chargePrice + parseInt(action.payload.price),
                }
            }else{
                if(state.chargePrice == 0){
                    return{
                        ...state,
                    }
                }else{
                    return{
                        ...state,
                        chargePrice: state.chargePrice - parseInt(action.payload.price),
                    }
                }
            }
        },
        handleModalBoxCheckOut:(state,action) => {
            return{
                ...state,
                modalBoxCheckOut:!state.modalBoxCheckOut,
                checkoutTitle: action.payload.title,
                checkoutType: action.payload.type,
                chargePrice: state.chargePrice
            }
        },
        closeModalBoxCheckOut:(state,action) => {
            return{
                ...state,
                modalBoxCheckOut: false,
                checkoutTitle: "",
                checkoutType: "",
                chargePrice: 0.00
            }
        },
        handleCompletePayment:(state,action)=>{
            return{
                ...state,
                modalBoxCompletePayment: !state.modalBoxCompletePayment
            }
        },
        addSelectedPackage:(state,action) => {
            return{
                ...state,
                selectedPackage: action.payload.standard,
                totalSelectedPackage: action.payload.standard?action.payload.standard.length : 0
            }
        },
        addSelectedPackageService:(state,action) => {
            return{
                ...state,
                selectedPackageService: action.payload.standard,
                totalSelectedPackage: action.payload.standard?action.payload.standard.length : 0
            }
        },
    },
    extraReducers:{
        [checkoutRoom.pending]: (state, action) => {
            return {
              ...state,
              checkout: action.payload,
            };
        },
        [checkoutTicket.pending]: (state, action) => {
            return {
              ...state,
            };
        },
        [checkoutTicket.fulfilled]: (state, action) => {
            return {
              ...state,
              checkout: action.payload,
              chargePrice: 0
            };
        },
        [checkoutTicket.rejected]: (state, action) => {
            return {
              ...state,
              chargePrice: 0
            };
        },        
        [checkoutService.pending]: (state, action) => {
            return {
              ...state,
            };
        },
        [checkoutService.fulfilled]: (state, action) => {
            return {
              ...state,
              chargePrice: 0,
              modalBoxCheckOut: false,
              checkout: action.payload,
            };
        },
        [checkoutService.rejected]: (state, action) => {
            return {
              ...state,
              chargePrice: 0,
              modalBoxCheckOut: false,
            };
        },
    }
})

export const { 
    closeModalBoxCheckOut,
    setCheckoutData,
    handleChargePrice,
    handleSelectedDate,
    addSelectedPackage,
    handleSelectedPackage,
    handleModalBoxCheckOut,
    handleCompletePayment,
    addSelectedPackageService
} = checkoutSlice.actions;
const { reducer } = checkoutSlice
export default reducer