import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBCardImage,
  MDBCol,
  MDBRow,
  MDBCardHeader,
} from "mdb-react-ui-kit";
import { toast } from "react-toastify";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  handleModalBoxPayment,
  planningInfo,
} from "../../../redux/reducer/planingReducer";
import moment from "moment/moment";
export default function PlaningCard({ ticket, planning, index }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { reference_no } = useSelector((state) => state.planning.planningInfo);

  const payment = (result) => {
    navigate(`/payment/${result}`);
  };

  return (
    <MDBCol className="col-12 col-lg-6 col-md-6 p-0" key={index}>
      <MDBCard className="planing-card">
        <MDBCardHeader onClick={() => navigate(`/planning/${planning.id}`)}>
          <Icon
            icon="heroicons:ticket-20-solid"
            color="#1d71bf"
            width="1.8em"
            height="2em"
          />
          <label className="ticket-title">{planning.title}</label>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBRow className="m-0">
            <MDBCol
              className="col-4 p-0"
              onClick={() => navigate(`/planning/${planning.id}`)}
            >
              <MDBCardImage src={planning.image} className="img-fluid" />
            </MDBCol>

            <MDBCol className="col-8 card-content">
              <MDBRow>
                {planning.ticket_status === "booking" ? (
                  <span className="planing-time">
                    Booking Date :{" "}
                    {moment(planning.selected_at).format("DD MMM YYYY")}
                  </span>
                ) : planning.ticket_status === "use now" ? (
                  <span className="planing-time">
                    Booking Date :{" "}
                    {moment(planning.selected_at).format("DD MMM YYYY")}
                  </span>
                ) : planning.ticket_status === "used" ? (
                  <span className="planing-time">
                    Used Date :{" "}
                    {moment(new Date(planning.used_at)).format("DD MMM YYYY")}
                  </span>
                ) : (
                  <span className="planing-time">
                    Use This Ticket Before:{" "}
                    {planning.pay_before_at === null
                      ? ""
                      : moment(new Date(planning.pay_before_at)).format(
                          "DD MMM YYYY"
                        )}
                  </span>
                )}
              </MDBRow>
              <MDBRow>
                <span className="planing-text">
                  To pay : RM&nbsp;
                  {parseFloat(planning.amount).toFixed(0)}
                </span>
              </MDBRow>
              {planning.ticket_status === "expired" ? (
                <MDBBtn type="button" disabled className="ex-btt">
                  {planning.ticket_status}
                </MDBBtn>
              ) : planning.ticket_status === "used" ? (
                <MDBBtn type="button" disabled className="ex-btt">
                  {planning.ticket_status}
                </MDBBtn>
              ) : planning.ticket_status === "waiting for payment" ? (
                <MDBBtn
                  type="button"
                  onClick={() => {
                    dispatch(planningInfo({ id: planning.id }))
                      .then((result) => {
                        payment(result.payload.reference_no);
                      })
                      .catch((ex) => {
                        const errors = ex.response.data.errors;
                        let errMsg = "";
                        if (errors && Object.keys(errors).length > 0) {
                          Object.keys(errors).map((item) => {
                            errMsg = errors[item];
                          });
                        }
                        toast.error(errMsg[0], {
                          theme: "colored",
                          hideProgressBar: true,
                          closeOnClick: true,
                          closeButton: false,
                        });
                      });
                  }}
                >
                  {planning.ticket_status}
                </MDBBtn>
              ) : (
                <MDBBtn
                  type="button"
                  onClick={() => navigate(`/planning/${planning.id}`)}
                >
                  {planning.ticket_status}
                </MDBBtn>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  );
}
