import { MDBRow, MDBCheckbox } from "mdb-react-ui-kit";
export default function CheckBox({ label, setFieldValue, name, values }) {
  return (
    <MDBRow>
      <MDBCheckbox
        name="flexCheck"
        id="flexCheckDefault"
        value={values}
        label={label}
        onChange={(event) => {
          setFieldValue(name, event.target.checked);
        }}
      />
    </MDBRow>
  );
}
