import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../components/common/window-dimension";

//plugin & framework
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, Autoplay } from "swiper";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBCard,
} from "mdb-react-ui-kit";
import { Skeleton } from "primereact/skeleton";
import { Rating } from "primereact/rating";
import { Icon } from "@iconify/react";
//redux
import { useSelector, useDispatch } from "react-redux";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

export default function RoomCarousel() {
  const { room, loading, rating } = useSelector((state) => state.home);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const [responsive, setResponsive] = useState("mobile");

  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);

  return (
    <MDBContainer className="room-carousel-container">
      <MDBRow className="header-container">
        <MDBCol className="col-9">
          <label>Staying with Gold Coast</label>
        </MDBCol>
      </MDBRow>
      <Swiper
        slidesPerView={responsive === "desktop" ? 2 : 1}
        // loop={true}
        grabCursor={true}
        effect={"creative"}
        modules={[Autoplay]}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        {loading ? (
          <SwiperSlide>
            <Skeleton width="100%" height="6.5rem" borderRadius="0.5em" />
          </SwiperSlide>
        ) : (
          <>
            {room.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <MDBContainer className="promotion-container">
                    <MDBCard
                      onClick={() => {
                        navigate(`/room/${item.id}`);
                      }}
                    >
                      <MDBRow>
                        <MDBCol className="col-5  image-col">
                          <MDBCardImage
                            className="banner-image"
                            src={item.image}
                            width="100%"
                          />
                        </MDBCol>
                        <MDBCol className="col-7 room-detail">
                          <MDBRow className="title">
                            <label>{item.title}</label>
                          </MDBRow>
                          {item.rating.map((rating, index) => {
                            return (
                              <MDBRow className="rating-row" key={index}>
                                <MDBCol className="col-4">
                                  <MDBRow className="MDBRow-text">
                                    <Rating
                                      value={parseInt(rating.rating)}
                                      start={5}
                                      readOnly={true}
                                      cancel={false}
                                      onIcon={
                                        <Icon
                                          icon="ant-design:star-filled"
                                          color="#ffcb46"
                                          width="11"
                                          height="11"
                                        />
                                      }
                                      offIcon={
                                        <Icon
                                          icon="ant-design:star-filled"
                                          color="#D6D6D6"
                                          width="11"
                                          height="11"
                                        />
                                      }
                                    />
                                  </MDBRow>
                                </MDBCol>
                                <MDBCol className="col-8">
                                  <label className="label-text">
                                    {rating.label}
                                  </label>
                                </MDBCol>
                              </MDBRow>
                            );
                          })}

                          <MDBRow>
                            <label className="label-text">
                              Room Size: {item.size}sf
                            </label>
                          </MDBRow>
                          <MDBRow className="room-message">
                            <MDBCol>
                              <label className="label-text">
                                from&nbsp;<label className="rm">RM</label>
                                <label className="money">
                                  {item.current_price}
                                </label>
                                / per night
                              </label>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  </MDBContainer>
                </SwiperSlide>
              );
            })}
          </>
        )}
      </Swiper>
    </MDBContainer>
  );
}
