import { current,createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const paymentInfo = createAsyncThunk("get/paymentInfo",
    async({ payload }) => {
        const response = await apiServices.paymentInfo(payload)
        return response
    }
)


export const completePayment = createAsyncThunk("post/completePayment",
async ({ payload }, thunkAPI) => {
    try {
        const response = await apiServices.completePayment(payload);
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
);

export const topup = createAsyncThunk("post/topup", async ({payload},thunkAPI) => {
  try {
  const response = await apiServices.postTopup(payload);
  return response;
}   catch(error)  {
  return thunkAPI.rejectWithValue(error);
}
}  );

export const getPayment = createAsyncThunk("get/paymentUrl",
    async( paymentUrl ) => {
        const response = await apiServices.getPaymentUrl(paymentUrl)
        return response
    }
)

export const postPayment = createAsyncThunk("post/paymentUrl",
    async({  }) => {
        const response = await apiServices.postPaymentUrl()
        return response
    }
)

const initialState = {
  payment: {
   
  },
  topup: {},
  url:{},
  url1:{}
}

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers:{
      closePaymentPage:(state,action) =>{
        return{
            ...state,
            payment: {},
        }
    },
    },
    extraReducers:{
        [paymentInfo.pending]: (state, action) => {
            return {
              ...state,
              payment: {}
            };
        },
        [paymentInfo.fulfilled]: (state, action) => {
            return {
              ...state,
              payment: action.payload,
            };
        },
        [paymentInfo.rejected]: (state, action) => {
            return {
              ...state,
            };
        },
        [topup.pending]: (state, action) => {
          return {
            ...state,
          };
        },
        [topup.fulfilled]: (state, action) => {
          return {
            ...state,
            topup: action.payload,
          };
      },
      [getPayment.pending]: (state, action) => {
        return {
          ...state,
        };
      },
      [getPayment.fulfilled]: (state, action) => {
        return {
          ...state,
          url: action.payload,
        };
    },
    [postPayment.pending]: (state, action) => {
      return {
        ...state,
      };
    },
    [postPayment.fulfilled]: (state, action) => {
      return {
        ...state,
        url1: action.payload,
      };
  },
    }
})

export const {
  closePaymentPage
} = paymentSlice
const { reducer } = paymentSlice
export default reducer