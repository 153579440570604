import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const profile = createAsyncThunk("get/profile", async () => {
  const response = await apiServices.getProfile();
  return response;
});
export const profileinfo = createAsyncThunk("get/profile", async () => {
  const response = await apiServices.profileInfo();
  return response;
});

export const balance = createAsyncThunk("get/balance", async () => {
  const response = await apiServices.getWalletBalance();
  return response.data;
});

export const purchaseHistory = createAsyncThunk("get/history", async ({ search,
  startDate,
  endDate,
  length,
  start,}) => {
  const response = await apiServices.getPurchaseHistory({ search,
    startDate,
    endDate,
    length,
    start,});
  return response.aaData;
});

export const paymentQr = createAsyncThunk("get/paymentQr", async () => {
  const response = await apiServices.getPaymentQr();
  return response.data;
});

export const updateProfile = createAsyncThunk(
  "post/completePayment",
  async ({ payload }, thunkAPI) => {
    try {
      const response = await apiServices.updateProfile(payload);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "post/completePayment",
  async ({ payload }, thunkAPI) => {
    try {
      const response = await apiServices.changePasword(payload);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  data: {},
  copy: false,
  loading: false,
  balance:{},
  history:[],
  qr:{},
  profile:{},
  password:{},
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers:{
    setCopy:(state,action)=>{
      state.copy = action.payload
    }
  },
  extraReducers: {
    [profile.pending]: (state, action) => {
      state.loading = true;
    },
    [profile.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [updateProfile.pending]: (state, action) => {
      state.Loading = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.Loading = false;
      state.profile = action.payload;
    },
    [updatePassword.pending]: (state, action) => {
      state.Loading = true;
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.Loading = false;
      state.password = action.payload;
    },
    [balance.pending]: (state, action) => {
      state.loading = true;
    },
    [balance.fulfilled]: (state, action) => {
      state.loading = false;
      state.balance = action.payload;
    },
    [purchaseHistory.fulfilled]: (state, action) => {
      state.history = action.payload;
    },
    [paymentQr.fulfilled]: (state, action) => {
      state.qr = action.payload;
    },
  },
});

export const {
  setCopy
} = profileSlice.actions
const { reducer } = profileSlice;

export default reducer;
