import React, { useState } from "react";

//framework & plugin
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import {
  MDBBtn,
  MDBContainer,
  MDBProgress,
  MDBProgressBar,
} from "mdb-react-ui-kit";

//redux
import { useDispatch } from "react-redux";
import { closeModalLogin } from "../../redux/reducer/modalReducer";
import { handleModalBoxCheckOut } from "../../redux/reducer/checkoutReducer";

export default function ModalBox({
  show,
  header,
  footer,
  content,
  backDrop,
  className,
}) {
  return (
    <BottomSheet
      open={show}
      onDismiss={false}
      // snapPoints={({ maxHeight }) => [0.45 * maxHeight, 0.83 * maxHeight]}
      // defaultSnap={({ lastSnap, snapPoints }) => {
      //   lastSnap ?? Math.max(...snapPoints);
      // }}
      snapPoints={({ maxHeight }) => [maxHeight / 1.25]}
      blocking={backDrop}
      header={header}
      footer={footer}
      className={className}
    >
      <MDBContainer>{content}</MDBContainer>
    </BottomSheet>
  );
}
