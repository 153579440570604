import { MDBRadio, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Swiper, SwiperSlide } from "swiper/react";
//redux
import { useSelector, useDispatch } from "react-redux";
import { planning } from "../../../redux/reducer/planingReducer";

export default function PlaningNavbar({ setTicket, ticket }) {
  const dispatch = useDispatch();

  const showTicketList = (e) => {
    const ticketList = e.target.value;
    setTicket(ticketList);
    dispatch(planning({ type: ticketList }));
  };
  return (
    <MDBRow className="planing-radio">
      <Swiper slidesPerView={3.3}>
        <SwiperSlide>
          <MDBCol className="radio">
            <MDBRadio
              btn
              btnColor="secondary"
              id="btn-radio"
              name="options"
              label="All"
              checked={ticket === "all"}
              wrapperTag="label"
              value="all"
              onChange={(e) => showTicketList(e)}
            />
          </MDBCol>
        </SwiperSlide>
        <SwiperSlide>
          <MDBCol className="radio">
            <MDBRadio
              btn
              btnColor="secondary"
              id="btn-radio2"
              name="options"
              label="Activity"
              wrapperTag="label"
              value="activity"
              onChange={(e) => showTicketList(e)}
            />
          </MDBCol>
        </SwiperSlide>
        <SwiperSlide>
          <MDBCol className="radio">
            <MDBRadio
              btn
              btnColor="secondary"
              id="btn-radio3"
              name="options"
              label="Event"
              wrapperTag="label"
              value="event"
              onChange={(e) => showTicketList(e)}
            />
          </MDBCol>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <MDBCol className="radio">
            <MDBRadio
              btn
              btnColor="secondary"
              id="btn-radio5"
              name="options"
              label="Promotion"
              wrapperTag="label"
              value="promotion"
              onChange={(e) => showTicketList(e)}
            />
          </MDBCol>
        </SwiperSlide>

        <SwiperSlide>
          <MDBCol className="radio">
            <MDBRadio
              btn
              btnColor="secondary"
              id="btn-radio6"
              name="options"
              label="Room"
              wrapperTag="label"
              value="booking"
              onChange={(e) => showTicketList(e)}
            />
          </MDBCol>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <MDBCol className="radio">
            <MDBRadio
              btn
              btnColor="secondary"
              id="btn-radio7"
              name="options"
              label="Used"
              wrapperTag="label"
              value="used"
              onChange={(e) => showTicketList(e)}
            />
          </MDBCol>
        </SwiperSlide>
      </Swiper>
    </MDBRow>
  );
}
