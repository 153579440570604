import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { closeModalLogin } from "../../../redux/reducer/modalReducer";
//redux
import { useDispatch, useSelector } from "react-redux";
import { runLoader, stopLoader } from "../../../redux/reducer/loaderReducer";
//component
import LoginButton from "../../../components/element/login-button";
import CheckBox from "../../../components/element/checkBox";
import InputField from "../../../components/element/inputField";
import VerficationCodeButton from "../../../components/element/verfication.code.button";
import { sweetAlertHelper } from "../../../components/helper/message.modal";
import { toast } from "react-toastify";
//auth
import authServices from "../../../services/auth.services";

export default function RegisterVerficationForm({
  data,
  code,
  setCode,
  codeMessage,
  setCodeMessage,
  time,
  setTime,
  setChangeBtt,
  referral_code,
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { isRunLoader } = useSelector((state) => state.loader);

  //data
  const writeReferralCode = () => {
    setOpen(!open);
  };

  const [userData, setUserData] = useState({
    username: data.email,
    verification_code: "",
    invitation_code: referral_code ? referral_code : "",
    checkBox: false,
  });
  const [userData2, setUserData2] = useState({
    type: "email verification",
    password: "",
  });
  //schema
  const loginSchema = Yup.object({
    username: Yup.string()
      .email("Invalid Emil Address Format")
      .required("Required"),
    verification_code: Yup.number()
      .required("Required")
      .typeError("Verification code must be a number"),
    invitation_code: Yup.string(),
    checkBox: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
  });
  const passwordSchema = Yup.object({
    password: Yup.string()
      .required("Required")
      .min(8, "Minimum of 8 characters")
      .max(10, "Maximum of 10 characters")
      .matches(
        /[0-9][a-zA-Z]|[a-zA-Z][0-9]/,
        "Password must enter numbers and characters"
      ),
  });
  //api
  const sendCode = async () => {
    setCodeMessage(true);
    setTime(60);
    try {
      const response = await authServices.registerCode(userData.username);
      if (response) {
        setCode([response]);
      }
    } catch (ex) {}
  };

  const beforeRegister = async ({ values, setSubmitting, setFieldError }) => {
    dispatch(runLoader());
    try {
      const response = await authServices.signUp(values);
      if (response) {
        setChangeBtt(false);
        dispatch(stopLoader());
        setUserData2((userValues) => ({
          ...userValues,
          email: values.username,
          // verification_code: values.verification_code,
        }));
        toast.success("Success", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        handleNextStep();
      }
    } catch (ex) {
      setSubmitting(false);
      dispatch(stopLoader());
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
      // toast.error("Oops... Verification Code is Invalid ", {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   theme: "colored",
      // });
    }
  };

  const handleSubmit = async ({ values, setFieldError }) => {
    dispatch(runLoader());
    try {
      const response = await authServices.changePassword({
        values,
      });
      if (response) {
        const response = await authServices.login({
          username: values.email,
          password: values.password,
        });
        if (response) {
          dispatch(stopLoader());
          dispatch(closeModalLogin());
          toast.success(" Login Success", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        }
      }
    } catch (ex) {
      dispatch(stopLoader());
      dispatch(closeModalLogin());
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
    time === 0 && setCodeMessage(false);
  }, [time]);
  //step
  const [currentStep, setCurrent] = useState(0);

  const handleNextStep = (newUserData) => {
    setUserData((prev) => ({ ...prev, ...newUserData }));
    setCurrent((prev) => prev + 1);
  };

  const handlePrevStep = (newUserData) => {
    setUserData((prev) => ({ ...prev, ...newUserData }));
    setCurrent((prev) => prev - 1);
  };

  const steps = [
    <StepOne
      userData={userData}
      loginSchema={loginSchema}
      beforeRegister={beforeRegister}
      sendCode={sendCode}
      writeReferralCode={writeReferralCode}
      open={open}
      codeMessage={codeMessage}
      time={time}
      isRunLoader={isRunLoader}
    />,
    <StepTwo
      userData2={userData2}
      passwordSchema={passwordSchema}
      handleSubmit={handleSubmit}
      isRunLoader={isRunLoader}
    />,
  ];

  return <div>{steps[currentStep]}</div>;
}

const StepOne = ({
  userData,
  loginSchema,
  beforeRegister,
  sendCode,
  writeReferralCode,
  open,
  time,
  codeMessage,
  isRunLoader,
}) => {
  return (
    <Formik
      initialValues={userData}
      validationSchema={loginSchema}
      onSubmit={(
        values,
        { resetForm, setSubmitting, setFieldValue, setFieldError }
      ) => {
        beforeRegister({ values, setSubmitting, setFieldError });
        setFieldValue("verification_code", "");
        // resetForm({ values: "" });
      }}
    >
      {({
        errors,
        isValid,
        isSubmitting,
        setFieldValue,
        values,
        handleReset,
      }) => (
        <Form>
          <MDBContainer className="login-text">
            <MDBRow>
              <MDBCol className="login-text-text">Let’s Strat your trip</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="login-text-text2">
                Get an account to enjoy more benefit on your Selangor trip.
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <label className="login-label">Email</label>

          <MDBRow className="code-center">
            <InputField
              className="login-field"
              name="username"
              type="email"
              errors={errors}
              codeMessage={codeMessage}
              time={time}
              placeholder="Enter Email Addres"
            />
            {codeMessage === true ? (
              // <VerficationCodeButton label="Wait" />
              ""
            ) : (
              <VerficationCodeButton
                label="RESEND"
                sendCode={sendCode}
                values={values}
              />
            )}
          </MDBRow>

          <InputField
            className="login-field"
            name="verification_code"
            type="numbers"
            errors={errors}
            placeholder="Enter email verification code"
          />
          <MDBRow className="message-btt">
            <MDBCol>
              <label className="form-label">Referral Code (Optional)</label>
              {open === true ? (
                <Icon
                  icon="bi:caret-up-fill"
                  rotate="180deg"
                  color="#41b1cd"
                  onClick={() => writeReferralCode()}
                />
              ) : (
                <Icon
                  icon="bi:caret-up-fill"
                  color="#41b1cd"
                  rotate={1}
                  onClick={() => writeReferralCode()}
                />
              )}
            </MDBCol>
            {open === true ? (
              <InputField
                className="login-field"
                name="invitation_code"
                type="invitation_code"
                errors={errors}
              />
            ) : (
              ""
            )}
          </MDBRow>

          <LoginButton
            label="Sign UP"
            isValid={isValid}
            isSubmitting={isSubmitting}
            isRunLoader={isRunLoader}
          />

          <MDBRow>
            <CheckBox
              name="checkBox"
              label={
                <label>
                  I have read and agree to gold coast resort
                  <span className="check-text">&nbsp;Term of Service</span> and
                  <span className="check-text">&nbsp; Privacy Policy</span>
                </label>
              }
              setFieldValue={setFieldValue}
              values={values}
            />
          </MDBRow>
        </Form>
      )}
    </Formik>
  );
};

const StepTwo = ({ userData2, passwordSchema, handleSubmit, isRunLoader }) => {
  return (
    <Formik
      initialValues={userData2}
      validationSchema={passwordSchema}
      onSubmit={(values, { setFieldError }) => {
        handleSubmit({ values, setFieldError });
      }}
    >
      {({ errors, isValid, isSubmitting, setFieldError }) => (
        <Form>
          <MDBContainer className="login-text">
            <MDBRow>
              <MDBCol className="login-text-text">
                Verification Success !
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="login-text-text2">
                Get an account to enjoy more & benifit on your selangor trip.
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <label className="login-label">Password</label>

          <InputField
            className="login-field "
            name="password"
            type="password"
            errors={errors}
            placeholder="Enter password"
          />

          <LoginButton
            label={"Sign Up"}
            isValid={isValid}
            isSubmitting={isSubmitting}
            isRunLoader={isRunLoader}
          />
        </Form>
      )}
    </Formik>
  );
};
