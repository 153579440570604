import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/full.modal.box";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import * as Yup from "yup";
import { Formik, Field, Form, FormikConsumer } from "formik";
import { Promotion1 } from "../../../assets/images";
import Header from "../../../components/header/header-five";
import InputField from "../../../components/element/inputField";

export default function ModalBooking({ title, tag }) {
  return (
    <ModalBox
      show={true}
      screenSize="fullscreen"
      content={
        <MDBModalBody className="profile-modal-container">
          <Header title={"Choose Date"} />
          <MDBRow>
            <MDBCol className="col-9">
              <label>
                {title ? title : "Romantic Gold Coast Pathhouse 5th Floor"}
              </label>
            </MDBCol>
            <MDBCol>Detalis</MDBCol>
          </MDBRow>
          <MDBRow>
            {tag
              ? tag.map((tag, index) => {
                  return (
                    <>
                      <MDBCol className="badge col-2 p-0" key={index}>
                        <label>{tag.text}</label>
                      </MDBCol>
                    </>
                  );
                })
              : ""}
          </MDBRow>
          <MDBContainer></MDBContainer>
        </MDBModalBody>
      }
    />
  );
}
