import {
  MDBBadge,
  MDBBtn,
  MDBCheckbox,
  MDBCol,
  MDBContainer,
  MDBRadio,
  MDBRow,
} from "mdb-react-ui-kit";
import { useState } from "react";
import InputCount from "../../element/inputCount";
import { Icon } from "@iconify/react";
import { handleSelectedDate } from "../../../redux/reducer/checkoutReducer";
import { useEffect } from "react";
import { useFormikContext } from "formik";
import * as Yup from "yup";
import { Calendar } from "primereact/calendar";
import apiServices from "../../../services/api.services";
import moment from "moment/moment";

//redux
import { useDispatch, useSelector } from "react-redux";

export default function CheckoutBooking({ roomId, step, item, setValidation }) {
  const { title, tag, name, policy } = useSelector(
    (state) => state.booking.roomInfo
  );
  const dispatch = useDispatch();
  const [moreContent, setMoreContent] = useState(false);
  const [invalidDates, setInvalidDates] = useState([new Date()]);
  const [date, setDate] = useState("");
  let calenderDate = date;
  const [time, setTime] = useState("");

  const { values, setValues, setFieldValue } = useFormikContext();

  const getCalender = async (roomId) => {
    const response = await apiServices.getCanlenderInfo(roomId);
    if (response) {
      let newDate = [];
      response.map((item) => newDate.push(new Date(item)));
      setInvalidDates(newDate);
    }
  };

  const onChangenDate = (e) => {
    if (
      moment(String(e.value[0])).format("DD MMM YYYY") !==
      moment(String(e.value[1])).format("DD MMM YYYY")
    ) {
      if (e.value[0] !== null && e.value[1] === null) {
        setDate(e.value);
        setFieldValue(
          "booking_start_at",
          moment(e.value[0]).format("YYYY-MM-DD")
        );
        setFieldValue("booking_end_at", moment(e.value[0]).add(1, "days"));
      } else {
        setDate(e.value);
        setFieldValue(
          "booking_start_at",
          moment(e.value[0]).format("YYYY-MM-DD")
        );
        setFieldValue("booking_end_at", moment(e.value[1]).add(1, "days"));
      }
    } else {
    }
  };

  useEffect(() => {
    if (roomId) {
      getCalender(roomId);
    }
  }, [roomId]);

  return (
    <MDBContainer className="content-container" breakpoint="none">
      <MDBRow className="container-subtitle">
        {/* <MDBCol className="col-9"> */}
        <label>{name}</label>
        {/* </MDBCol> */}
        {/* <MDBCol className="">
          <label className="detail-button">Details</label>
        </MDBCol> */}
      </MDBRow>
      <MDBRow className="badge-row">
        {tag
          ? tag.map((tag, index) => {
              return (
                <MDBCol
                  key={index}
                  style={{ background: `#${tag.color}` }}
                  className="badge col-3"
                >
                  {tag.text}
                </MDBCol>
              );
            })
          : ""}
      </MDBRow>
      <MDBRow className="booking-calander">
        <div className="checkout-container">
          <MDBCol className="col-5 check-in-label-col">
            <MDBRow>
              <label>
                {moment(values.booking_start_at).format("DD MMM YYYY")}
              </label>
            </MDBRow>
            <MDBRow>
              <span>Check in at</span>
            </MDBRow>
          </MDBCol>
          <MDBCol className="col-2 label-col">
            <label>-</label>
          </MDBCol>
          <MDBCol className="col-5 check-out-label-col">
            <MDBRow>
              <label>
                {moment(values.booking_end_at).format("DD MMM YYYY")}
              </label>
            </MDBRow>
            <MDBRow>
              <span>Check out at</span>
            </MDBRow>
          </MDBCol>
        </div>
        <Calendar
          minDate={new Date()}
          id="disableddays"
          selectionMode="range"
          value={calenderDate}
          dateFormat="dd/mm/yy"
          onChange={(e) => onChangenDate(e)}
          inline
          disabledDates={invalidDates}
        />
      </MDBRow>
      <MDBContainer className="detail-container">
        <MDBRow className="detail-row justify-content-center align-items-center">
          <label className="detail-title">Term & condition</label>
          <MDBCol
            className={moreContent ? "detail-text" : "detail-text hideHeight"}
          >
            {policy}
          </MDBCol>
          <label onClick={() => setMoreContent(!moreContent)}>
            {moreContent ? "show less" : "see more"}
          </label>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  );
}
