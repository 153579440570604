import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const planning = createAsyncThunk("get/planning", async ({type}) => {
  const response = await apiServices.getPlanning(type);
  return response;
});

export const planningInfo = createAsyncThunk(
  "get/planningInfo",
  async ({ id }) => {
    const response = await apiServices.getPlanningInfo(id);
    return response;
  }
);

export const booking = createAsyncThunk(
  "post/ticket",
  async ({ payload},thunkAPI) => {
    try{
    const response = await apiServices.activateTicket(payload);
    return response; }  catch (error) {
      return thunkAPI.rejectWithValue(error);
    } 
  }
);

const initialState = {
  listing: [],
  loading: false,
  planningInfo: {
    package_detail: []
  },
  booking:{},
  modalBoxCheckOut:false,
  checkoutTitle:"",
  chargePrice:"",
  id:"",
};

const planningSlice = createSlice({
  name: "planning",
  initialState,
  reducers: {
    handleModalBoxPayment:(state,action) => {
        return{
            ...state,
            modalBoxCheckOut:!state.modalBoxCheckOut,
            checkoutTitle: action.payload.title,
            id:action.payload.id,
            // checkoutType: action.payload.type,
            chargePrice: 0.00
        }
    },
    clearPlaningInfo:(state,action) => {
      return{
          ...state,
          planningInfo: {
            package_detail: []
          },
      }
  },
},
  extraReducers: {
    [planning.pending]: (state, action) => {
      state.loading = true;
    },
    [planning.fulfilled]: (state, action) => {
      state.loading = false;
      state.listing = action.payload;
    },
    [planningInfo.pending]: (state, action) => {
      state.loading = true;
    },
    [planningInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.planningInfo = action.payload;
    },
    [booking.pending]: (state, action) => {
      return {
        ...state,
      };
    },
    [booking.fulfilled]: (state, action) => {
      return {
        ...state,
        booking: action.payload,
      };
  },
  },
});

export const {
  clearPlaningInfo,
  handleModalBoxPayment,
}= planningSlice.actions;
const { reducer } = planningSlice;
export default reducer;
