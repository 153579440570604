import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { Skeleton } from "primereact/skeleton";
import { Rating } from "primereact/rating";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useSelector, useDispatch } from "react-redux";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

export default function ActivityCard() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activity, loading, rating } = useSelector((state) => state.home);
  const { height, width } = useWindowDimensions();
  const [responsive, setResponsive] = useState("mobile");
  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);
  return (
    <MDBContainer className="activity-card-container">
      <MDBRow className="header-container">
        <MDBCol className="col-10">
          <label className="">Activity in morib</label>
        </MDBCol>
        {responsive === "mobile" ? (
          ""
        ) : (
          <MDBCol
            className={responsive === "mobile" ? "btn-row" : "btn-row  desktop"}
          >
            <MDBBtn
              className={
                responsive === "mobile"
                  ? "btn-tranparent"
                  : "btn-tranparent desktop"
              }
              onClick={() => navigate("/activity")}
            >
              See more
            </MDBBtn>
          </MDBCol>
        )}
      </MDBRow>
      <MDBRow>
        {loading ? (
          <>
            {[1, 2, 3, 4].map((item, index) => {
              return (
                <MDBCol key={index} className="col-6 col-md-3 p-1">
                  <MDBCard>
                    <Skeleton width="100%" height="5rem" borderRadius="0.5em" />
                    <MDBCardBody>
                      <Skeleton width="4em" height="1rem" className="mb-2" />
                      <Skeleton width="100%" height="1rem" className="mb-2" />
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              );
            })}
          </>
        ) : (
          <>
            {activity.map((item, index) => {
              return (
                <>
                  {index <= 3 ? (
                    <MDBCol key={index} className="col-6 col-md-3 p-1">
                      <MDBCard>
                        <MDBRipple
                          rippleColor="light"
                          rippleTag="div"
                          className="bg-image hover-overlay"
                          onClick={() => {
                            navigate(`/ticket/${item.id}`);
                          }}
                        >
                          <MDBCardImage
                            src={item.image}
                            alt="..."
                            position="top"
                          />
                          <MDBCardBody>
                            <MDBCardText>
                              {item.rating.map((rating, index) => {
                                return (
                                  <MDBRow className="rating-row" key={index}>
                                    <label className="rating-label">
                                      {rating.label}:
                                      <Rating
                                        value={parseInt(rating.rating)}
                                        start={5}
                                        readOnly={true}
                                        cancel={false}
                                        onIcon={
                                          <Icon
                                            icon="ant-design:star-filled"
                                            color="#ffcb46"
                                            width="11"
                                            height="11"
                                          />
                                        }
                                        offIcon={
                                          <Icon
                                            icon="ant-design:star-filled"
                                            color="#D6D6D6"
                                            width="11"
                                            height="11"
                                          />
                                        }
                                      />
                                    </label>
                                  </MDBRow>
                                );
                              })}
                              <MDBRow className="title-label">
                                <span>{item.title}</span>
                              </MDBRow>
                              <MDBRow className="price-row d-flex align-items-end">
                                <MDBCol className="col-7">
                                  Now&nbsp;
                                  <span className="price-label-before-discount">
                                    RM
                                    {parseFloat(item.original_price).toFixed(0)}
                                  </span>
                                </MDBCol>
                                {item.current_price && (
                                  <MDBCol className="col-5">
                                    <span className="price-label-after-discount">
                                      RM
                                      {parseFloat(item.current_price).toFixed(
                                        0
                                      )}
                                    </span>
                                  </MDBCol>
                                )}
                              </MDBRow>
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBRipple>
                      </MDBCard>
                    </MDBCol>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </>
        )}
      </MDBRow>
      {responsive === "mobile" ? (
        <MDBRow className="btn-row">
          <MDBBtn
            className="btn-tranparent"
            onClick={() => navigate("/activity")}
          >
            See more
          </MDBBtn>
        </MDBRow>
      ) : (
        ""
      )}
    </MDBContainer>
  );
}
