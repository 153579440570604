import { MDBContainer, MDBModalBody } from "mdb-react-ui-kit";
import { useState } from "react";
import { useSelector } from "react-redux";
import ModalBox from "../../components/modal/full.modal.box";
import BannerAgent from "./component/banner.container";
import ModalBoxBank from "./component/modal.box.bank";
import RoomHistory from "./component/room.history";
import TransferCredit from "./component/transfer.credit";
import WalletDetail from "./component/wallet.detail";
import WalletHistory from "./component/wallet.history";



export default function Agent(){
    const [activeTab,setActiveTab] = useState("tab-1")

    const { modalboxAddBank } = useSelector((state) => state.agent)
    const handleChangeTab = (values) => {
        setActiveTab(values)
    }

    return(
        <ModalBox 
            show={true}
            className="agent-container"
            screenSize="fullscreen"
            content={
                <MDBModalBody>
                    <BannerAgent activeTab={activeTab} handleChangeTab={handleChangeTab}/>
                    <div className="agent-content-container">
                        {activeTab === "tab-1" ? <WalletDetail /> : <TransferCredit />}
                        {activeTab === "tab-1" ? <WalletHistory /> : <RoomHistory />    }
                    </div>
                    {modalboxAddBank && <ModalBoxBank show={modalboxAddBank}/>}
                </MDBModalBody>
            }   
        />
    )
}