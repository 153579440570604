import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalLogin } from "../../redux/reducer/modalReducer";
import Login from "../../pages/Login";
import ProtectedRoute from "../common/protected.route";

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpenLogin } = useSelector((state) => state.modal);
  const { pathname } = useLocation();
  const token = localStorage.getItem("token");
  return (
    <div>
      <ProtectedRoute />
      <MDBNavbar className="navbar-container" fixed="bottom">
        <MDBContainer>
          <MDBCol className="col-3 text-center" onClick={() => navigate("/")}>
            <Icon
              icon="octicon:home-fill-24"
              color={pathname === "/" ? "#77A2C1" : "darkgray"}
              width="30"
              height="27"
            />
            <MDBRow className="label">
              <label>Home</label>
            </MDBRow>
          </MDBCol>
          <MDBCol
            className="col-3 text-center"
            onClick={() => {
              navigate("/service");
            }}
          >
            <Icon
              icon="fa6-solid:door-open"
              color={pathname === "/service" ? "#77A2C1" : "darkgray"}
              width="30"
              height="27"
            />
            <MDBRow className="label">
              <label>Room</label>
            </MDBRow>
          </MDBCol>
          <MDBCol
            className="col-3 text-center"
            onClick={() => {
              if (token) {
                navigate("/planning");
              } else {
                dispatch(openModalLogin());
              }
            }}
          >
            <Icon
              icon="fluent:stack-star-16-regular"
              color={pathname === "/planning" ? "#77A2C1" : "darkgray"}
              width="30"
              height="27"
            />
            <MDBRow className="label">
              <label>My Planning</label>
            </MDBRow>
          </MDBCol>
          <MDBCol
            className="col-3 text-center"
            onClick={() => {
              if (token) {
                navigate("/profile");
              } else {
                dispatch(openModalLogin());
              }
            }}
          >
            <Icon
              icon="gg:profile"
              color={pathname === "/profile" ? "#77A2C1" : "darkgray"}
              width="30"
              height="27"
            />
            <MDBRow className="label">
              <label>Account</label>
            </MDBRow>
          </MDBCol>
        </MDBContainer>
      </MDBNavbar>
      {/* {isOpenLogin && <Login show={isOpenLogin} />} */}
    </div>
  );
}
