import { MDBContainer } from "mdb-react-ui-kit";
import { useState } from "react";
import { useEffect } from "react";
//common component
import Layout from "../../components/common/screen-one";
import Header from "../../components/header/header-one";
import Navbar from "../../components/navbar/navbar-one";
import ServiceForm from "./component/service.form";
import ServiceCategory from "./component/service.category";
import CheckOutModal from "../../components/modal/check.out.modal";
import ModalHistory from "./component/modal.history";
//redux
import { useDispatch, useSelector } from "react-redux";
import { addRoomNumber, roomService } from "../../redux/reducer/serviceReducer";
import { service_history } from "../../redux/reducer/serviceHistoryReducer";
import { closeModalBoxCheckOut } from "../../redux/reducer/checkoutReducer";
import Login from "../Login";
import { useParams } from "react-router";

export default function Service() {
  const dispatch = useDispatch();
  const { referral_code } = useParams();
  const { isOpenLogin, isOpenServiceHistory } = useSelector(
    (state) => state.modal
  );
  const [submit, setSubmit] = useState(false);
  const { modalBoxCheckOut } = useSelector((state) => state.checkout);
  const room_no = localStorage.getItem("room_no");
  const contact = localStorage.getItem("contact_no");

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      // .setAttribute("content", "#12B6DF");
      .setAttribute("content", "#12B6DF");
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.body.style.background =
      "linear-gradient(180deg, #12B6DF 0%, #F8EFE4 49.48%)";
  }, []);

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  useEffect(() => {
    if (room_no && contact) {
      setSubmit(true);
      const values = {
        room: room_no,
        contact: contact,
      };
      dispatch(addRoomNumber(values));
    }
  }, [room_no]);

  useEffect(() => {
    scrollToTop();

    if (isOpenServiceHistory) {
      dispatch(service_history());
    }
  }, [isOpenServiceHistory]);

  useEffect(() => {
    dispatch(closeModalBoxCheckOut());
  }, []);

  return (
    <MDBContainer className="service-container" breakpoint="none">
      <Header headerThree={submit} setSubmit={setSubmit} />
      {submit ? "" : <Navbar />}
      <MDBContainer>
        <h1 className="header">Enjoy Our Room Service</h1>
      </MDBContainer>
      <div className="fixed-container" breakpoint="none">
        {submit ? <ServiceCategory /> : <ServiceForm setSubmit={setSubmit} />}
      </div>
      {modalBoxCheckOut && <CheckOutModal show={modalBoxCheckOut} />}
      {isOpenServiceHistory && <ModalHistory />}
      {isOpenLogin && (
        <Login referral_code={referral_code} show={isOpenLogin} />
      )}
    </MDBContainer>
  );
}
