import { useState } from "react";
import {
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdb-react-ui-kit";
import { MDBContainer } from "mdb-react-ui-kit";

import { Icon } from "@iconify/react";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import ModalBox from "../../../components/modal/full.modal.box";
import Button from "../../../components/element/button";
import InputField from "../../../components/element/inputField";
import { useDispatch, useSelector } from "react-redux";
import { handleModalContact } from "../../../redux/reducer/modalReducer";
import { updateProfile } from "../../../redux/reducer/profileReducer";

export default function ContactForm({ show, values, setFieldValue }) {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    edit_first_name: values.name ? values.name : "",
    edit_last_name: values.last_name ? values.last_name : "",
    edit_phone_number: values.phone_number ? values.phone_number : "",
    edit_email: values.email ? values.email : "",
  });

  const useSchema = Yup.object({
    edit_first_name: Yup.string().required("required"),
    edit_last_name: Yup.string().required("required"),
    edit_email: Yup.string()
      .required("required")
      .email("Please write correct Email"),
    edit_phone_number: Yup.string()
      .required("required")
      .min(10, "Please enter more than or equal to 10 character")
      .max(13, "Must be exactly 13 digits")
      .matches(
        /^([6,0]{1}|\+?[0,1]{1})([0-9]{9,11})$/g,
        // /^([6,0]{1})([0,1]{1})([0-9]{9,11})$/g,
        "Must be phone format"
      ),
  });

  const handleSubmit = (values) => {
    setFieldValue("name", values.edit_first_name);
    setFieldValue("last_name", values.edit_last_name);
    setFieldValue("phone_number", values.edit_phone_number);
    setFieldValue("email", values.edit_email);
    dispatch(handleModalContact());
  };

  return (
    <ModalBox
      show={show}
      screenSize="s"
      content={
        <div>
          <MDBModalHeader>
            <MDBCol
              className="col-2"
              onClick={() => dispatch(handleModalContact())}
            >
              <Icon
                icon="fluent-emoji-high-contrast:cross-mark"
                color="#707070"
                width="20"
                height="25"
              />
            </MDBCol>
            <MDBCol className="col-8 contact-header text-align-center">
              <label>Add Contact Info</label>
            </MDBCol>
            <MDBCol></MDBCol>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              // enableReinitialize={true}
              // validateOnChange={true}
              // validateOnBlur={false}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError, setSubmitting);
                // resetForm({ values: ""})
              }}
            >
              {({ errors, isSubmitting, isValid, status, handleSubmit }) => (
                <Form>
                  <MDBContainer className="contact-formik-form">
                    <InputField
                      className="contact-field"
                      name="edit_first_name"
                      type="text"
                      placeholder="Name"
                    />
                    <InputField
                      className="contact-field"
                      name="edit_last_name"
                      type="text"
                      placeholder="Last Name"
                    />
                    <InputField
                      className="contact-field"
                      name="edit_phone_number"
                      type="text"
                      placeholder="Phone Number"
                    />
                    <InputField
                      className="contact-field"
                      name="edit_email"
                      type="text"
                      placeholder="Email (for receive order number)"
                    />
                    <Button
                      label="Save"
                      type="submit"
                      onClick={() => handleSubmit()}
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                    />
                  </MDBContainer>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </div>
      }
    ></ModalBox>
  );
}
