import { useState } from "react";
import { MDBModalBody, MDBModalHeader,MDBRow,MDBCol } from "mdb-react-ui-kit";
import { MDBContainer } from "mdb-react-ui-kit";

import { Icon } from '@iconify/react';
import { Form, Formik } from "formik";
import * as Yup from "yup";

import ModalBox from "../../../components/modal/full.modal.box";
import Button from "../../../components/element/button";
import InputField from "../../../components/element/inputField";
import { useDispatch, useSelector } from "react-redux";
import { handleModalContact } from "../../../redux/reducer/modalReducer";
import { handleModalboxAddBank } from "../../../redux/reducer/agentReducer";


export default function ModalBoxBank({show}){
    const dispatch = useDispatch()
    const { name,phone_number,email } = useSelector((state) => state.payment.payment)

    const [data,setData] = useState({
        name: name ? name : "",
        // last_name: last_name ? last_name : "",
        phone_name: phone_number ? phone_number :"",
        email: email ? email : "",
    })

    const useSchema = Yup.object({
        name: Yup.string().required("Enter name"),
        // last_name: Yup.string().required("Enter last name"),
        phone_name: Yup.string().required("Enter phhone number"),
        email: Yup.string().required("Enter email"),
      });

    const handleSubmit = (values) => {
    }

    return(
        <ModalBox
        show={show}
        className="bank"
        screenSize="s"
        content={
            <div>
            <MDBModalHeader>
                <MDBCol className="col-2" onClick={() => dispatch(handleModalboxAddBank())}><Icon icon="fluent-emoji-high-contrast:cross-mark" color="#707070" width="20" height="25" /></MDBCol>
                <MDBCol className="col-8 text-align-center"><label>Add contact info</label></MDBCol>
                <MDBCol></MDBCol>
            </MDBModalHeader>
            <MDBModalBody>
            <Formik
            enableReinitialize={true}
            validateOnChange={true}
            // validateOnBlur={false}
            initialValues={data}
            validationSchema={useSchema}
            onSubmit={(values, {setFieldError,resetForm,setSubmitting }) => { 
                handleSubmit(values,setFieldError,setSubmitting);
                // resetForm({ values: ""})     
            }}
        >
            {({setFieldValue, errors, isSubmitting, isValid, status }) => (
                <Form>
                    <MDBContainer className="contact-formik-form">
                        <InputField className="contact-field" name="name" type="text" placeholder="Name"/>
                        {/* <InputField className="contact-field" name="last_name" type="text" placeholder="Last Contact"/> */}
                        <InputField className="contact-field" name="phone_name" type="text" placeholder="Phone Number"/>
                        <InputField className="contact-field" name="email" type="text" placeholder="Email (for receive order number)"/>
                        <Button label="Save" isSubmitting={isSubmitting} isValid={isValid} />
                    </MDBContainer>
                </Form>
            )}
        </Formik>
            </MDBModalBody>
            </div>
        }
        >
        </ModalBox>
    )
}