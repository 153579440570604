import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Description() {
  const [moreContent, setMoreContent] = useState(false);
  const { description } = useSelector((state) => state.detail.ticketInfo);
  const totalTextLength = description ? description.join("").length : "";
  return (
    <MDBCol className="description-container">
      <MDBRow className="container-subtitle">
        <label>Description of the activity</label>
      </MDBRow>
      <MDBContainer
        className={
          moreContent ? "content-container" : "content-container hide-height"
        }
      >
        <ul className="listing ">
          {description
            ? description.map((item, index) => {
                return <li key={index}>{item}</li>;
              })
            : ""}
        </ul>
      </MDBContainer>
      {totalTextLength > 200 ? (
        <MDBRow className="form-group btn-see-more">
          <MDBBtn onClick={() => setMoreContent(!moreContent)}>
            {moreContent === false ? "See More" : "Close"}
          </MDBBtn>
        </MDBRow>
      ) : (
        ""
      )}
    </MDBCol>
  );
}
