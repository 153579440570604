import React from "react";
import { MDBContainer, MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function LoginButton({
  label,
  isSubmitting,
  dirty,
  isValid,
  className,
  onClick,
  handleOnClick,
  ...rest
}) {
  return (
    <MDBRow className="button-container">
      <MDBBtn
        type="submit"
        className="submit-button"
        disabled={!isValid || isSubmitting}
      >
        {label}
      </MDBBtn>
    </MDBRow>
  );
}
