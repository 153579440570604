import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { closeModalLogin } from "../../../redux/reducer/modalReducer";
//redux
import { useDispatch, useSelector } from "react-redux";
import { runLoader, stopLoader } from "../../../redux/reducer/loaderReducer";
//component
import LoginButton from "../../../components/element/login-button";
import InputField from "../../../components/element/inputField";
import VerficationCodeButton from "../../../components/element/verfication.code.button";
import { toast } from "react-toastify";
//auth
import authServices from "../../../services/auth.services";

export default function ForgotForm({ setChangeBtt }) {
  const [time, setTime] = useState(0);
  const [message, setMesage] = useState(false);
  const [code, setCode] = useState([]);
  const dispatch = useDispatch();
  const { isRunLoader } = useSelector((state) => state.loader);
  const [userData, setUserData] = useState({
    email: "",
    verification_code: "",
  });
  const [userData2, setUserData2] = useState({
    type: "forgot password",

    password: "",
  });
  //schema
  const loginSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Emil Address Format")
      .required("Required"),
    verification_code: Yup.number()
      .required("Required")
      .typeError("Verification code must be a number"),
  });

  const fogotSchema = Yup.object({
    password: Yup.string()
      .required("Required")
      .min(8, "Minimum of 8 characters")
      .max(16, "Maximum of 16 characters")
      .matches(
        /[0-9][a-zA-Z]|[a-zA-Z][0-9]/,
        "Password must enter numbers and characters"
      ),
  });
  //api
  const sendForgotCode = async (values) => {
    setMesage(true);
    setTime(60);
    try {
      const response = await authServices.forgotPassword(values.email);
      if (response) {
        setCode([response]);
      }
    } catch (ex) {}
  };

  const beforeChangePassword = async (values) => {
    dispatch(runLoader());
    setChangeBtt(false);
    setUserData2((userValues) => ({
      ...userValues,
      email: values.email,
      verification_code: values.verification_code,
    }));

    handleNextStep();

    dispatch(stopLoader());
  };

  const handleSubmit = async ({ values, setFieldError }) => {
    dispatch(runLoader());
    try {
      const response = await authServices.changePassword({ values });
      if (response) {
        dispatch(stopLoader());
        setChangeBtt(true);
        dispatch(closeModalLogin());
        toast.success("Success", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (ex) {
      setChangeBtt(true);
      dispatch(stopLoader());
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
      toast.error(
        "Oops.. Please double check your verification code and resend",
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
      handlePrevStep();
    }
  };

  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
    time === 0 && setMesage(false);
  }, [time]);
  //step
  const [currentStep, setCurrent] = useState(0);
  const handleNextStep = (newUserData) => {
    setUserData((prev) => ({ ...prev, ...newUserData }));
    setCurrent((prev) => prev + 1);
  };

  const handlePrevStep = (newUserData) => {
    setUserData((prev) => ({ ...prev, ...newUserData }));
    setCurrent((prev) => prev - 1);
  };

  const steps = [
    <StepOne
      userData={userData}
      loginSchema={loginSchema}
      beforeChangePassword={beforeChangePassword}
      sendForgotCode={sendForgotCode}
      message={message}
      time={time}
      isRunLoader={isRunLoader}
    />,
    <StepTwo
      userData2={userData2}
      fogotSchema={fogotSchema}
      handleSubmit={handleSubmit}
      isRunLoader={isRunLoader}
    />,
  ];

  return <div>{steps[currentStep]}</div>;
}
const StepOne = ({
  userData,
  loginSchema,
  beforeChangePassword,
  sendForgotCode,
  message,
  time,
  isRunLoader,
}) => {
  return (
    <Formik
      initialValues={userData}
      validationSchema={loginSchema}
      onSubmit={(values, { setFieldError }) => {
        beforeChangePassword(values, { setFieldError });
      }}
    >
      {({
        errors,
        isValid,
        isSubmitting,
        values,
        setFieldValue,
        handleChange,
      }) => (
        <Form>
          <MDBContainer className="login-text">
            <MDBRow>
              <MDBCol className="login-text-text">
                Opss.. Check your mail box
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="login-text-text2">
                Get an account to enjoy more & benifit on your selangor trip.
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <label className="login-label">Email</label>
          <MDBRow className="code-center">
            <InputField
              className="login-field"
              name="email"
              type="email"
              errors={errors}
              placeholder="Enter email addres"
              message={message}
              time={time}
            />
            {message === true ? (
              ""
            ) : (
              <VerficationCodeButton
                label="SEND"
                sendForgotCode={sendForgotCode}
                values={values}
              />
            )}
          </MDBRow>
          <InputField
            className="login-field"
            name="verification_code"
            type="text"
            errors={errors}
            placeholder="Enter email verification code "
          />

          <LoginButton
            label={"Continue"}
            isValid={isValid}
            isSubmitting={isSubmitting}
            isRunLoader={isRunLoader}
          />
        </Form>
      )}
    </Formik>
  );
};

const StepTwo = ({ userData2, fogotSchema, handleSubmit, isRunLoader }) => {
  return (
    <Formik
      initialValues={userData2}
      validationSchema={fogotSchema}
      onSubmit={(values, { setFieldError }) => {
        handleSubmit({ values, setFieldError });
      }}
    >
      {({ errors, isValid, isSubmitting }) => (
        <Form>
          <MDBContainer className="login-text">
            <MDBRow>
              <MDBCol className="login-text-text">Set Pasword</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="login-text-text2">
                Get an account to enjoy more & benifit on your selangor trip.
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <label className="login-label">Password</label>

          <InputField
            className="login-field"
            name="password"
            type="password"
            errors={errors}
            placeholder="Enter password"
          />

          <LoginButton
            label={"Submit"}
            isValid={isValid}
            isSubmitting={isSubmitting}
            isRunLoader={isRunLoader}
          />
        </Form>
      )}
    </Formik>
  );
};
