import http from "./http.services";
import config from "../config.json";

//login
const apiEndpoint = config.apiEndPoint + "/login";
const verificationCodePassword =
  config.apiEndPoint + "/send_verification_code_forgot_password";
const setPassword = config.apiEndPoint + "/set_password";

//register
const checkEmail = config.apiEndPoint + "/verify_email_if_exist";
const verificationCode = config.apiEndPoint + "/send_verification_code";
const register = config.apiEndPoint + "/register";

const tokenKey = "token";
http.setToken(getToken());

//login
async function login(values) {
  const { data } = await http.post(apiEndpoint, values);
  const token = data.data.token || "";
  localStorage.setItem(tokenKey, token);

  if (token) return true;
  else return false;
}

async function forgotPassword(values) {
  const response = await http.post(verificationCodePassword, {
    email: values,
  });
  return response.data.data.code;
}
async function changePassword({values}) {
  const { data } = await http.post(setPassword, values);
  const status = data.status || "";

  if (status === "success") return true;
  else return false;
}

//register
async function emailExist(values) {
  const { data } = await http.post(checkEmail, values);
  const checked = data.status || "";

  if (checked === "success") return true;
  else return false;
}

async function registerCode(values) {
  const response = await http.post(verificationCode, {
    email: values,
  });
  return response.data.data.code;
}

async function signUp(values) {
  const { data } = await http.post(register, values);
  const status = data.status || "";

  if (status === "success") return true;
  else return false;
}

function getToken() {
  return localStorage.getItem(tokenKey);
}

async function logout() {
  localStorage.clear();
}

function getCurrentUser() {
  const token = localStorage.getItem(tokenKey);
  if (token) return { token };
  else return "";
}

export default {
  login,
  forgotPassword,
  changePassword,
  emailExist,
  registerCode,
  signUp,
  logout,
  getToken,
  getCurrentUser,
};
