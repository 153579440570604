import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBCardImage,
  MDBCol,
  MDBRow,
  MDBContainer,
} from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

export default function PromotionCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ticket } = useSelector((state) => state.promotion);
  const token = localStorage.getItem("token");
  return (
    <>
      {ticket.map((promotion, index) => {
        return (
          <MDBCard className="promotion-card col-12 col-md-5 p-6" key={index}>
            <MDBCardBody>
              <MDBCardImage
                src={promotion.image}
                position="top"
                className="img"
              />
              <MDBCardTitle>{promotion.title}</MDBCardTitle>
              <MDBRow className="promotion-ticket m-0" between>
                <MDBCol size="6" className="col-6 p-0">
                  <label className="promotion-label">
                    {promotion.description}
                  </label>
                </MDBCol>
                <MDBCol size="6" className="promotion-ticket-money p-0">
                  from{" "}
                  <span className="original-ticket">
                    RM
                    {parseFloat(promotion.display_original_price).toFixed(0)}
                  </span>
                  &nbsp;
                  <span className="ticket-money">
                    RM
                    {parseFloat(promotion.display_current_price).toFixed(0)}
                  </span>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBBtn
                  className="promotion-btt"
                  onClick={() => {
                    navigate(`/ticket/${promotion.id}`);
                  }}
                >
                  Buy Now
                </MDBBtn>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        );
      })}
    </>
  );
}
