import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBCol,
  MDBRow,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//REDUX
import { useDispatch } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";
import { closeModalHistoryDetalis } from "../../redux/reducer/modalReducer";
import { handleModalBoxCheckOut } from "../../redux/reducer/checkoutReducer";
import { updateProfile } from "../../redux/reducer/profileReducer";
import { closePaymentPage } from "../../redux/reducer/paymentReducer";

export default function Header({
  title,
  label,
  icon,
  values,
  isSubmitting,
  payment,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <MDBNavbar className="header-five-container">
      <MDBContainer>
        <MDBRow className="header-row">
          <MDBCol className="col-2 d-flex justify-content-center p-0">
            {icon === "icon" ? (
              <div
                className="round-background-icon"
                onClick={() => {
                  // dispatch(closePaymentPage());
                  dispatch(closeModalHistoryDetalis());
                }}
              >
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="darkgray"
                  width="30"
                  height="27"
                />
              </div>
            ) : payment ? (
              <div
                className="round-background-icon"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="darkgray"
                  width="30"
                  height="27"
                />
              </div>
            ) : (
              <div
                className="round-background-icon"
                onClick={() => dispatch(closeAllModal())}
              >
                <Icon
                  icon="clarity:window-close-line"
                  color="#707070"
                  width="30"
                  height="27"
                />
              </div>
            )}
          </MDBCol>
          <MDBCol
            className={
              title ? "col-8 text-center p-0" : "col-6 text-center p-0"
            }
          >
            <label>{title}</label>
          </MDBCol>
          <MDBCol className="col-2">
            {label ? (
              <button
                type="submit"
                className="save-btt"
                disabled={isSubmitting}
              >
                {label}
              </button>
            ) : (
              ""
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBNavbar>
  );
}
