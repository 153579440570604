import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { MDBBtn, MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit";
import { ProfileAvatar } from "../../../assets/images";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  openModalReferral,
  openModalWallet,
  openModalQrCode,
} from "../../../redux/reducer/modalReducer";
import { useEffect } from "react";
import { setCopy } from "../../../redux/reducer/profileReducer";

export default function ProfileData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { balance, copy } = useSelector((state) => state.profile);
  const { email, referral_code, avatar, agent, referral_link } = useSelector(
    (state) => state.profile.data
  );
  const onCopy = () => {
    // setCopy(true);
    dispatch(setCopy(true));
    // toast("Copied", {
    //   position: "bottom-center",
    //   autoClose: 1200,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   theme: "colored",
    // });
  };

  useEffect(() => {
    let text = setInterval(() => {
      dispatch(setCopy(false));
    }, 1000);
    return () => {
      clearInterval(text);
    };
  }, [copy]);

  const onComming = () => {
    toast.info("Comming Soon", {
      position: "top-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  };

  return (
    <>
      <MDBContainer>
        <div className="avatar">
          {avatar ? <img src={avatar} /> : <img src={ProfileAvatar} />}
        </div>
        <MDBRow>
          {agent ? (
            <MDBCol className="user-member col-4">
              <label className="agent">Agent</label>
            </MDBCol>
          ) : (
            <MDBCol className="user-member col-4">
              <label className="user">User</label>
            </MDBCol>
          )}
          <MDBCol className={agent ? "user-name col-8" : "user-name col-8"}>
            <MDBRow>
              <label>{email}</label>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow className="data-referral">
          <MDBCol className=" btn-css ">
            <label
              className="btn-modal"
              onClick={() => dispatch(openModalWallet())}
            >
              Buy Credit
            </label>
          </MDBCol>
          <MDBCol className=" data col-5">
            {/* {parseFloat(
              balance.credit_balance ? balance.credit_balance : "0"
            ).toFixed(0)} */}
            {balance.credit_balance ? balance.credit_balance : "0"} Credit
          </MDBCol>
          <MDBCol className="col-3">
            <MDBBtn
              className="btn-qrcopy"
              onClick={() => dispatch(openModalQrCode())}
              // onClick={() => onComming()}
            >
              <Icon
                icon="bi:upc-scan"
                color="white"
                hFlip={true}
                width="20"
                height="20"
              />{" "}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow className="data-referral">
          <MDBCol className=" btn-css">
            <label
              className="btn-modal"
              onClick={() => dispatch(openModalReferral())}
            >
              Referral
            </label>
          </MDBCol>
          <MDBCol className="col-5 data">{referral_code}</MDBCol>
          <MDBCol className="col-3">
            <CopyToClipboard text={referral_link} onCopy={onCopy}>
              <MDBBtn className="btn-qrcopy">
                <Icon
                  icon="octicon:copy-16"
                  color="white"
                  hFlip={true}
                  width="20"
                  height="20"
                />{" "}
              </MDBBtn>
            </CopyToClipboard>
          </MDBCol>
        </MDBRow>
        <MDBContainer className="content-container refer-detail p-2">
          <MDBRow className="p-1 d-flex">
            <MDBCol className="col-1">
              <Icon
                icon="ant-design:field-time-outlined"
                width="20"
                height="18"
              />
            </MDBCol>
            <MDBCol>
              <label className="d-flex align-items-center">
                Refer friend to stay in Gold Coast Morib to earn up to 50%
                commission.
              </label>
            </MDBCol>
          </MDBRow>
          <MDBRow className="p-1 d-flex">
            <MDBCol className="col-1">
              <Icon
                icon="ant-design:field-time-outlined"
                width="20"
                height="18"
              />
            </MDBCol>
            <MDBCol>
              <label className="d-flex align-items-center">
                Click here to know to become our travel agent
              </label>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </>
  );
}
