import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBBadge,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { handleModalContact } from "../../../redux/reducer/modalReducer";
import { useEffect } from "react";
import { useFormikContext } from "formik";
import { profile } from "../../../redux/reducer/profileReducer";
export default function Contact({ data }) {
  const dispatch = useDispatch();
  // const { first_name, last_name, email, phone_number } = useSelector(
  //   (state) => state.profile.data
  // );
  const { values, errors, setFieldValue } = useFormikContext();
  // const { name,phone_number,email } = useSelector((state) => state.payment.payment)

  // useEffect(() => {
  //     if(name && phone_number && email){
  //         setFieldValue()
  //     }
  // },[name])
  useEffect(() => {
    dispatch(profile());
  }, []);

  return (
    <MDBContainer className="contact-container">
      <MDBRow className="container-title m-0">
        <label>
          <div></div>Contact info
        </label>
      </MDBRow>
      <MDBContainer className="content-container">
        <MDBRow className="package-option-label m-0">
          <label>we'll only contact you about update to your booking</label>
        </MDBRow>
        {values.name ? (
          <div className="box">
            <MDBRow className="edit-button">
              <span>
                {/* {values.name} */}
                {values.name ? values.name : ""}
                {values.last_name ? values.last_name : ""}
              </span>
              <MDBBtn
                type="button"
                onClick={() => dispatch(handleModalContact())}
              >
                Edit
              </MDBBtn>
            </MDBRow>
            <MDBRow className="user-contact-detail">
              <MDBCol className="col-5">
                <label>First Name</label>
              </MDBCol>
              <MDBCol>
                <span>{values.name ? values.name : ""} </span>
              </MDBCol>
            </MDBRow>
            <MDBRow className="user-contact-detail">
              <MDBCol className="col-5">
                <label>Last Name</label>
              </MDBCol>
              <MDBCol>
                <span>{values.last_name ? values.last_name : ""}</span>
              </MDBCol>
            </MDBRow>
            <MDBRow className="user-contact-detail">
              <MDBCol className="col-5">
                <label>Phone Number</label>
              </MDBCol>
              <MDBCol>
                <span>{values.phone_number ? values.phone_number : ""}</span>
              </MDBCol>
            </MDBRow>
            <MDBRow className="user-contact-detail">
              <MDBCol className="col-5">
                <label>Email (for receive order number)</label>
              </MDBCol>
              <MDBCol>
                <span>{values.email ? values.email : ""}</span>
              </MDBCol>
            </MDBRow>
          </div>
        ) : (
          <div className="box">
            <MDBRow className="contact-button">
              <MDBBtn
                type="button"
                onClick={() => dispatch(handleModalContact())}
              >
                <Icon
                  icon="carbon:add-alt"
                  color="#707070"
                  width="18"
                  height="18"
                />
                Add
              </MDBBtn>
              <label
                className={
                  errors["contact_form"]
                    ? "contact-label errors"
                    : "contact-label"
                }
              >
                <Icon
                  icon="ant-design:info-circle-outlined"
                  color="#ea3943"
                  width="18"
                  height="18"
                  rotate={2}
                />
                Please add contact details
              </label>
            </MDBRow>
          </div>
        )}
      </MDBContainer>
    </MDBContainer>
  );
}
