import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useState } from "react";

//redux
import { useSelector } from "react-redux";
export default function FacilitiesService() {
  const { facility, general, safety, cleaning } = useSelector(
    (state) => state.booking.roomInfo
  );

  return (
    <MDBContainer className="property-container p-0">
      <MDBRow className="property-detail-container-title">
        <label>
          <div></div>Facilities & service
        </label>
      </MDBRow>
      <MDBContainer className="popular-facilitices">
        <label>Most Popular Facilities</label>
        <MDBRow className="icon">
          {facility
            ? facility.map((title, index) => {
                return (
                  <>
                    <MDBCol className="col-3" key={index}>
                      <Icon icon={title.icon} width="22" height="22" />
                      <MDBRow>
                        <span className="icon-label">{title.title}</span>
                      </MDBRow>
                    </MDBCol>
                  </>
                );
              })
            : ""}
        </MDBRow>
      </MDBContainer>
      <MDBRow className="service">
        <label>General</label>

        {general
          ? general.map((title, index) => {
              return (
                <>
                  <MDBRow key={index}>
                    <MDBCol className="col-12">
                      <Icon icon="charm:circle-tick" color="#1ca151" />

                      <span>&nbsp;{title.title}</span>
                    </MDBCol>
                  </MDBRow>
                </>
              );
            })
          : ""}
      </MDBRow>
      <MDBRow className="service">
        <label>Safety & security</label>
        {safety
          ? safety.map((title, index) => {
              return (
                <MDBRow key={index}>
                  <MDBCol className="col-12">
                    <Icon icon="charm:circle-tick" color="#1ca151" />

                    <span>&nbsp;{title.title}</span>
                  </MDBCol>
                </MDBRow>
              );
            })
          : ""}
      </MDBRow>
      <MDBRow className="service">
        <label>Cleaning service</label>
        {cleaning
          ? cleaning.map((title, index) => {
              return (
                <>
                  <MDBRow key={index}>
                    <MDBCol className="col-12">
                      <Icon icon="charm:circle-tick" color="#1ca151" />

                      <span>&nbsp;{title.title}</span>
                    </MDBCol>
                  </MDBRow>
                </>
              );
            })
          : ""}
      </MDBRow>
    </MDBContainer>
  );
}
