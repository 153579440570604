import { Formik,Form, Field } from "formik";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";

export default function WalletDetail(){
    const dispatch = useDispatch()
    const { name,bank_name,bank_acc } = useSelector((state) => state.agent.wallet)
    const [data,setData] = useState({
        name: name ? name :"",
        bank_name: bank_name ? bank_name : "",
        bank_acc: bank_acc ? bank_acc : "",
        withdraw: "",
    })


    const handleSubmit = () => {

    }

    return(
    <MDBContainer className="wallet-detail-container">
        <Formik
            initialValues={data}
            // validationSchema={schema}
            enableReinitialize={true}
            validateOnChange={true}
            onSubmit={(values)=>{
                handleSubmit(values)
            }}
        >
            {({ setFieldValue, isSubmitting, handleReset, isValid,values,errors }) => (
            <Form> 
                <div className="bank-info">
                    {data.name ? 
                    <div className="border-box">
                        <MDBRow className="name-label">
                            <label>{name}</label>
                        </MDBRow>
                        <div className="single-line"></div>
                        <MDBRow>
                            <MDBCol className="col-6 bank-label text-start">
                                <label>{bank_name}</label>
                            </MDBCol>
                            <MDBCol className="col-6 bank-label text-end">
                                <label>{bank_acc}</label>
                            </MDBCol>
                        </MDBRow>
                    </div>:
                    <div className="border-box">
                        <MDBRow className="name-label" onClick={() => dispatch()}>
                            <label>Add Bank Detail to Withdraw.</label>
                        </MDBRow>
                    </div>}
                    <MDBRow className="description-label">
                        <label><span>Must </span>enter your bank account same with your full name. Once submitted it cannot be change.</label>
                    </MDBRow>
                    {data.name && <MDBRow className="input-container m-0">
                        <MDBCol className="input-field col-7">
                            <MDBRow>
                                <Field name="withdraw" type="text" placeholder="RM 0.00"/>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol className="button col-5">
                            <Button label="Withdraw"/>
                        </MDBCol>
                    </MDBRow>}
                </div>
            </Form>)}
        </Formik>
    </MDBContainer>
    )
}