import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBRadio,
  MDBBtnGroup,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { QRCodeSVG } from "qrcode.react";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { Formik, Field, Form } from "formik";
import moment from "moment/moment";
import * as Yup from "yup";
import CopyToClipboard from "react-copy-to-clipboard";
import apiServices from "../../../services/api.services";
import { useNavigate } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalPlanningPayment } from "../../../redux/reducer/modalReducer";
import { booking } from "../../../redux/reducer/planingReducer";

export default function PlaningQR({ planningId }) {
  const {
    ticket_status,
    used_at,
    pay_before_at,
    qr,
    share_qr,
    reference_no,
    advance_booking_days,
    availability_end_date,
    availability_start_date,
  } = useSelector((state) => state.planning.planningInfo);
  const { listing } = useSelector((state) => state.planning);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copy, setCopy] = useState(false);
  const [invalidDates, setInvalidDates] = useState([new Date()]);
  const [calanderDate, setCalanderDate] = useState(false);

  const [bookingData, setBookingData] = useState({
    selected_at: "",
    id: planningId,
  });

  const useSchema = Yup.object({
    selected_at: Yup.string().required("Required"),
  });

  const shareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "${title}",
          url: `${share_qr}`,
        })
        .then(() => {})
        .catch();
    } else {
    }
  };

  const onChangenDate = (e, setFieldValue) => {
    setFieldValue("selected_at", moment(e.value).format("YYYY-MM-DD"));
  };

  const openCalender = () => {
    setCalanderDate(true);
  };

  const handleSubmit = async (values, setFieldError) => {
    dispatch(booking({ payload: values }))
      .unwrap()
      .then((res) => {
        navigate(-1);
        toast.success("Booking Successful", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      })
      .catch((ex) => {
        const errors = ex.response.data.errors;

        let errMsg = "";
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item) => {
            // setFieldError(item, errors[item]);
            errMsg = errors[item];
          });
        }
        toast.error(errMsg[0], {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      });
  };

  return (
    <Formik
      initialValues={bookingData}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError }) => {
        handleSubmit(values, { setFieldError });
      }}
    >
      {({ setFieldValue }) => (
        <MDBContainer className="qr-container-planning">
          {qr !== null && ticket_status !== "used" ? (
            <MDBRow className="qr-code-svg">
              <QRCodeSVG value={qr} width="250" height="250" />
            </MDBRow>
          ) : ticket_status === "booking" && calanderDate === false ? (
            <MDBBtn className="booking-btn" onClick={() => openCalender()}>
              Booking {advance_booking_days} days before use
            </MDBBtn>
          ) : calanderDate === true ? (
            <Form>
              <Calendar
                minDate={new Date(availability_start_date)}
                maxDate={new Date(availability_end_date)}
                id="disableddays"
                // value={date}
                dateFormat="dd/mm/yy"
                onChange={(e) => onChangenDate(e, setFieldValue)}
                inline
              />
              <MDBRow className="aplly-row">
                <MDBBtn className="aplly" type="submit">
                  <label>Apply</label>
                </MDBBtn>
              </MDBRow>
            </Form>
          ) : (
            ""
          )}
          {qr !== null ? (
            <MDBRow className="booking-no">Booking ID : {qr}</MDBRow>
          ) : (
            ""
          )}

          <MDBRow className="planning-status">
            {ticket_status === "used" ? (
              <label className="used-paid">
                {ticket_status} <Icon icon="dashicons:yes-alt" />
              </label>
            ) : ticket_status === "booking" && qr !== null ? (
              <label className="used-paid">
                {ticket_status} <Icon icon="dashicons:yes-alt" />
              </label>
            ) : ticket_status === "booking" && qr === null ? (
              <label className="used-paid" style={{ background: "#D6D6D6" }}>
                Waiting Booking <Icon icon="bx:time-five" />
              </label>
            ) : ticket_status === "use now" ? (
              <label className="used-paid">
                {ticket_status} <Icon icon="dashicons:yes-alt" />
              </label>
            ) : ticket_status === "expired" ? (
              <label className="used-paid" style={{ background: "#D6D6D6" }}>
                {ticket_status} <Icon icon="bx:time-five" />
              </label>
            ) : ticket_status === "waiting for payment" ? (
              <label className="used-paid" style={{ background: "#D6D6D6" }}>
                {ticket_status} <Icon icon="bx:time-five" />
              </label>
            ) : (
              ""
            )}
            {ticket_status !== "waiting for payment" ? (
              <label className="used-paid">
                Paid <Icon icon="dashicons:yes-alt" />
              </label>
            ) : (
              ""
            )}

            {qr !== null ? (
              <label className="used-paid">
                {/* <CopyToClipboard text={share_qr}> */}
                <Icon
                  icon="carbon:share"
                  color="white"
                  onClick={() => shareLink()}
                />
                {/* </CopyToClipboard> */}
              </label>
            ) : (
              <label className="used-paid" style={{ background: "#D6D6D6" }}>
                <Icon icon="carbon:share" color="white" />
              </label>
            )}
          </MDBRow>
        </MDBContainer>
      )}
    </Formik>
  );
}
