import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../components/common/window-dimension";

//plugin & framework
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, Autoplay } from "swiper";
import { MDBContainer, MDBRow, MDBCol, MDBCardImage } from "mdb-react-ui-kit";
import { Skeleton } from "primereact/skeleton";
//redux
import { useSelector, useDispatch } from "react-redux";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

export default function PromotionCarousel() {
  const { promotion, loading } = useSelector((state) => state.home);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const [responsive, setResponsive] = useState("mobile");

  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);
  return (
    <>
      {responsive === "mobile" ? (
        <MDBContainer className="promotion-carousel-container">
          <MDBRow className="header-container">
            <MDBCol className="col-9">
              <label>Offer to inspire you</label>
            </MDBCol>
          </MDBRow>
          <Swiper
            slidesPerView={responsive === "desktop" ? 2 : 1}
            loop={true}
            grabCursor={true}
            effect={"creative"}
            modules={[Autoplay]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {loading ? (
              <SwiperSlide>
                <Skeleton width="100%" height="6.5rem" borderRadius="0.5em" />
              </SwiperSlide>
            ) : (
              <>
                {promotion.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <MDBContainer className="promotion-container">
                        <MDBCardImage
                          className="banner-image"
                          src={item.image}
                          width="100%"
                          height="100em"
                          onClick={() => {
                            navigate(`/ticket/${item.id}`);
                          }}
                        />
                      </MDBContainer>
                    </SwiperSlide>
                  );
                })}
              </>
            )}
          </Swiper>
        </MDBContainer>
      ) : (
        ""
      )}
    </>
  );
}
