import { Form, Formik } from "formik";
import { MDBContainer } from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Button from "../../../components/element/button";
import { toast } from "react-toastify";

import InputField from "../../../components/element/inputField";
import { addRoomNumber } from "../../../redux/reducer/serviceReducer";
import apiServices from "../../../services/api.services";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

export default function ServiceForm({ setSubmit }) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [data, setData] = useState({
    room_no: "",
    // contact: "",
  });

  const schema = Yup.object({
    room_no: Yup.string()
      .matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Must be number format"
      )
      .required("Enter room number"),
    // contact: Yup.string()
    //   .required("required")
    //   .min(9, "Please enter more than or equal to 10 character")
    //   .max(12, "Must be exactly 13 digits")
    //   .matches(
    //     /^([6,0]{1}|\+?[0,1]{1})([0-9]{8,11})$/g,
    //     "Must be phone format"
    //   ),
  });

  const handleSubmit = async (values, setFieldError, setSubmitting) => {
    // const payload = {
    //   room_no: values.room,
    // };
    try {
      const response = await apiServices.checkRoomNumber({
        room_no: values.room_no,
      });
      if (response) {
        setSubmit(true);
        localStorage.setItem("room_no", values.room_no);
        localStorage.setItem("contact_no", values.contact);
        dispatch(addRoomNumber(values));
        toast.info("Welcome to Gold Coast Morib Resort", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errMsg = "";
      setSubmitting(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errMsg = errors[item];
            });
          }
        }
        // toast.error(errMsg[0], {
        //   position: "top-center",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   theme: "colored",
        // });
      }
      if (ex.response.status === 401) {
        dispatch(openModalLogin());
      }
      // toast.error(errMsg[0], {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   theme: "colored",
      // });
    }
  };
  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      // validateOnBlur={false}
      initialValues={data}
      validationSchema={schema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        if (token) {
          handleSubmit(values, setFieldError, setSubmitting);
          resetForm({ values: "" });
        } else {
          setSubmitting(false);
          dispatch(openModalLogin());
        }
      }}
    >
      {({ setFieldValue, errors, isSubmitting, isValid, status, values }) => (
        <Form>
          <MDBContainer className="service-formik-form">
            <h3>
              Enter current staying
              <br />
              Room Number
            </h3>
            <InputField
              className="service-field"
              name="room_no"
              type="text"
              placeholder="Room No"
            />
            {/* <InputField
              className="service-field"
              name="contact"
              type="text"
              placeholder="Guest Contact"
            /> */}
            <Button
              label="Submit"
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </MDBContainer>
        </Form>
      )}
    </Formik>
  );
}
