import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import Description from "./description";
import History from "./history";
import { useState } from "react";

export default function Detail() {
  const [hideContent, setHideContent] = useState(false);
  const { detail } = useSelector((state) => state.detail.ticketInfo);
  const { loading } = useSelector((state) => state.detail);
  return (
    <MDBCol className="col-12">
      {loading ? (
        ""
      ) : (
        <>
          <MDBCol className="detail-container">
            <MDBRow className="container-title">
              <label>
                <div></div>Detail
              </label>
            </MDBRow>
            <div className={detail ? "content-container" : ""}>
              {detail
                ? detail.map((item, index) => {
                    return (
                      <MDBRow
                        key={index}
                        className="detail-row justify-content-center align-items-center m-0"
                      >
                        {/* <MDBCol className="detail-text"> */}
                        <label className="detail-title">
                          {item.title ? item.title : ""}
                        </label>
                        <MDBRow>
                          <MDBCol className="d-flex col-11 p-0">
                            <span
                              onClick={() => {
                                item.text.length > 34 &&
                                  setHideContent(!hideContent);
                              }}
                              className={hideContent ? "hide-content" : ""}
                            >
                              <Icon
                                icon={item.icon}
                                color="#888daa"
                                width="20"
                                height="18"
                              />
                              {item.text ? item.text : ""}
                            </span>
                          </MDBCol>
                          <MDBCol
                            className="col-1 p-0"
                            onClick={() => setHideContent(!hideContent)}
                          >
                            {item.text.length > 34 ? (
                              <Icon
                                icon="ic:sharp-navigate-next"
                                color="#888daa"
                                width="30"
                                height="27"
                                rotate={hideContent ? 1 : 0}
                              />
                            ) : (
                              ""
                            )}
                          </MDBCol>
                        </MDBRow>
                        {/* </MDBCol> */}
                        <MDBCol className="col-2"></MDBCol>
                      </MDBRow>
                    );
                  })
                : ""}
            </div>
          </MDBCol>
          <Description />
        </>
      )}
      {/* {history && <History /> } */}
    </MDBCol>
  );
}
