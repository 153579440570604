import {
  MDBCheckbox,
  MDBBtnGroup,
  MDBRow,
  MDBCol,
  MDBRadio,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  booking,
  sortingLisitng,
} from "../../../redux/reducer/booking.reducer";

export default function RoomCheck() {
  const { listing } = useSelector((state) => state.booking);
  const dispatch = useDispatch();
  const [bar, setbar] = useState("most_popular");

  const searhRoom = (e) => {
    const data = JSON.parse(JSON.stringify(listing));

    if (e === "most_popular") {
      setbar(e);
      // data.sort((a, b) =>
      //   a.current_price > b.current_price
      //     ? 1
      //     : b.current_price > a.current_price
      //     ? -1
      //     : 0
      // );
      // dispatch(sortingLisitng(data));
    } else if (e === "low_to_high") {
      setbar(e);
      data.sort((a, b) =>
        a.current_price > b.current_price
          ? 1
          : b.current_price > a.current_price
          ? -1
          : 0
      );
      dispatch(sortingLisitng(data));
    } else if (e === "high_to_row") {
      setbar(e);

      data.sort((a, b) =>
        a.current_price < b.current_price
          ? 1
          : b.current_price < a.current_price
          ? -1
          : 0
      );
      dispatch(sortingLisitng(data));
    }
  };
  return (
    <MDBRow className="room-checkbox">
      <MDBCol className="p-1">
        <MDBRadio
          name="options"
          btn
          id="btn-check1"
          label="Favourite"
          value="most_popular"
          checked={bar === "most_popular"}
          onChange={(e) => searhRoom(e.target.value)}
        />
      </MDBCol>
      <MDBCol className="p-1">
        <MDBRadio
          name="options"
          btn
          id="btn-check2"
          label="Low to High"
          value="low_to_high"
          checked={bar === "low_to_high"}
          onChange={(e) => searhRoom(e.target.value)}
        />
      </MDBCol>
      <MDBCol className="p-1">
        <MDBRadio
          name="options"
          btn
          id="btn-check3"
          label="High to Low"
          value="high_to_row"
          checked={bar === "high_to_row"}
          onChange={(e) => searhRoom(e.target.value)}
        />
      </MDBCol>
    </MDBRow>
  );
}

// let student_name = ['Karthik', 'Saideep', 'Balu', 'Shweta', 'Diya'];
// student_name.sort();
