import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const activity = createAsyncThunk("get/activity", async () => {
  const response = await apiServices.getActivity();
  return response;
});

const initialState = {
  history: [],
  ticket: [],
  loading: false,
};

const activitySlice = createSlice({
  name: "home",
  initialState,
  extraReducers: {
    [activity.pending]: (state, action) => {
      state.loading = true;
    },
    [activity.fulfilled]: (state, action) => {
      state.loading = false;
      state.history = action.payload.history;
      state.ticket = action.payload.ticket;
    },
  },
});

const { reducer } = activitySlice;
export default reducer;
