import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//redux
import { useSelector } from "react-redux";

export default function RoomExpect() {
  const { expectation } = useSelector((state) => state.booking.roomInfo);
  return (
    <MDBContainer className="expect-container" style={{ paddingBottom: "5em" }}>
      <MDBRow className="room-detail-container-title">
        <label className="p-0">
          <div></div>What to Expect
        </label>
      </MDBRow>
      <MDBRow className="room-detail-container-text">
        {/* <p>{expectations.text}</p> */}
      </MDBRow>
      <MDBContainer className="content-container">
        {expectation
          ? expectation.map((items, index) => {
              return (
                <>
                  <MDBRow key={index}>
                    <img src={items.image} />
                  </MDBRow>
                  <MDBRow className="description">
                    <label>
                      <Icon
                        icon="fa6-solid:square-caret-up"
                        color="#888daa"
                        width="16"
                        height="16"
                      />
                      {items.text}
                    </label>
                  </MDBRow>
                </>
              );
            })
          : ""}
      </MDBContainer>
    </MDBContainer>
  );
}
