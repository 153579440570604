import { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { closeModalLogin } from "../../../redux/reducer/modalReducer";
import { MDBContainer, MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
//redux
import { useDispatch, useSelector } from "react-redux";
import { runLoader, stopLoader } from "../../../redux/reducer/loaderReducer";
//component
import InputField from "../../../components/element/inputField";
import LoginButton from "../../../components/element/button";
import { sweetAlertHelper } from "../../../components/helper/message.modal";
import { toast } from "react-toastify";
//auth
import authServices from "../../../services/auth.services";

export default function LoginForm({ forgotPasswordModal, changeLoginModal }) {
  const dispatch = useDispatch();
  const { isRunLoader } = useSelector((state) => state.loader);
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });

  const loginSchema = Yup.object({
    username: Yup.string()
      .email("Invalid Emil Address Format")
      .required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    dispatch(runLoader());
    try {
      const response = await authServices.login(values);
      if (response) {
        dispatch(stopLoader());
        dispatch(closeModalLogin());

        toast.success("Login Success", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (ex) {
      dispatch(stopLoader());
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <Formik
      initialValues={userData}
      validationSchema={loginSchema}
      onSubmit={(values, { resetForm, setFieldError }) => {
        handleSubmit({ values, setFieldError });
        resetForm({ values: "" });
      }}
    >
      {({ errors, isValid, resetForm, isSubmitting, setFieldError }) => (
        <Form>
          <MDBContainer className="login-text">
            <MDBRow>
              <MDBCol className="login-text-text">Welcome back</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="login-text-text2">
                Get an account to enjoy more & benifit on your selangor trip.
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <label className="login-label">Email</label>
          <InputField
            className="login-field"
            name="username"
            type="username"
            errors={errors}
            placeholder="Enter email addres"
          />

          <InputField
            className="login-field"
            name="password"
            type="password"
            errors={errors}
            placeholder="Enter Password "
          />
          <MDBRow className="message-btt">
            <MDBCol>
              <label
                className="form-label"
                onClick={() => forgotPasswordModal()}
              >
                Forgot Password
              </label>
              <Icon icon="bi:caret-up-fill" color="#41b1cd" rotate={1} />
            </MDBCol>
          </MDBRow>

          <LoginButton
            label={"Log In"}
            isValid={isValid}
            isRunLoader={isRunLoader}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
}
