import { current, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const serviceCategory = createAsyncThunk("get/paymentInfo", async () => {
  const response = await apiServices.serviceCategory();
  return response;
});

const initialState = {
  categorySelected: [],
  room_detail: {
    room_no: "",
    contact_number: "",
  },
  serviceCategory: [],
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    addRoomNumber: (state, action) => {
      return {
        ...state,
        room_detail: {
          room_no: action.payload.room,
          contact_number: action.payload.contact,
        },
      };
    },
    addSelectedService: (state, action) => {
      return {
        ...state,
        categorySelected: false,
      };
    },
  },
  extraReducers: {
    [serviceCategory.pending]: (state, action) => {
      return {
        ...state,
      };
    },
    [serviceCategory.fulfilled]: (state, action) => {
      return {
        ...state,
        serviceCategory: action.payload,
      };
    },
  },
});

export const { addSelectedService, addRoomNumber } = serviceSlice.actions;
export default serviceSlice.reducer;
