import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { useState, useEffect } from "react";
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useSelector, useDispatch } from "react-redux";

export default function EventCard() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { event, loading } = useSelector((state) => state.home);
  const { height, width } = useWindowDimensions();
  const [responsive, setResponsive] = useState("mobile");
  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);
  return (
    <MDBContainer className="event-card-container" breakpoint="md">
      <MDBRow className="header-container">
        <MDBCol className="col-10">
          <label className="">Event in Gold Coast</label>
        </MDBCol>
        {responsive === "mobile" ? (
          ""
        ) : (
          <MDBCol
            className={responsive === "mobile" ? "btn-row" : "btn-row  desktop"}
          >
            <MDBBtn
              className={
                responsive === "mobile"
                  ? "btn-tranparent"
                  : "btn-tranparent desktop"
              }
              onClick={() => navigate("/event")}
            >
              See more
            </MDBBtn>
          </MDBCol>
        )}
      </MDBRow>
      <MDBRow>
        {loading ? (
          <MDBContainer>
            <MDBRow>
              {[1, 2, 3].map((item, index) => {
                return (
                  <MDBCol
                    key={index}
                    className="col-12 col-md-4 skeleton-container"
                  >
                    <Skeleton
                      width="100%"
                      height="6.5rem"
                      borderRadius="0.5em"
                    />
                  </MDBCol>
                );
              })}
            </MDBRow>
          </MDBContainer>
        ) : (
          <>
            {event.map((item, index) => {
              return (
                <>
                  {index <= 2 ? (
                    <MDBCol key={index} className="col-12 col-md-4 card-col">
                      <MDBCard
                        className="w-ws"
                        onClick={() => {
                          navigate(`/ticket/${item.id}`);
                        }}
                        style={{
                          display: "block",
                          backgroundImage: `url("${item.image}")`,
                          width: "98%",
                          alignItems: "center",
                          height: "11.3em",
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          boxShadow: "rgb(44 44 44 / 60%) 80px 0px 90px inset",
                        }}
                      >
                        <MDBRipple
                          rippleColor="light"
                          rippleTag="div"
                          className="bg-image hover-overlay"
                        >
                          <MDBCardBody>
                            <MDBCardTitle>{item.title}</MDBCardTitle>
                            <MDBCardText>
                              <span>{item.content}</span>
                            </MDBCardText>
                            <MDBBtn
                              className="btn-activity"
                              onClick={() => {
                                navigate(`/ticket/${item.id}`);
                              }}
                            >
                              Details
                            </MDBBtn>
                          </MDBCardBody>
                        </MDBRipple>
                      </MDBCard>
                    </MDBCol>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </>
        )}
      </MDBRow>
      {responsive === "mobile" ? (
        <MDBRow className="btn-row">
          <MDBBtn className="btn-tranparent" onClick={() => navigate("/event")}>
            See more
          </MDBBtn>
        </MDBRow>
      ) : (
        ""
      )}
    </MDBContainer>
  );
}
