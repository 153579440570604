import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { closeModalWallet } from "../../../redux/reducer/modalReducer";

export default function BannerWallet({ activeTab, handleChangeTab }) {
  const dispatch = useDispatch();
  const { credit_balance } = useSelector((state) => state.profile.data);
  const { balance } = useSelector((state) => state.profile);

  return (
    <div className="banner-agent-container">
      <MDBContainer>
        <MDBRow className="header-row">
          <MDBCol className="col-2 d-flex justify-content-center p-0">
            <div
              className="round-background-icon"
              onClick={() => dispatch(closeModalWallet())}
            >
              <Icon
                icon="entypo:cross"
                color="#707070"
                width="30"
                height="27"
              />
            </div>
          </MDBCol>
          <MDBCol className="col-8 text-center">
            <label>Wallet</label>
          </MDBCol>
          <MDBCol className="col-2">
            <label>Help</label>
          </MDBCol>
        </MDBRow>
        <MDBContainer className="available-container">
          <MDBRow className="header-label">
            <label>My Balance</label>
          </MDBRow>
          <MDBRow className="comission-label">
            <label>
              <span>RM</span>
              {balance.credit_balance ? balance.credit_balance : "0"}
            </label>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </div>
  );
}
