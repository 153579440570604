import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/full.modal.box";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";

import Header from "../../../components/header/header-five";

//redux
import { useSelector } from "react-redux";

export default function ModalHistory() {
  const { history } = useSelector((state) => state.service_history);
  const navigate = useNavigate();

  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="service-history-container p-0 m-0">
            <Header title={" History"} />
            <MDBContainer className="service-modal-container">
              {history
                ? history.map((history, index) => {
                    return (
                      <>
                        <MDBRow className="service-history" key={index}>
                          <MDBCol className="col p-0">
                            <span className="room-service-label">
                              {history.title} x{history.quantity}
                            </span>
                            <MDBRow>
                              <span className="w-100">
                                Room No. {history.room_number}
                              </span>
                              <span>Order&nbsp;{history.created_at}</span>
                            </MDBRow>
                          </MDBCol>
                          <MDBCol className="col-4 text-end p-0">
                            <p>RM&nbsp;{history.amount}</p>
                            <MDBRow className="m-0 status">
                              <span
                                className={
                                  history.status === "Completed"
                                    ? "span-c"
                                    : "span"
                                }
                              >
                                {history.status}
                              </span>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>

                        {/* <div className="line"></div> */}
                      </>
                    );
                  })
                : ""}
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
