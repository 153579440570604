import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";
import { EventOne, EventTwo, EventThree } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

export default function ComingEvent() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listing } = useSelector((state) => state.event);
  let slidesPerView = 1;

  return (
    <MDBContainer className="coming-event-container">
      <label className="event-title">Up coming event</label>
      <Swiper
        grabCursor={true}
        slidesPerView={1}
        spaceBetween={10}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
      >
        {listing &&
          listing.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  className="p-0 w-100"
                  style={{
                    height: "24em",
                    display: "flex",
                    alignItems: "end",
                    borderRadius: "1rem",
                    backgroundImage: `url("${item.image}")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    boxShadow: "rgb(44 44 44 / 60%) 80px 0px 90px inset",
                  }}
                  onClick={() => {
                    navigate(`/ticket/${item.id}`);
                  }}
                >
                  <MDBContainer className="m-3">
                    <div className="d-flex justify-content-start">
                      <MDBRow className="title">
                        <span>{item.title}</span>
                      </MDBRow>
                    </div>
                    <MDBRow className="detail">
                      <label className="p-0">{item.description}</label>
                    </MDBRow>
                    <MDBRow className="coming-event-join-now">
                      <MDBBtn
                        onClick={() => {
                          navigate(`/ticket/${item.id}`);
                        }}
                      >
                        Join Now
                      </MDBBtn>
                    </MDBRow>
                  </MDBContainer>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <MDBRow className="btn-organize-event">
        <MDBBtn>i want to organize my own event.</MDBBtn>
      </MDBRow>
    </MDBContainer>
  );
}
