import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModalBody,
  MDBProgress,
  MDBProgressBar,
  MDBRow,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { QRCodeSVG } from "qrcode.react";
import { RemoveScroll } from "react-remove-scroll";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import { paymentQr } from "../../../redux/reducer/profileReducer";

//component
import ModalBox from "../../../components/modal/full.modal.box";
import Header from "../../../components/header/header-five";
import { useState, useEffect } from "react";

export default function ModalQR({ show, roomId, setShow, qr }) {
  const dispatch = useDispatch();
  const [time, setTime] = useState(0);
  const resetQR = () => {
    setTime(60);
  };
  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
  }, [time]);

  useEffect(() => {
    dispatch(paymentQr());
  }, []);

  return (
    <RemoveScroll>
      <ModalBox
        className="qr-container"
        show={true}
        setShow={setShow}
        backDrop={false}
        content={
          <MDBModalBody className="qr-container">
            <MDBRow>
              <label>GCM Credit To Pay</label>
            </MDBRow>
            <MDBRow>
              <QRCodeSVG value={qr} width="250" height="250" />
            </MDBRow>
            <MDBBtn
              className="btn-primary"
              onClick={() => dispatch(closeAllModal())}
            >
              Close
            </MDBBtn>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
