import React from "react";
import { MDBContainer, MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function VerficationCodeButton({
  label,
  sendCode,
  values,
  sendForgotCode,
}) {
  return (
    <MDBRow className="verfication-button">
      {sendCode ? (
        <MDBBtn className="code-button" onClick={() => sendCode()}>
          {label}
        </MDBBtn>
      ) : sendForgotCode ? (
        <MDBBtn className="code-button" onClick={() => sendForgotCode(values)}>
          {label}
        </MDBBtn>
      ) : (
        <MDBBtn className="code-button">{label}</MDBBtn>
      )}
    </MDBRow>
  );
}
