import { MDBBtn, MDBRow } from "mdb-react-ui-kit";
import { useDispatch } from "react-redux";
import { handleModalBoxCheckOut } from "../../../redux/reducer/checkoutReducer";
import { openModalBookingDate } from "../../../redux/reducer/modalReducer";

export default function BookingBtn() {
  const dispatch = useDispatch();
  return (
    <MDBRow className="booking-container">
      <MDBBtn
        className="booking-btn"
        onClick={() => {
          dispatch(
            handleModalBoxCheckOut({ title: "Choose Date", type: "booking" })
          );
        }}
      >
        Book Now
      </MDBBtn>
    </MDBRow>
  );
}
