import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBBtn,
  MDBRadio,
  MDBBtnGroup,
  MDBCheckbox,
  MDBInput,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { addAdditionalService } from "../../../redux/reducer/detailReducer";
import { Field, FieldArray, useFormikContext } from "formik";
import {
  addSelectedPackage,
  handleSelectedPackage,
} from "../../../redux/reducer/checkoutReducer";
import { useState } from "react";

export default function PackageTypeAddtional({ packages, indexPackages }) {
  const dispatch = useDispatch();
  const [radioValue, setRadioValue] = useState("");
  const { values, setFieldValue } = useFormikContext();
  const { additional_service } = useSelector(
    (state) => state.detail.ticketInfo
  );
  const selectedPackageIDArray = values.standard.map(
    (item) => item.selectedPackage.ticket_package_type_id
  );

  return (
    <div className="package-container">
      <MDBRow className="container-subtitle">
        <MDBCol className="col-9">
          <label>
            <div></div>
            {packages.package_name}
          </label>
        </MDBCol>
      </MDBRow>
      <MDBRow className="badge-row m-0">
        {packages.tag
          ? packages.tag.map((tag, index) => (
              <MDBCol
                key={index}
                className="badge col-3 p-0"
                style={{ background: tag.text ? `#${tag.color}` : "" }}
              >
                <label>{tag.text}</label>
              </MDBCol>
            ))
          : ""}
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <div className="package-card type-3">
            <MDBRow className="package-card-title">
              <label>Package Type</label>
            </MDBRow>
            <MDBRow className="option-card">
              {packages.package_type
                ? packages.package_type.map((ticket, index) => {
                    return (
                      <div key={index}>
                        <MDBRadio
                          btn
                          name={`package-${indexPackages}`}
                          id={`package-type-${ticket.ticket_package_type_id}`}
                          value={ticket.title}
                          label={ticket.title}
                          onChange={(e) => {
                            setRadioValue(undefined);
                            setFieldValue(`standard[${indexPackages}]`, {
                              package: packages,
                              selectedPackage: ticket,
                            });
                            const selectedPackage = {
                              [packages.ticket_package_id]: {
                                package: {
                                  ...packages,
                                  package_type: [ticket],
                                },
                              },
                            };
                            // dispatch(addSelectedPackage(selectedPackage));
                          }}
                        />
                      </div>
                    );
                  })
                : ""}
            </MDBRow>
            {additional_service
              ? additional_service.map((addon, index) => {
                  return (
                    <div key={index}>
                      {packages.ticket_package_id === addon.ticket_package_id &&
                        addon.additional_service.map((addon_service, index) => {
                          return (
                            <div key={index}>
                              {addon.additional_service.length > 0 ? (
                                <MDBRow className="package-card-title">
                                  <label>{addon_service.title}</label>
                                </MDBRow>
                              ) : (
                                ""
                              )}
                              <MDBRow className="option-card">
                                {addon_service.addon_item
                                  ? addon_service.addon_item.map((item) => {
                                      return (
                                        <MDBRadio
                                          btn
                                          key={`addon-service-${item.ticket_package_type_addon_item_id}`}
                                          name={`additional-${index}-${indexPackages}`}
                                          id={`addon-service-${item.ticket_package_type_addon_item_id}`}
                                          checked={
                                            radioValue ==
                                            `addon-service-${item.ticket_package_type_addon_item_id}`
                                          }
                                          label={item.title}
                                          disabled={
                                            !selectedPackageIDArray.includes(
                                              addon.ticket_package_type_id
                                            )
                                          }
                                          onChange={(e) => {
                                            setRadioValue(
                                              `addon-service-${item.ticket_package_type_addon_item_id}`
                                            );
                                            setFieldValue(`additional`, [
                                              {
                                                package: packages,
                                                selectedService: addon_service,
                                              },
                                            ]);
                                            const selectedPackage = {
                                              [addon.ticket_package_type_id]: {
                                                package_addon: [item],
                                              },
                                            };
                                            dispatch(
                                              handleSelectedPackage({
                                                type: "addon",
                                                selectedPackage:
                                                  selectedPackage,
                                              })
                                            );
                                          }}
                                        />
                                      );
                                    })
                                  : ""}
                              </MDBRow>
                            </div>
                          );
                        })}
                    </div>
                  );
                })
              : ""}
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
