import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const agentInfo = createAsyncThunk("get/agent",
    async() => {
        const response = await apiServices.getAgentInfo()
        return response
    }  
)

const initialState = {
    comission: 700.00,
    modalboxAgent: false,
    modalboxAddBank: false,
    wallet: {
        name: "Techpole Gum",
        bank_name: "Maybank",
        bank_acc: "84336992341"
    },
    comission_history: [
        {
            title: "Commission",
            description: "From jason@gmail.com",
            detail: "Booking",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
    ],
    room_history: [
        {
            title: "Commission",
            description: "From jason@gmail.com",
            detail: "Booking",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
        {
            title: "Bonus",
            description: "From downline sales",
            detail: "profit sharing",
            price: "145.00"
        },
    ]
}

const agentSlice = createSlice({
    name: 'agent',
    initialState,
    reducers: {
        handleModalboxAgent:(state,action) =>{
            return{
                ...state,
                modalboxAgent: !state.modalboxAgent
            }
        },
        handleModalboxAddBank:(state,action) =>{
            return{
                ...state,
                modalboxAddBank: !state.modalboxAddBank
            }
        },
    },
    extraReducers:{
    }
})

export const { 
    handleModalboxAgent,
    handleModalboxAddBank,
} = agentSlice.actions;
const { reducer } = agentSlice
export default reducer