import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBBtn,
  MDBCheckbox,
  MDBRadio,
  MDBBtnGroup,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import BtnRadio from "../../../components/element/btnRadio";
import { addSelectedPackage } from "../../../redux/reducer/detailReducer";
import { Field, FieldArray, useFormikContext } from "formik";

export default function PackageTypeStandard({ packages, indexPackages }) {
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();

  const handleChange = (ticketId, { indexPackages, packages, ticket }) => {
    setFieldValue(`standard[${indexPackages}]`, {
      package: packages,
      selectedPackage: ticket,
    });
    // const selectedPackage = {
    //     [ticketId]:{
    //         package: packages,
    //         selectedPackage: ticket
    //     }
    // }
    // dispatch(addSelectedPackage(selectedPackage))
  };

  return (
    <div className="package-container">
      <MDBRow className="container-subtitle package-name">
        <MDBCol className="col-9">
          <label>
            <div></div>
            {packages.package_name}
          </label>
        </MDBCol>
        {/* <MDBCol className="">
          <label className="detail-button">Details</label>
        </MDBCol> */}
      </MDBRow>
      <MDBRow className="badge-row m-0">
        {packages.tag
          ? packages.tag.map((tag, index) => (
              <MDBCol
                key={index}
                className="badge col-3 p-0"
                style={{ background: `#${tag.color}` }}
              >
                <label>{tag.text}</label>
              </MDBCol>
            ))
          : ""}
        {/* <MDBBadge className="badge">No cancelled</MDBBadge> */}
      </MDBRow>
      <div key={indexPackages} className="package-standard">
        <MDBRow className="package-card-title">
          <label>Package Type</label>
        </MDBRow>
        <MDBRow className="option-card m-0">
          {packages.package_type
            ? packages.package_type.map((ticket, index) => {
                return (
                  <BtnRadio
                    key={index}
                    name={`package-${indexPackages}`}
                    id={`service-${ticket.ticket_package_type_id}`}
                    value={ticket.title}
                    label={ticket.title}
                    handleChange={() =>
                      handleChange(ticket.ticket_package_type_id, {
                        indexPackages,
                        packages,
                        ticket,
                      })
                    }
                  />
                );
              })
            : ""}
        </MDBRow>
      </div>
    </div>
  );
}
