import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function History() {
  const [moreContent, setMoreContent] = useState(false);
  const { history } = useSelector((state) => state.detail.ticketInfo);
  const { loading } = useSelector((state) => state.detail);

  const totalTextLength = history ? history.join("").length : "";

  return (
    <MDBContainer className="history-container p-0">
      {loading ? (
        ""
      ) : (
        <>
          <MDBRow className="container-subtitle">
            <label>History of the place</label>
          </MDBRow>
          <MDBContainer className="content-container">
            <MDBRow
              className={moreContent ? "history " : "history hide-height"}
            >
              {history &&
                history.map((item, index) => (
                  <p key={index} className={"paragraph"}>
                    {item}
                  </p>
                ))}
            </MDBRow>
            {totalTextLength > 250 ? (
              <MDBRow
                className="form-group btn-read-more"
                style={{ marginTop: "1em" }}
              >
                <MDBBtn onClick={() => setMoreContent(!moreContent)}>
                  {moreContent ? "Show Less" : "Read More"}
                </MDBBtn>
              </MDBRow>
            ) : (
              ""
            )}
          </MDBContainer>
        </>
      )}
    </MDBContainer>
  );
}
