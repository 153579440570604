import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { balance } from "../../../redux/reducer/profileReducer";

export default function PaymentMethod({ setCredit, credit }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { credit_balance } = useSelector((state) => state.profile.balance);

  const changePayment = (pay) => {
    // const pay = e.target.value;
    setCredit(pay);
  };

  useEffect(() => {
    dispatch(balance());
  }, []);

  return (
    <MDBContainer className="payment-method-container">
      <MDBRow className="container-title m-0">
        <label className="p-0">
          <div></div>Payment Method
        </label>
      </MDBRow>
      <MDBRow
        onClick={() => changePayment("credit")}
        className={
          credit === "credit"
            ? "payment-method-radio "
            : "payment-method-radio disabled"
        }
      >
        <MDBCol className="col-1 payment-method-border">
          <MDBRadio
            name="flexRadioDefault"
            value="credit"
            id="credit"
            checked={credit === "credit"}
            onChange={() => {}}
          />
        </MDBCol>
        <MDBCol className="col-9">
          <label>Wallet Balance : RM {credit_balance}</label>
        </MDBCol>
        <MDBCol className="col-2">
          <Icon
            icon="ic:twotone-account-balance-wallet"
            color={credit === "credit" ? "#41b1cd" : "#70707063"}
            width="30"
            height="35"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow
        onClick={() => changePayment("online payment")}
        className={
          credit === "online payment"
            ? "payment-method-radio "
            : "payment-method-radio disabled"
        }
      >
        <MDBCol className="col-1">
          <MDBRadio
            name="flexRadioDefault"
            value="online payment"
            id="online"
            checked={credit === "online payment"}
            onChange={() => {}}
          />
        </MDBCol>
        <MDBCol className="col-9">
          <label>Online Banking</label>
        </MDBCol>
        <MDBCol className="col-2">
          <Icon
            icon="ph:bank-bold"
            color={credit === "online payment" ? "#41b1cd" : "#70707063"}
            width="30"
            height="35"
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
