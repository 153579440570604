import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/full.modal.box";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";
import { purchaseHistory } from "../../../redux/reducer/profileReducer";
import ModalHistoryDetalis from "./modal.history.detalis";
import Header from "../../../components/header/header-five";

//redux
import { useDispatch, useSelector } from "react-redux";
import { paymentInfo } from "../../../redux/reducer/paymentReducer";
import { openModalHistoryDetalis } from "../../../redux/reducer/modalReducer";

export default function ModalHistory() {
  const { isOpenHistoryDetalis } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date
  const { history } = useSelector((state) => state.profile);
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(purchaseHistory({ search, startDate, endDate, length, start }));
  }, []);

  useEffect(() => {
    if (id !== "") {
      dispatch(paymentInfo({ payload: id }));
    }
  }, [id]);
  {
  }
  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="profile-modal-container">
            <Header title={"Purchase History"} />
            {history.length > 0 ? (
              history.map((history, index) => {
                return (
                  <MDBContainer
                    className="wallet-history"
                    key={index}
                    onClick={() => {
                      setId(history.reference_no);
                      dispatch(openModalHistoryDetalis());
                    }}
                  >
                    <MDBRow className="wallet-list">
                      <MDBCol className="col-8">
                        <label>{history.title}</label>
                        <MDBRow>
                          <span>at {history.created_at}</span>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className="col-4 wallet-money">
                        <label className="p">
                          RM&nbsp;{parseFloat(history.amount).toFixed(2)}
                        </label>
                      </MDBCol>
                      <div className="line"></div>
                    </MDBRow>
                  </MDBContainer>
                );
              })
            ) : (
              <MDBContainer className="no-data p-5">
                <label>No record yet ~</label>
              </MDBContainer>
            )}
          </MDBModalBody>
        }
      />
      {/* {isOpenHistoryDetalis && id ? <ModalHistoryDetalis id={id} /> : ""} */}
    </RemoveScroll>
  );
}
