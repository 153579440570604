import { useState, useEffect } from "react";
import { Banner1, Banner2, Banner3 } from "../../../assets/images";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

import "swiper/css/autoplay";
// import required modules
import { EffectCreative, Autoplay } from "swiper";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import useWindowDimensions from "../../../components/common/window-dimension";
import { useSelector, useDispatch } from "react-redux";

export default function BannerCarousel({ image0 }) {
  const [image, setImage] = useState([
    {
      url: Banner1,
    },
    {
      url: Banner2,
    },
  ]);
  const { promotion, loading, banner } = useSelector((state) => state.home);
  const { height, width } = useWindowDimensions();
  const [responsive, setResponsive] = useState("mobile");
  const navigate = useNavigate();

  const promotionTicket = () => {
    navigate(`/ticket/${image0[0].id}`);
  };

  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);

  return (
    <div className="banner-container">
      {responsive === "mobile" ? (
        <label className="banner-header">
          Welcome To Gold
          <br /> Coast Morib Resort
        </label>
      ) : (
        <label className="banner-header">
          Welcome To Gold Coast Morib Resort
        </label>
      )}
      <MDBRow>
        <MDBCol className={responsive === "desktop" ? "col-8" : "col-12"}>
          <Swiper
            loop={true}
            grabCursor={true}
            effect={"creative"}
            creativeEffect={{
              prev: {
                shadow: false,
                translate: ["-120%", 0, -500],
              },
              next: {
                shadow: false,
                translate: ["120%", 0, -500],
              },
            }}
            modules={[EffectCreative, Autoplay]}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
          >
            {banner !== [] ? (
              banner.map((item, index) => (
                <SwiperSlide key={index}>
                  <MDBContainer className="p-0">
                    <img
                      className={
                        responsive === "mobile"
                          ? "banner-image"
                          : "banner-image-desktop"
                      }
                      src={item.url}
                      width="100%"
                    />
                  </MDBContainer>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <MDBContainer className="p-0">
                  <img
                    className={
                      responsive === "mobile"
                        ? "banner-image"
                        : "banner-image-desktop"
                    }
                    src={Banner3}
                    width="100%"
                  />
                </MDBContainer>
              </SwiperSlide>
            )}
          </Swiper>
        </MDBCol>

        {responsive === "desktop" && image0[0] && image0[1] ? (
          <MDBCol className="col-4 row-row">
            <MDBRow
              className="row-row"
              onClick={() => {
                image0[0]
                  ? navigate(`/ticket/${image0[0].id}`)
                  : navigate("/promotion");
              }}
            >
              <img
                className="promotion-image"
                src={image0 && image0 !== "" ? image0[0].image : Banner1}
              />
            </MDBRow>
            <MDBRow
              className="row-row"
              onClick={() => {
                image0[1]
                  ? navigate(`/ticket/${image0[1].id}`)
                  : image0[0]
                  ? navigate(`/ticket/${image0[0].id}`)
                  : navigate("/promotion");
              }}
            >
              <img
                className="promotion-image"
                src={
                  image0 && image0[1] !== ""
                    ? image0[1].image
                    : image0[0]
                    ? image0[0].image
                    : Banner2
                }
              />
            </MDBRow>
          </MDBCol>
        ) : (
          ""
        )}
      </MDBRow>
    </div>
  );
}
