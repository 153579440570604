import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";

import Header from "../../components/header/header-one";
import Navbar from "../../components/navbar/navbar-one";

import PlaningNavbar from "./component/planing.header";
import PlaningCard from "./component/planing.card";
import CheckOutModal from "./component/modal.payment";

//redux
import { useDispatch, useSelector } from "react-redux";
import { planning } from "../../redux/reducer/planingReducer";
import { planningInfo } from "../../redux/reducer/planingReducer";
import { EmptyData } from "./../../assets/images/index";
import { useParams } from "react-router";
import Login from "../Login";

export default function Planing() {
  const { referral_code } = useParams();
  const { modalBoxCheckOut } = useSelector((state) => state.planning);
  const { listing, type, loading } = useSelector((state) => state.planning);
  const { isOpenLogin } = useSelector((state) => state.modal);
  const [ticket, setTicket] = useState("all");
  const dispatch = useDispatch();

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#12B6DF");
    // .setAttribute("content", "#fafbfb");
  }

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  useEffect(() => {
    document.body.style.background =
      "linear-gradient(180deg, #12B6DF 0%, #F8EFE4 49.48%)";
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    dispatch(planning({ type: ticket }));
    scrollToTop();
  }, []);

  return (
    <MDBContainer className="planing-container" breakpoint="none">
      <Header />
      <Navbar />
      <h1 className="header">My Trip Planning</h1>
      <div className="fixed-container" breakpoint="none">
        <MDBContainer className="planning-desktop">
          <PlaningNavbar setTicket={setTicket} ticket={ticket} />
          <MDBRow className="m-0">
            {loading ? (
              <div className={`empty-data`}>
                <img src={EmptyData} height="50%" />
              </div>
            ) : listing.length > 0 ? (
              listing.map((planning, index) => (
                <>
                  <PlaningCard
                    ticket={ticket}
                    planning={planning}
                    index={index}
                  />
                </>
              ))
            ) : (
              <label className="h1">Not Planning Your Trip</label>
            )}
          </MDBRow>
        </MDBContainer>
      </div>
      {modalBoxCheckOut && <CheckOutModal show={modalBoxCheckOut} />}
      {isOpenLogin && (
        <Login referral_code={referral_code} show={isOpenLogin} />
      )}
    </MDBContainer>
  );
}
