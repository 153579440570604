import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CloseLoginModal } from "../../../assets/images";
import { closeModalLogin } from "../../../redux/reducer/modalReducer";

export default function LoginHeader({
  changeLoginModal,
  changeModal,
  changeBtt,
}) {
  const dispatch = useDispatch();

  return (
    <MDBRow className="login-header">
      <MDBCol>
        {changeBtt === true ? (
          <img
            src={CloseLoginModal}
            width="65em"
            onClick={() => dispatch(closeModalLogin())}
          />
        ) : (
          ""
        )}
      </MDBCol>
      <MDBCol className="login-header-title">
        {changeModal ? "SIGN UP" : "LOGIN"}
      </MDBCol>
      <MDBCol
        className="login-header-button"
        onClick={changeBtt === true ? changeLoginModal : ""}
      >
        {changeBtt === true ? (changeModal ? "Log In" : "Sign Up") : ""}
      </MDBCol>
    </MDBRow>
  );
}
