import { useState } from "react";
import { MDBModalBody } from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";
import InputField from "../../components/element/inputField";
import ModalBox from "../../components/modal/full.modal.box";
import LoginHeader from "./component/login.header";
import LoginForm from "./component/login.form";
import RegisterForm from "./component/register.form";
import ForgotForm from "./component/forgot-form";
import RegisterVerficationForm from "./component/register.verfication.form";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export default function Login({ show, referral_code }) {
  const [data, setData] = useState();
  const [changeBtt, setChangeBtt] = useState(true);
  const [code, setCode] = useState([]);
  const [time, setTime] = useState(0);
  const [codeMessage, setCodeMessage] = useState(false);
  const [changeModal, setChangeModal] = useState(false);
  const [openForgotForm, setForgotForm] = useState(false);
  const [registerForm, setRegisterForm] = useState(false);
  // const { referral_code } = useParams();

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      // .setAttribute("content", "#12B6DF");
      .setAttribute("content", "#fafbfb");
  }

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  const changeLoginModal = () => {
    setChangeModal(!changeModal);
  };

  const forgotPasswordModal = () => {
    setForgotForm(true);
  };

  const changeRegisterModal = () => {
    setRegisterForm(true);
  };

  return (
    <RemoveScroll>
      <ModalBox
        show={show}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="login-container">
            <LoginHeader
              changeModal={changeModal}
              changeLoginModal={changeLoginModal}
              changeBtt={changeBtt}
            />
            {changeModal ? (
              <>
                {registerForm ? (
                  <RegisterVerficationForm
                    setChangeBtt={setChangeBtt}
                    data={data}
                    code={code}
                    setCode={setCode}
                    codeMessage={codeMessage}
                    setCodeMessage={setCodeMessage}
                    time={time}
                    setTime={setTime}
                    referral_code={referral_code}
                  />
                ) : (
                  <RegisterForm
                    changeRegisterModal={changeRegisterModal}
                    setRegisterForm={setRegisterForm}
                    setData={setData}
                    setCode={setCode}
                    setCodeMessage={setCodeMessage}
                    setTime={setTime}
                  />
                )}
              </>
            ) : (
              <>
                {openForgotForm ? (
                  <ForgotForm setChangeBtt={setChangeBtt} />
                ) : (
                  <LoginForm
                    forgotPasswordModal={forgotPasswordModal}
                    openForgotForm={openForgotForm}
                    changeLoginModal={changeLoginModal}
                  />
                )}
              </>
            )}
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
