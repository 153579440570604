import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRunLoader: false,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    runLoader: (state, action) => {
      state.isRunLoader = true;
    },
    
    stopLoader: (state, action) => {
        state.isRunLoader = false;
      },
   
  },
});

export const {
  runLoader,
  stopLoader

} = loaderSlice.actions;
export default loaderSlice.reducer;
