import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import config from "../../../config.json";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBCardImage,
  MDBCol,
  MDBRow,
  MDBCardHeader,
  MDBContainer,
} from "mdb-react-ui-kit";
import authServices from "../../../services/auth.services";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  openModalLanguage,
  openModalPurchaseHistory,
  openModalChangePassword,
  openModalNotifacation,
  openModalProfile,
  openModalPrivary,
  openModalTos,
  openModalReport,
  openModalAgent,
} from "../../../redux/reducer/modalReducer";

export default function ProfileSetting() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    authServices.logout();
    navigate("/");
  };
  const onComming = () => {
    toast.info("Comming Soon", {
      position: "top-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  };

  const chatSevice = () => {
    let link = "https://web.whatsapp.com/";
    window.location.replace(link);
  };

  return (
    <>
      <MDBContainer className="profile-setting">
        <label>Account settings</label>
        <MDBRow
          className="setting-body"
          onClick={() => dispatch(openModalProfile())}
        >
          <MDBCol className="col-2">
            <Icon icon="gg:profile" color="darkgray" width="25" height="22" />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Personal Information</label>
          </MDBCol>

          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow
          className="setting-body"
          onClick={() => dispatch(openModalPurchaseHistory())}
        >
          <MDBCol className="col-2">
            <Icon
              icon="uil:transaction"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Purchase History</label>
          </MDBCol>

          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow
          className="setting-body"
          onClick={() => dispatch(openModalLanguage())}
        >
          <MDBCol className="col-2">
            <Icon
              icon="carbon:ibm-watson-language-translator"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Language</label>
          </MDBCol>
          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
        {/* <MDBRow
          className="setting-body"
          onClick={() => dispatch(openModalNotifacation())}
        >
          <MDBCol className="col-2">
            <Icon
              icon="iconoir:bell-notification"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Notification settings</label>
          </MDBCol>
          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow> */}
        <MDBRow
          className="setting-body"
          onClick={() => dispatch(openModalChangePassword())}
        >
          <MDBCol className="col-2">
            <Icon
              icon="ph:password-fill"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Change Password</label>
          </MDBCol>
          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      {/* <MDBContainer className="profile-setting">
        <label>Referrals & Credits</label>
        <MDBRow
          className="setting-body"
          // onClick={() => dispatch(openModalAgent())}
          onClick={() => onComming()}
        >
          <MDBCol className="col-2">
            <Icon
              icon="fluent:people-list-16-regular"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Switch to agent account</label>
          </MDBCol>
          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow
          className="setting-body"
          onClick={() => onComming()}

          // onClick={() => dispatch(openModalReport())}
        >
          <MDBCol className="col-2">
            <Icon
              icon="fluent:people-list-16-regular"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Refer a Member</label>
            <MDBRow className="p-message">
              Earn RM50 for every new member you refer
            </MDBRow>
          </MDBCol>
          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow
          className="setting-body"
          onClick={() => dispatch(openModalReport())}
        >
          <MDBCol className="col-2">
            <Icon
              icon="tabler:report-analytics"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Report</label>
          </MDBCol>
          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer> */}
      <MDBContainer className="profile-setting">
        <label>Legal</label>
        <MDBRow
          className="setting-body"
          onClick={() => dispatch(openModalTos())}
        >
          <MDBCol className="col-2">
            <Icon
              icon="ri:hotel-line"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Term of Service </label>
          </MDBCol>
          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow
          className="setting-body"
          onClick={() => dispatch(openModalPrivary())}
        >
          <MDBCol className="col-2">
            <Icon
              icon="ri:hotel-line"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Privacy</label>
          </MDBCol>
          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="setting-body" onClick={() => chatSevice()}>
          <MDBCol className="col-2">
            <Icon
              icon="mdi:customer-service"
              color="darkgray"
              width="25"
              height="22"
            />
          </MDBCol>
          <MDBCol className="col-9">
            <label>Chat with Gold Coast Morib</label>
          </MDBCol>
          <MDBCol className="col-1">
            <Icon
              icon="eva:arrow-ios-back-outline"
              color="darkgray"
              width="25"
              height="25"
              hFlip={true}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="logout">
          <p onClick={() => handleLogout()}>Log out</p>
        </MDBRow>
        <MDBRow className="version">
          <p>VERSION {config.version}</p>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
