import {
  MDBBadge,
  MDBBtn,
  MDBCheckbox,
  MDBCol,
  MDBContainer,
  MDBRadio,
  MDBRow,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputCount from "../../element/inputCount";
import { Icon } from "@iconify/react";
import { handleSelectedDate } from "../../../redux/reducer/checkoutReducer";
import { useEffect } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment/moment";

export default function CheckoutDetail({ step, item, errors }) {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const { addon } = useSelector((state) => state.checkout.testData);
  const [moreContent, setMoreContent] = useState(true);
  const [dateRange, setDateRange] = useState("");
  const totalTextLength = item.package.tnc
    ? item.package.tnc.join("").length
    : "";

  useEffect(() => {
    if (item.package.is_select_date_require == 0) {
      setFieldValue("");
    } else if (item.package.is_select_date_require == 1) {
      setFieldValue("validationDate", true);
    }
  }, [step]);

  const handleChange = (e) => {
    const selectedDate = {
      [item.package.ticket_package_id]: {
        ticket_package_id: item.package.ticket_package_id,
        select_date: e,
      },
    };
    dispatch(handleSelectedDate({ selectedDate }));
    setFieldValue("select_date", e);
  };

  useEffect(() => {
    if (dateRange) {
      handleChange(moment(new Date(dateRange)).format("DD/MM/YYYY hh:mm"));
    }
  }, [dateRange]);

  return (
    <MDBContainer className="content-container" breakpoint="none">
      <MDBRow className="container-subtitle">
        <label>{item.package.package_name}</label>
      </MDBRow>
      {item.package.tag ? (
        <MDBRow className="badge-row">
          {item.package.tag.map((tag, index) => (
            <div key={index} className="p-0">
              {tag.text ? (
                <MDBCol
                  className="badge col-3 p-0"
                  style={{ background: `#${tag.color}` }}
                >
                  <label>{tag.text}</label>
                </MDBCol>
              ) : (
                ""
              )}
            </div>
          ))}
        </MDBRow>
      ) : (
        ""
      )}
      <MDBContainer className="detail-container">
        <MDBRow className="detail-row justify-content-center align-items-center">
          <label className="detail-title">Term & condition</label>
          <MDBCol
            className={moreContent ? "detail-text hideHeight" : "detail-text"}
          >
            {item.package.tnc
              ? item.package.tnc.map((tnc, index) => (
                  <span key={index}>{tnc}</span>
                ))
              : ""}
          </MDBCol>
          {totalTextLength > 100 ? (
            <label onClick={() => setMoreContent(!moreContent)}>see more</label>
          ) : (
            ""
          )}
        </MDBRow>
      </MDBContainer>
      {item.package.avalability_date_type === "date range" ? (
        <MDBRow className="container-subtitle m-0">
          <MDBCol className="col-4 p-0 d-flex">
            <label>
              Availability
              <Icon
                icon="ep:arrow-right-bold"
                color="#707070"
                width="15"
                height="20"
                style={{ marginLeft: "1em" }}
              />
            </label>
          </MDBCol>
          <MDBCol className="col-8 date-range">
            <DatePicker
              value={dateRange}
              placeholder={`${moment(
                new Date(item.package.availability_start_date)
              ).format("DD MMM YYYY")} - ${moment(
                new Date(item.package.availability_end_date)
              ).format("DD MMM YYYY")}`}
              minDate={new Date(item.package.availability_start_date)}
              maxDate={new Date(item.package.availability_end_date)}
              onChange={(date) => setDateRange(date)}
              disabled
            />
          </MDBCol>
        </MDBRow>
      ) : item.package.avalability_date_type === "specific date" ? (
        <div>
          <MDBRow className="container-subtitle">
            <label>Please select a visit date</label>
          </MDBRow>
          <MDBRow
            className={
              errors["select_date"]
                ? "container-date-options errors-msg m-0"
                : "container-date-options m-0"
            }
          >
            {item.package.availability_date.map((date, index) => {
              return (
                <MDBRadio
                  name="date"
                  key={`date-${index}`}
                  id={`date-${index}`}
                  onChange={(e) => {
                    handleChange(e.target.value, date);
                  }}
                  value={date}
                  label={moment(date).format("DD MMM")}
                  btn
                />
              );
            })}
          </MDBRow>
          {errors && errors["select_date"] && errors["select_date"] ? (
            <div className="text-danger text-right no-padding error-message">
              {errors["select_date"]}
            </div>
          ) : null}
        </div>
      ) : (
        ""
      )}
      <MDBRow className="selected-package-container m-0">
        <MDBRow className="selected-package-title">
          <label className="p-0">{item.selectedPackage.title}</label>
        </MDBRow>
        <div
          className={
            errors && errors["ticket"]
              ? "choose-service-card errors-msg"
              : "choose-service-card"
          }
        >
          {item.selectedPackage.ticket_package_type_id in addon ? (
            <>
              {addon[item.selectedPackage.ticket_package_type_id].map(
                (item, index) => {
                  return (
                    <MDBRow key={index} className="count-field">
                      <MDBCol className="label col-9">
                        <label>Addon - {item.title}</label>
                      </MDBCol>
                      <MDBCol className="button-check col-3">
                        RM {parseFloat(item.current_price).toFixed(2)}
                      </MDBCol>
                    </MDBRow>
                  );
                }
              )}
            </>
          ) : (
            ""
          )}
          {item.selectedPackage.package_type_item
            ? item.selectedPackage.package_type_item.map((item, index) => {
                return <InputCount item={item} index={index} />;
              })
            : ""}
        </div>
      </MDBRow>
    </MDBContainer>
  );
}
