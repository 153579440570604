import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
//redux
import { useDispatch, useSelector } from "react-redux";

export default function CreditHistory() {
  const dispatch = useDispatch();
  const { history } = useSelector((state) => state.wallet);

  return (
    <MDBContainer className="detail-container">
      <MDBRow className="container-title">
        <MDBCol className="col-9">
          <label>
            <div></div>Credit History
          </label>
        </MDBCol>
        <MDBCol className="col-3 d-flex justify-content-end align-items-center">
          <span>Details</span>
        </MDBCol>
      </MDBRow>
      {history
        ? history.map((item, index) => {
            return (
              <div key={index} className="listing-row">
                <MDBRow>
                  <MDBCol className="col-6">
                    <MDBRow className="title">
                      <label>{item.type}</label>
                    </MDBRow>
                    <MDBRow className="detail">
                      <label>{item.created_at}</label>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol className="col-6 price">
                    <MDBRow>
                      <span className="_span --amount">{item.amount}</span>
                    </MDBRow>
                    <MDBRow>
                      <span className="_span --description">
                        {item.description}
                      </span>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <div className="single-line"></div>
              </div>
            );
          })
        : ""}
    </MDBContainer>
  );
}
