import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

export default function Detail() {
  const { payment_items, total_price, order_type, room_info } = useSelector(
    (state) => state.payment.payment
  );

  return (
    <MDBContainer className="detail-container">
      <MDBRow className="container-title m-0">
        <label className="">
          <div></div>Detail
        </label>
      </MDBRow>
      {order_type === "booking" ? (
        <>
          <MDBContainer className="booking-container">
            <MDBRow>
              <MDBCol className="payment-detail-title">
                <label>{room_info.title}</label>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="payment-detail-item">
                <label>Date : {room_info.date}</label>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="payment-detail-item">
                <label>{room_info.nights} Nights </label>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="payment-detail-item">
                <label>Pax : {room_info.pax}</label>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="payment-detail-item">
                <label>Paid per night: {room_info.average} </label>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      ) : order_type === "room service" ? (
        <div>
          {Object.keys(payment_items).map((item, index) => (
            <MDBContainer key={index} className="content-container">
              <MDBRow className="package-option-label">
                <label>{item}</label>
              </MDBRow>
              {Object.keys(payment_items[item]).map((service, index) => (
                <div className="service" key={index}>
                  <MDBRow className="package-name-label">
                    <label>{service}</label>
                  </MDBRow>
                  {payment_items[item][service].map((listing, index) => (
                    <div className="detail-listing" key={index}>
                      <MDBRow>
                        <label>
                          {listing.quantity} x {listing.package_title}
                        </label>
                      </MDBRow>
                      <MDBRow className="package-name-label">
                        <label>Delivery - {listing.visit_time}</label>
                      </MDBRow>
                    </div>
                  ))}
                </div>
              ))}
            </MDBContainer>
          ))}
        </div>
      ) : (
        <div>
          {payment_items &&
            payment_items.map((item, index) => {
              return (
                <MDBContainer key={index} className="content-container">
                  <MDBRow className="package-option-label">
                    <label>{item.ticket_package_title}</label>
                  </MDBRow>
                  <MDBRow className="package-name-label">
                    <label>{item.ticket_package_type_title}</label>
                  </MDBRow>
                  <div className="detail-listing">
                    {item.ticket_package_type_item.map((listing, index) => {
                      return (
                        <div key={index}>
                          <MDBRow>
                            <label>
                              {listing.quantity} x{" "}
                              {listing.ticket_package_type_item_title}
                            </label>
                          </MDBRow>
                          {listing.ticket_package_type_addon_item ? (
                            <MDBRow className="detail-listing-addon">
                              <label className="i">Add on : </label>
                              {listing.ticket_package_type_addon_item.map(
                                (addon, index) => (
                                  <label key={index}>
                                    {addon.ticket_package_type_addon_item_title}
                                  </label>
                                )
                              )}
                            </MDBRow>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                </MDBContainer>
              );
            })}
        </div>
      )}
      <MDBRow className="package-price">
        <label>
          Total Paid: RM{" "}
          {total_price ? parseFloat(total_price).toFixed(2) : "0.00"}
        </label>
      </MDBRow>
    </MDBContainer>
  );
}
