import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

export default function InputSearch({ setSearch }) {
  return (
    <MDBRow className="form-group input-search">
      <MDBCol className="input-col">
        <input
          className="w-100"
          type="text"
          name="input"
          placeholder="search the place name"
          onChange={(e) => setSearch(e.target.value)}
        />
      </MDBCol>
      <MDBCol className="col-3 justify-content-center d-flex">
        <Icon icon="dashicons:search" color="#77a2c1" width="30" heigth="27" />
      </MDBCol>
    </MDBRow>
  );
}
