import { useState } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBProgress,
  MDBProgressBar,
  MDBRow,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { Formik, Form, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//redux
import { useDispatch, useSelector } from "react-redux";

//component
import ModalBox from "./half.modal.box";
import PackageDetail from "./checkout.component/package.detail";
import CompletePayment from "./complete.payment";
import CheckoutDetail from "./checkout.component/checkout.detail";
import {
  checkoutRoom,
  checkoutService,
  checkoutTicket,
  handleModalBoxCheckOut,
} from "../../redux/reducer/checkoutReducer";
import { useNavigate } from "react-router-dom";
import { paymentInfo } from "../../redux/reducer/paymentReducer";
import CheckoutDetailService from "./checkout.component/checkout.detail.service";
import { openModalLogin } from "../../redux/reducer/modalReducer";
import Login from "../../pages/Login";
import CheckoutBooking from "./checkout.component/checkout.booking";
import { useEffect } from "react";

export default function CheckOutModal({ show, roomId }) {
  const [selectedTime, setSelectedTime] = useState([]);
  const [validButton, setValidButton] = useState(false);
  const { image, name, tag } = useSelector((state) => state.booking.roomInfo);
  const { avalability_date_type } = useSelector(
    (state) => state.checkout.selectedPackage[0].package
  );
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { isOpenLogin } = useSelector((state) => state.modal);
  const {
    testData,
    chargePrice,
    checkoutTitle,
    checkoutType,
    selectedPackage,
    selectedPackageService,
    totalSelectedPackage,
  } = useSelector((state) => state.checkout);
  const { room_no, contact_number } = useSelector(
    (state) => state.service.room_detail
  );
  const [step, setStep] = useState(0);
  const currentProcess = step / totalSelectedPackage;

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    let text = setInterval(() => {
      setValidButton(false);
    }, 5000);
    return () => {
      clearInterval(text);
    };
  }, [validButton]);

  const schema = Yup.object({
    validationDate: Yup.boolean(),
    select_date: Yup.string().when("validationDate", {
      is: true,
      then: Yup.string().required("Choose booking date"),
    }),
    ticket: Yup.array().min(1, "required").required("required"),
  });

  const schemaService = Yup.object({
    room_no: Yup.string(),
    contact_number: Yup.string(),
    room_service_category_item_id: Yup.array().min(
      step + 1,
      "Select at least one request"
    ),
    visit_time: Yup.array().min(step + 1, "required"),
  });

  const roomSchema = Yup.object({
    booking_start_at: Yup.string().required("Required"),
    booking_end_at: Yup.string().required("Required"),
  });

  const roomValues = {
    type: "booking",
    booking_start_at: "",
    booking_end_at: "",
    room_id: roomId,
  };

  const initialValues = {
    select_date: "",
    validationDate: avalability_date_type == "specific date" ? true : false,
    ticket: [],
  };

  const initialValuesService = {
    room_no: room_no ? room_no : "",
    // contact_number: contact_number ? contact_number : "",
    room_service_category_item_id: [],
    visit_time: [],
  };

  const handleSubmit = (values) => {
    // let ticket = [];
    let addon = [];
    let selectDate = [];
    // const objTicket = Object.entries(testData.ticket);
    // ticket.push(objTicket);

    // let newTicket = [];
    // ticket[0].map((item, index) => {
    //   newTicket.push(item[1]);
    // });

    const objAddon = Object.entries(testData.addon);
    addon.push(objAddon);

    let newAddon = [];
    addon[0].map((item, index) => {
      let addonArry = [];
      item[1].map((addon) => {
        addonArry.push(addon.ticket_package_type_addon_item_id);
      });

      const addObj = {
        ticket_package_type_id: item[0],
        addon_id: addonArry,
      };
      // addObj.addon_id.push(item[1].ticket_package_type_id)
      newAddon.push(addObj);
    });

    const objDate = Object.entries(testData.select_date);
    selectDate.push(objDate);

    let newSelectDate = [];
    selectDate[0].map((item, index) => {
      newSelectDate.push(item[1]);
    });

    const payload = {
      type: "ticket",
      ticket: values.ticket,
      addon: newAddon,
      select_date: newSelectDate,
    };

    dispatch(checkoutTicket({ payload }))
      .unwrap()
      .then((res) => {
        dispatch(paymentInfo({ payload: res.reference_no }))
          .unwrap()
          .then((res) => {})
          .catch((ex) => {});
        navigator(`/payment/${res.reference_no}`);
        dispatch(
          handleModalBoxCheckOut({
            title: "Booking Options",
            type: "service",
          })
        );
        // toast.success("Success", {
        //   position: "top-center",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   theme: "colored",
        // });
      })
      .catch((ex) => {
        const invalidToken = ex.response.status;
        if (invalidToken === 401) {
          dispatch(openModalLogin());
        }
        const errors = ex.response.data.errors;
        let errMsg = "";
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item) => {
            errMsg = errors[item];
          });
        }
        toast.error(errMsg[0], {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      });
  };

  const handleSubmitService = (values) => {
    const payload = {
      ...values,
      visit_time: selectedTime,
    };

    dispatch(checkoutService({ payload: payload }))
      .unwrap()
      .then((res) => {
        dispatch(paymentInfo({ payload: res.reference_no }));
        navigator(`/payment/${res.reference_no}`);
      })
      .catch((ex) => {
        const invalidToken = ex.response.status;
        if (invalidToken === 401) {
          dispatch(openModalLogin());
        }
      });
  };

  const handleSubmitRoom = (values) => {
    if (values.booking_end_at !== "Invalid date") {
      dispatch(checkoutRoom({ payload: values }))
        .unwrap()
        .then((res) => {
          dispatch(paymentInfo({ payload: res.data.reference_no }))
            .unwrap()
            .then((res) => {})
            .catch((ex) => {});
          navigator(`/payment/${res.data.reference_no}`);
        })
        .catch((ex) => {
          const invalidToken = ex.response.status;
          if (invalidToken === 401) {
            dispatch(openModalLogin());
          }
        });
    } else {
      toast.error("Cannot select an invalid date", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      dispatch(
        handleModalBoxCheckOut({
          title: "Booking Options",
          type: "service",
        })
      );
    }
  };

  return (
    <div>
      <Formik
        initialValues={
          checkoutType === "service"
            ? initialValuesService
            : checkoutType === "booking"
            ? roomValues
            : initialValues
        }
        validationSchema={
          checkoutType === "service"
            ? schemaService
            : checkoutType === "booking"
            ? roomSchema
            : schema
        }
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(
          values,
          {
            setFieldValue,
            setFieldError,
            resetForm,
            isSubmitting,
            setSubmitting,
          }
        ) => {
          if (checkoutType === "booking") {
            handleSubmitRoom(values);
            resetForm({ values: "" });
            dispatch(
              handleModalBoxCheckOut({
                title: "Booking Options",
                type: "service",
              })
            );
          } else {
            if (step === totalSelectedPackage - 1) {
              if (checkoutType === "service") {
                if (
                  values.room_service_category_item_id.length !==
                  selectedTime.length
                ) {
                  const getLastTime = values.visit_time[step];
                  const length =
                    values.room_service_category_item_id.length -
                    selectedTime.length;
                  let newData = selectedTime;
                  for (var i = 0; i < length; i++) {
                    newData.push(getLastTime);
                  }
                  // handleNextStep();
                  handleSubmitService(values);
                  resetForm({ values: "" });
                }
              } else {
                handleSubmit(values);
                resetForm({ values: "" });
              }
            } else {
              if (checkoutType === "service") {
                if (
                  values.room_service_category_item_id.length !==
                  selectedTime.length
                ) {
                  const getLastTime = values.visit_time[step];
                  const length =
                    values.room_service_category_item_id.length -
                    selectedTime.length;
                  let newData = selectedTime;
                  for (var i = 0; i < length; i++) {
                    newData.push(getLastTime);
                  }
                  handleNextStep();
                  setSubmitting(false);
                }
              } else {
                handleNextStep();
                setSubmitting(false);
              }
            }
          }
        }}
      >
        {({
          setFieldValue,
          isSubmitting,
          handleReset,
          isValid,
          errors,
          values,
          handleSubmit,
        }) => (
          <Form>
            <ModalBox
              className="check-out-container"
              show={show}
              backDrop={false}
              header={
                <MDBRow>
                  <MDBCol
                    onClick={() => {
                      if (checkoutType === "booking") {
                        dispatch(
                          handleModalBoxCheckOut({
                            title: "Choose Date",
                            type: "booking",
                          })
                        );
                      } else {
                        dispatch(
                          handleModalBoxCheckOut({
                            title: "Booking Options",
                            type: "service",
                          })
                        );
                      }
                    }}
                  >
                    <Icon
                      icon="fluent-emoji-high-contrast:cross-mark"
                      color="#707070"
                      width="20"
                      height="25"
                    />
                  </MDBCol>
                  <MDBCol className="col-8">
                    <label className="title">{checkoutTitle}</label>
                  </MDBCol>
                  <MDBCol></MDBCol>
                </MDBRow>
              }
              content={
                <div>
                  {checkoutType === "booking" ? (
                    <div>
                      <CheckoutBooking roomId={roomId} />
                    </div>
                  ) : (
                    <div>
                      {/* <div key={index}> */}
                      {checkoutType === "service" ? (
                        <>
                          {selectedPackageService &&
                            selectedPackageService.map((page, index) => {
                              return (
                                <div key={index}>
                                  {step === index && (
                                    <CheckoutDetailService
                                      index={index}
                                      step={step}
                                      item={page.item}
                                      selectedTime={selectedTime}
                                      setSelectedTime={setSelectedTime}
                                    />
                                  )}
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {selectedPackage &&
                            selectedPackage.map((page, index) => {
                              return (
                                <div key={index}>
                                  {step === index && (
                                    <CheckoutDetail
                                      step={step}
                                      item={page}
                                      errors={errors}
                                    />
                                  )}
                                </div>
                              );
                            })}
                        </>
                      )}
                      {/* </div> */}
                      {/* );
                        })} */}
                    </div>
                  )}
                </div>
              }
              footer={
                checkoutType !== "booking" ? (
                  <MDBRow className="navbar-bottom checkout-payment fixed-bottom">
                    <MDBProgress>
                      <MDBProgressBar striped width={currentProcess * 100} />
                    </MDBProgress>
                    <MDBCol className="helper">
                      <label>
                        <span>Charge</span> RM {chargePrice}
                      </label>
                    </MDBCol>
                    <MDBCol className="button">
                      <MDBBtn
                        disabled={isSubmitting}
                        type="submit"
                        onClick={() => {
                          if (step === totalSelectedPackage - 1) {
                            setValidButton(true);
                          }
                          dispatch(handleModalBoxCheckOut(), handleSubmit());
                        }}
                      >
                        {step === totalSelectedPackage - 1 ? "Confirm" : "Next"}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                ) : (
                  <MDBRow className="navbar-bottom  checkout-payment">
                    <MDBCol className="button">
                      <MDBBtn
                        disabled={isSubmitting}
                        type="submit"
                        onClick={() =>
                          dispatch(handleModalBoxCheckOut(), handleSubmit())
                        }
                      >
                        Confirm
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                )
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
