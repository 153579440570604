import { MDBBtn, MDBCol, MDBContainer, MDBNavbar } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedPackage,
  addSelectedPackageService,
  handleModalBoxCheckOut,
} from "../../../redux/reducer/checkoutReducer";
import { serviceCategory } from "../../../redux/reducer/serviceReducer";
import CategoryCard from "./category.card";

export default function ServiceCategory() {
  const dispatch = useDispatch();
  const service = useSelector((state) => state.service.serviceCategory);
  const [data, setData] = useState({
    standard: [],
  });

  const schema = Yup.object({
    standard: Yup.array()
      .min(1, "You must select at least one category...")
      .required("required"),
  });

  const handleSubmit = (values, setSubmitting) => {
    dispatch(addSelectedPackageService(values));
    dispatch(
      handleModalBoxCheckOut({ title: "Room Service", type: "service" })
    );
    setSubmitting(false);
  };

  useEffect(() => {
    dispatch(serviceCategory());
  }, []);

  return (
    <MDBContainer className="service-category-container">
      <Formik
        initialValues={data}
        validationSchema={schema}
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
      >
        {({ setFieldValue, isSubmitting, handleReset, isValid, errors }) => (
          <Form>
            <label>
              We will process request according to the order. please be patient
              of your request
            </label>
            {/* {errors && errors["standard"] ? (
              <div className="text-danger text-start no-padding error-message">
                {errors["standard"]}
              </div>
            ) : null} */}
            {service.length > 0
              ? service.map((item, index) => {
                  return (
                    <div key={index}>
                      {/* {item.room_service_category_services.length == 0 ? (
                        ""
                      ) : ( */}
                      <CategoryCard
                        title={item.title}
                        service={item.room_service_category_services}
                      />
                      {/* )} */}
                    </div>
                  );
                })
              : ""}
            <MDBNavbar className="navbar-bottom service-room" fixed="bottom">
              <MDBCol className="helper">{/* <label>Helper</label> */}</MDBCol>
              <MDBCol className="button">
                <MDBBtn type="submit" disabled={isSubmitting}>
                  Next
                </MDBBtn>
              </MDBCol>
            </MDBNavbar>
          </Form>
        )}
      </Formik>
    </MDBContainer>
  );
}
