import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBRadio,
  MDBBtnGroup,
  MDBBtn,
} from "mdb-react-ui-kit";
import moment from "moment/moment";
import { useState } from "react";

//redux
import { useSelector } from "react-redux";
import { booking } from "../../../redux/reducer/booking.reducer";

export default function PlaningTicket() {
  const {
    total_paid,
    reference_no,
    use_before,
    paid_at,
    use_at,
    package_detail,
    package_title,
    package_name,
    title,
    pax,
    type,
    used_at,
    payment_method,
    nights,
  } = useSelector((state) => state.planning.planningInfo);

  const totalPackageLength =
    type === "booking"
      ? package_detail.length > 0 && package_detail.length - 1
      : "";

  return (
    <MDBContainer className="planing-details">
      <MDBContainer>
        <label className="details-label">Ticket details</label>
        <div className="line"></div>
        <MDBRow>
          <label className="event-title">{title}</label>
        </MDBRow>
        <MDBContainer className="ticket-container">
          <MDBRow className="event-title-row">
            <label className="event-title">
              {type === "booking" ? "Staying info" : package_title}
            </label>
          </MDBRow>
          {type === "booking" ? (
            <>
              <MDBRow>
                <span className="ticket-name">Room For Pax : {pax} </span>
              </MDBRow>
              <MDBRow>
                <span className="ticket-name">
                  Check in date -{" "}
                  {package_detail.length > 0
                    ? moment(new Date(package_detail[0].date)).format(
                        "DD MMM YYYY"
                      )
                    : ""}
                </span>
              </MDBRow>
              <MDBRow>
                <span className="ticket-name">
                  Check out date -{" "}
                  {package_detail.length > 0
                    ? moment(
                        new Date(package_detail[totalPackageLength].date)
                      ).format("DD MMM YYYY")
                    : ""}
                </span>
              </MDBRow>
              <MDBRow>
                <span className="ticket-name">{nights} Nights </span>
              </MDBRow>
            </>
          ) : (
            <>
              <MDBRow>
                <span className="ticket-name">{package_name} </span>
              </MDBRow>

              <MDBRow>
                <span className="ticket-name">
                  (use on{" "}
                  {moment(new Date(use_before)).format("DD MMM YYYY hh:mm A")}){" "}
                </span>
              </MDBRow>
            </>
          )}
        </MDBContainer>
      </MDBContainer>
      {type !== "booking" ? (
        <MDBContainer>
          <label className="details-label">Package details</label>
          <div className="line"></div>
          <MDBContainer className="ticket-container">
            <MDBRow className="event-title-row">
              <label className="event-title">{package_name}</label>
            </MDBRow>
            <div></div>
            <MDBRow>
              <span className="ticket-name">
                {package_detail.map((item, index) => item.title)}
              </span>
            </MDBRow>
            <MDBRow>
              <span className="ticket-name">
                {" "}
                {package_detail.map((item, index) => item.quantity)}
                x&nbsp;&nbsp;
                {package_detail.map((item, index) => item.title)}
              </span>
            </MDBRow>
          </MDBContainer>
        </MDBContainer>
      ) : (
        ""
      )}
      <MDBContainer>
        <label className="details-label">Purchase information</label>

        <div className="line"></div>
        <MDBContainer className="purchase-information">
          {" "}
          <MDBRow className="margin">
            <MDBCol className="col-7">
              <p>Total Paid: </p>
            </MDBCol>
            <MDBCol className="p-right">
              <p>RM {parseFloat(total_paid).toFixed(0)}</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className="margin">
            <MDBCol className="col-7">
              <p>Payment Via : </p>
            </MDBCol>
            <MDBCol className="p-right">
              <p>{payment_method}</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className="margin">
            <MDBCol className="col-7">
              <p>Purchase on:</p>
            </MDBCol>
            <MDBCol className="p-right">
              <p>{moment(new Date(paid_at)).format("DD MMM YYYY ")}</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className="margin">
            <MDBCol className="col-7">
              <p>Use before:</p>
            </MDBCol>
            <MDBCol className="p-right">
              <p>{moment(new Date(use_before)).format("DD MMM YYYY ")}</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className="margin ">
            <MDBCol className="col-7">
              <p>Purchase reference ID:</p>
            </MDBCol>
            <MDBCol className="col-5 p-right">
              <p>{reference_no} </p>
            </MDBCol>
          </MDBRow>
          <MDBRow className="used-on">
            <p>
              Used on :
              {used_at !== null ? moment(used_at).format("DD MMM YYYY") : " - "}
            </p>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </MDBContainer>
  );
}
