import { MDBContainer } from "mdb-react-ui-kit";
import Header from "../header/header-one";
import Navbar from "../navbar/navbar-one";

export default function Layout({ home }) {
  return (
    <div className={home ? "layout-component2" : "layout-component"}>
      <MDBContainer></MDBContainer>
    </div>
  );
}
