import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/half.modal.box";
import { useFormikContext } from "formik";

export default function CategoryCard({ title, service }) {
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [selectedID, setSelectedID] = useState([]);
  const { values, setValues, setFieldValue } = useFormikContext();
  const handleClick = (item) => {
    let selectedId = [];
    values.standard.map((selectedItem) => {
      selectedId.push(selectedItem.item.id);
    });
    if (selectedId.includes(item.id)) {
      setValues({
        standard: values.standard.filter(
          (newPackage) => newPackage.item.id !== item.id
        ),
      });
      setSelectedID((preState) =>
        preState.filter((newPackage) => newPackage !== item.id)
      );
    } else {
      const newState = [...values.standard, { item }];
      setFieldValue(`standard`, [...newState]);
      selectedID.push(item.id);
    }
  };

  return (
    <MDBContainer className="service-category-card-container">
      <MDBRow className="service-category-card-titile">
        <label
          className={selectedCategory ? "active" : ""}
          onClick={() => setSelectedCategory(!selectedCategory)}
        >
          {title}
          <div></div>
        </label>
      </MDBRow>
      <MDBRow style={{ display: selectedCategory ? "flex" : "none" }}>
        {service &&
          service.map((item, index) => {
            return (
              <MDBCol
                key={index}
                className="col-6"
                onClick={() => {
                  handleClick(item);
                }}
              >
                {/* {item.status == "active" ? ( */}
                <div
                  className={
                    selectedID.includes(item.id)
                      ? "service-card active"
                      : "service-card"
                  }
                >
                  <Icon
                    icon="ic:outline-cleaning-services"
                    color="#303345"
                    width="30"
                    height="36"
                  />
                  <MDBRow>
                    <label>{item.title}</label>
                  </MDBRow>
                </div>
                {/* ) : (
                  ""
                )} */}
              </MDBCol>
            );
          })}
      </MDBRow>
    </MDBContainer>
  );
}
