import { useNavigate } from "react-router-dom";

//framework
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { Rating } from "primereact/rating";
import { Icon } from "@iconify/react";

//redux
import { useDispatch } from "react-redux";
import { openModalLogin } from "../../../redux/reducer/modalReducer";

export default function PlaceListingCard({ item, index, handleClick }) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <MDBCard
      className="card-container"
      onClick={() => {
        navigate(`/ticket/${item.id}`);
      }}
    >
      <MDBCardImage src={item.image} alt="" position="top" />
      <MDBCardBody>
        <MDBCardText>
          <MDBRow>
            <label className="title">{item.title}</label>
          </MDBRow>
          {item.rating.map((rating, index) => {
            return (
              <MDBRow className="rating-row" key={index}>
                <MDBCol className="col-5 col-md-7">
                  <label className="title-label">Google recommend</label>
                </MDBCol>
                <MDBCol className="p-0">
                  <MDBRow className="MDBRow-text">
                    <Rating
                      value={parseInt(rating.rating)}
                      start={5}
                      readOnly={true}
                      cancel={false}
                      onIcon={
                        <Icon
                          icon="ant-design:star-filled"
                          color="#ffcb46"
                          width="11"
                          height="11"
                        />
                      }
                      offIcon={
                        <Icon
                          icon="ant-design:star-filled"
                          color="#D6D6D6"
                          width="11"
                          height="11"
                        />
                      }
                    />
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            );
          })}
          <MDBRow className="title-label">
            <label>{item.description}</label>
          </MDBRow>
          <MDBRow className="badge-row d-flex align-items-start">
            {item.tag
              ? item.tag.map((tag, index) => {
                  return (
                    <MDBCol className="badge col-2 p-0" key={index}>
                      <label>{tag.text}</label>
                    </MDBCol>
                  );
                })
              : ""}
          </MDBRow>
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
}
