import {
  MDBBadge,
  MDBBtn,
  MDBCheckbox,
  MDBCol,
  MDBContainer,
  MDBRadio,
  MDBRow,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputCount from "../../element/inputCountv2";
import { Icon } from "@iconify/react";
import { handleSelectedDate } from "../../../redux/reducer/checkoutReducer";
import { useEffect } from "react";
import { useFormikContext } from "formik";
import * as Yup from "yup";
import InputCheckbox from "../../element/inputCheckbox";
import { Swiper, SwiperSlide } from "swiper/react";

export default function CheckoutDetailService({
  index,
  step,
  item,
  setValidation,
  selectedTime,
  setSelectedTime,
}) {
  // const dispatch = useDispatch()
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const [moreContent, setMoreContent] = useState(true);
  const [time, setTime] = useState([
    { value: "ASAP", label: "As soon as possible" },
    { value: "1PM", label: "1PM" },
    { value: "2PM", label: "2PM" },
    { value: "3PM", label: "3PM" },
    { value: "4PM", label: "4PM" },
    { value: "5PM", label: "5PM" },
    { value: "6PM", label: "6PM" },
    { value: "7PM", label: "7PM" },
    { value: "8PM", label: "8PM" },
    { value: "9PM", label: "9PM" },
    { value: "10PM", label: "10PM" },
    { value: "11PM", label: "11PM" },
    { value: "12PM", label: "12PM" },
  ]);

  const handleChange = (e) => {
    // setSelectedTime([e.target.value]);
    const time = e.target.value;
    setFieldValue(`visit_time.[${index}]`, time);
  };

  useEffect(() => {
    setFieldValue(`visit_time.[${index}]`, "ASAP");
  }, []);

  return (
    <MDBContainer key={index} className="content-container" breakpoint="none">
      <MDBRow className="container-subtitle">
        <label>{item.title}</label>
      </MDBRow>
      {/* <MDBRow className="badge-row">
        <MDBBadge>no refund</MDBBadge>
        <MDBBadge>no cancelled</MDBBadge>
      </MDBRow> */}
      {item.tnc ? (
        <MDBContainer className="detail-container">
          <MDBRow className="detail-row justify-content-center align-items-center">
            <label className="detail-title">Term & condition</label>
            <MDBCol
              className={moreContent ? "detail-text hideHeight" : "detail-text"}
            >
              <span> {item.tnc}</span>
            </MDBCol>
            {item.tnc.length > 50 ? (
              <label
                className="button-see-more"
                onClick={() => setMoreContent(!moreContent)}
              >
                {moreContent ? "see more" : "see less"}
              </label>
            ) : (
              ""
            )}
          </MDBRow>
        </MDBContainer>
      ) : (
        ""
      )}
      <MDBRow className="container-subtitle">
        <label>Please select a available visit time</label>
      </MDBRow>
      <MDBRow
        className={
          errors["visit_time"]
            ? "container-date-options m-0 errors-msg"
            : "container-date-options m-0"
        }
      >
        {time.map((hours, indexRadio) => (
          <MDBCol key={indexRadio}>
            <MDBRadio
              name="date"
              id={`date-${indexRadio}`}
              onChange={(e) => {
                handleChange(e);
              }}
              value={hours.value}
              label={hours.label}
              checked={hours.value == values.visit_time[index]}
              btn
            />
          </MDBCol>
        ))}
      </MDBRow>
      {errors && errors["visit_time"] && errors["visit_time"] ? (
        <div className="text-danger text-right no-padding error-message">
          {errors["visit_time"]}
        </div>
      ) : null}
      <MDBRow className="selected-package-container m-0">
        {/* <MDBRow className="selected-package-title"><label className="p-0">{item.selectedPackage.title}</label></MDBRow> */}
        <div
          className={
            errors["room_service_category_item_id"]
              ? "choose-service-card errors-msg"
              : "choose-service-card"
          }
        >
          {item &&
            item.room_service_category_service_items.map((item, index) => {
              return (
                <div key={index}>
                  {item.is_quantity == "1" ? (
                    <InputCount item={item} index={index} />
                  ) : (
                    <InputCheckbox item={item} index={index} />
                  )}
                </div>
              );
            })}
        </div>
        {errors &&
        errors["room_service_category_item_id"] &&
        errors["room_service_category_item_id"] ? (
          <div className="text-danger text-right no-padding error-message">
            {errors["room_service_category_item_id"]}
          </div>
        ) : null}
      </MDBRow>
    </MDBContainer>
  );
}
