import { FieldArray, useFormikContext } from "formik";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import {
  handleChargePrice,
  handleSelectedPackage,
} from "../../redux/reducer/checkoutReducer";
import { useEffect } from "react";
export default function InputCount({ item, index }) {
  const dispatch = useDispatch();
  const { values, setValues, setFieldValue } = useFormikContext();
  const { serviceQuantity } = useSelector((state) => state.checkout);
  const { ticket } = useSelector((state) => state.checkout.checkoutData);
  const [number, setNumber] = useState(0);

  const handleDecrement = (id) => {
    values.ticket.map((service, index) => {
      if (service.ticket_package_type_item_id == id) {
        if (number <= 1) {
          setValues({
            ...values,
            ticket: values.ticket.filter(
              (newItem) => newItem["quantity"] !== 1
            ),
          });
        } else {
          setFieldValue(`ticket[${index}]`, {
            ticket_package_type_item_id: id,
            quantity: number - 1,
            price: item.current_price,
          });
        }
      }
    });
  };

  const handleIncrement = (id) => {
    if (values.ticket.length > 0) {
      const findId = values.ticket.find(
        (obj) => obj.ticket_package_type_item_id == id
      );
      if (findId) {
        values.ticket.map((service, index) => {
          if (service.ticket_package_type_item_id == id) {
            setFieldValue(`ticket[${index}]`, {
              ticket_package_type_item_id: id,
              quantity: number + 1,
              price: item.current_price,
            });
          } else {
          }
        });
      } else {
        const data = [
          ...values.ticket,
          {
            ticket_package_type_item_id: id,
            quantity: number + 1,
            price: item.current_price,
          },
        ];
        setFieldValue(`ticket`, data);
      }
      // values.ticket.map((service, index) => {
      //   if (service.ticket_package_type_item_id == id) {
      //     setFieldValue(`ticket[${index}]`, {
      //       ticket_package_type_item_id: id,
      //       quantity: number + 1,
      //       price: item.current_price,
      //     });
      //   } else {
      //     const data = [
      //       ...values.ticket,
      //       {
      //         ticket_package_type_item_id: id,
      //         quantity: number + 1,
      //         price: item.current_price,
      //       },
      //     ];
      //     setFieldValue(`ticket`, data);
      //   }
      // });
    } else {
      const data = [
        ...values.ticket,
        {
          ticket_package_type_item_id: id,
          quantity: number + 1,
          price: item.current_price,
        },
      ];
      setFieldValue("ticket", data);
    }
  };

  return (
    <MDBRow className="count-field">
      <MDBCol className="label col-5">
        <label>{item.title}</label>
      </MDBCol>
      <MDBCol className="price p-0 col-3">
        <label>
          {item.quantity_balance <= 0
            ? "Sold Out"
            : `RM ${parseFloat(item.current_price).toFixed(2)}`}
        </label>
      </MDBCol>
      <MDBCol className="button col-4">
        <Icon
          onClick={() => {
            if (!number <= 0) {
              setNumber(number - 1);
              dispatch(
                handleChargePrice({
                  type: "decrement",
                  price: item.current_price,
                })
              );
              handleDecrement(item.ticket_package_type_item_id);
              // const ticket = {
              //   [item.ticket_package_type_item_id]: {
              //     ticket_package_type_item_id: item.ticket_package_type_item_id,
              //     quantity: number - 1,
              //     price: item.current_price,
              //   },
              // };
              // dispatch(
              //   handleSelectedPackage({
              //     type: "decrement",
              //     ticket,
              //   })
              // );
            }
          }}
          icon="fa-regular:minus-square"
          color="#707070"
          width="30"
          height="25"
        />
        <label>{number}</label>
        {number <= item.quantity_balance - 1 ? (
          <Icon
            onClick={() => {
              setNumber(number + 1);
              dispatch(
                handleChargePrice({
                  type: "increment",
                  price: item.current_price,
                })
              );
              handleIncrement(item.ticket_package_type_item_id);
              // const ticket = {
              //   [item.ticket_package_type_item_id]: {
              //     ticket_package_type_item_id: item.ticket_package_type_item_id,
              //     quantity: number + 1,
              //     price: item.current_price,
              //   },
              // };
              // dispatch(
              //   handleSelectedPackage({
              //     type: "increment",
              //     ticket,
              //   })
              // );
            }}
            icon="material-symbols:add-box-outline-rounded"
            color="#707070"
            width="30"
            height="29"
          />
        ) : (
          <Icon
            icon="material-symbols:add-box-outline-rounded"
            color="#d6d6d6"
            width="30"
            height="29"
          />
        )}
      </MDBCol>
    </MDBRow>
  );
}
