import { useState, useEffect } from "react";

//framework
import {
  MDBContainer,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";

//common component
import Header from "../../components/header/header-one";
import ModalBox from "../../components/modal/full.modal.box";
import Navbar from "../../components/navbar/navbar-one";
import Login from "../Login";
import ProfileSetting from "./component/profile.settings";
import ProfileData from "./component/profile.data";
import ProfileLanguage from "./component/profile.language";
import ProfileNotifacation from "./component/profile.notifacation";
import ProfileChangePassword from "./component/profile.changepassword";
import ModalHistory from "./component/modal.history";
import ModalReferral from "./component/modal.referral";
import ModalProfile from "./component/modal.information";
import ModalHistoryDetalis from "./component/modal.history.detalis";
import ModalReport from "./component/moodal.report";
import ModalTos from "./component/modal.tos";
import ModalPrivary from "./component/modal.privary";
import Agent from "../Agent";
import Wallet from "../Wallet";
import ModalQR from "./component/modal.qr";
import { toast } from "react-toastify";
//redux
import { useDispatch, useSelector } from "react-redux";
import { profile, balance, history } from "../../redux/reducer/profileReducer";
import { getPayment } from "../../redux/reducer/paymentReducer";
import { handleModalBoxCheckOut } from "../../redux/reducer/checkoutReducer";
import LoaderSvg from "../../assets/images/svg/loader.svg";

export default function Profile() {
  const dispatch = useDispatch();
  const { referral_code } = useParams();
  const { isOpenLogin } = useSelector((state) => state.modal);
  const { paymentUrl } = useParams();
  const [openModal, setOpenModal] = useState();
  const {
    isOpenNotifacation,
    isOpenChangePassword,
    isOpenLanguage,
    isOpenPurchaseHistory,
    isOpenReferral,
    isOpenProfile,
    isOpenHistoryDetalis,
    isOpenReport,
    isOpenTos,
    isOpenPrivary,
    isOpenAgent,
    isOpenWallet,
    isOpenQrCode,
  } = useSelector((state) => state.modal);

  const { loading, qr, copy } = useSelector((state) => state.profile);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const loader = () => {
    toast.success("Success", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  };

  useEffect(() => {
    document.body.style.background =
      "linear-gradient(180deg, #12B6DF 0%, #F8EFE4 49.48%)";
  }, []);

  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#12B6DF");
    // .setAttribute("content", "#fafbfb");
  }

  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  useEffect(() => {
    dispatch(profile());
    dispatch(balance());
    scrollToTop();
  }, []);

  return (
    <div>
      {copy && (
        <div className="copy-container">
          <div className="copy-background">
            <label>Copied</label>
          </div>
        </div>
      )}
      <MDBContainer className="profile-container" breakpoint="none">
        <Header />
        <Navbar />
        <h1 className="header">My Information</h1>
        <div className="fixed-container" breakpoint="none">
          {loading ? (
            <div className="loader-container">
              <img src={LoaderSvg} height="50" width="50" />
            </div>
          ) : (
            <>
              <ProfileData />
              <ProfileSetting />
            </>
          )}
        </div>
        {/* <div className="content-component" data-aos="fade-up"></div> */}
      </MDBContainer>
      {isOpenLanguage && <ProfileLanguage />}
      {isOpenNotifacation && <ProfileNotifacation />}
      {isOpenChangePassword && <ProfileChangePassword />}
      {isOpenPurchaseHistory && <ModalHistory />}
      {isOpenReferral && <ModalReferral />}
      {isOpenProfile && <ModalProfile />}
      {isOpenHistoryDetalis && <ModalHistoryDetalis />}
      {isOpenReport && <ModalReport />}
      {isOpenTos && <ModalTos />}
      {isOpenPrivary && <ModalPrivary />}
      {isOpenAgent && <Agent />}
      {isOpenWallet && <Wallet />}
      {isOpenQrCode && <ModalQR qr={qr} />}
      {isOpenLogin && (
        <Login referral_code={referral_code} show={isOpenLogin} />
      )}
    </div>
  );
}
