import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function Operation() {
  const { open_time, close_time } = useSelector(
    (state) => state.detail.ticketInfo
  );
  const { loading } = useSelector((state) => state.detail);

  return (
    <MDBCol className="operation-container col-12">
      {loading ? (
        ""
      ) : (
        <>
          {open_time === null ? (
            ""
          ) : (
            <>
              <MDBRow className="container-title">
                <label>
                  <div></div>Operation
                </label>
              </MDBRow>
              <MDBContainer className="content-container">
                <MDBRow className="p-1">
                  <label className="d-flex">
                    <Icon
                      icon="ant-design:field-time-outlined"
                      width="20"
                      height="18"
                    />
                    Opening Time {open_time}
                  </label>
                </MDBRow>
                <MDBRow className="p-1">
                  <label className="d-flex">
                    <Icon
                      icon="ant-design:field-time-outlined"
                      width="20"
                      height="18"
                    />
                    Closing Time {close_time}
                  </label>
                </MDBRow>
              </MDBContainer>
            </>
          )}
        </>
      )}
    </MDBCol>
  );
}
