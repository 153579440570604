import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/full.modal.box";
import { RemoveScroll } from "react-remove-scroll";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";

import Header from "../../../components/header/header-five";

export default function ProfileNotifacation() {
  const navigate = useNavigate();
  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="profile-modal-container">
            <Header title={"Notifacation Setting"} label="save" />
            <MDBContainer className="setting">
              <label className="setting-label">Updates and Promotion</label>
              <p>
                Be the first to know about new features,promo codes and deals
              </p>
              <MDBRow className="notifacation-radio">
                <MDBCol className="col-10">
                  <label>Email</label>
                </MDBCol>
                <MDBCol className="col-2">
                  <MDBRadio name="inlineRadio" id="email1" />
                </MDBCol>
              </MDBRow>
              <MDBRow className="notifacation-radio">
                <MDBCol className="col-10">
                  <label>SMS</label>
                </MDBCol>
                <MDBCol className="col-2">
                  <MDBRadio name="inlineRadio" id="sms1" />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <MDBContainer className="setting">
              <label className="setting-label">Account Notifacation</label>
              <p>
                For important notifications on order summaries, payment,
                refferal friends
              </p>
              <MDBRow className="notifacation-radio">
                <MDBCol className="col-10">
                  <label>Email</label>
                </MDBCol>
                <MDBCol className="col-2">
                  <MDBRadio name="inlineRadio" id="email" />
                </MDBCol>
              </MDBRow>
              <MDBRow className="notifacation-radio">
                <MDBCol className="col-10">
                  <label>SMS</label>
                </MDBCol>

                <MDBCol className="col-2">
                  <MDBRadio name="inlineRadio" id="sms" />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
