import { MDBCard, MDBContainer, MDBRow } from "mdb-react-ui-kit";

import ActivityListingCard from "./activity.listing.card";
import { Activity1, Activity2, Activity3 } from "../../../assets/images";
import LoaderSvg from "../../../assets/images/svg/loader.svg";
import { useSelector } from "react-redux";
export default function ActivityListing() {
  const { ticket } = useSelector((state) => state.activity);
  const { loading } = useSelector((state) => state.activity);

  return (
    <MDBContainer className="activity-listing-container">
      {loading ? (
        <div className="loader-container">
          <img src={LoaderSvg} height="50" width="50" />
        </div>
      ) : (
        <MDBRow className="activity-listing-row m-0">
          {ticket
            ? ticket.map((item, index) => {
                return (
                  <>
                    <ActivityListingCard item={item} index={index} />
                  </>
                );
              })
            : ""}
        </MDBRow>
      )}
    </MDBContainer>
  );
}
