import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ModalBox from "../../../components/modal/full.modal.box";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";

import Header from "../../../components/header/header-five";

export default function ProfileLanguage() {
  const [language, setLanguage] = useState("english");
  const navigate = useNavigate();

  const changeLanguage = (e) => {
    const useLanguage = e.target.value;
    setLanguage(useLanguage);
  };
  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="profile-modal-container">
            <Header title={"Language"} label="Save" />
            <MDBContainer className="language-container">
              <MDBRow
                className={
                  language === "melayu"
                    ? "language-radio active"
                    : "language-radio"
                }
              >
                <MDBCol className="col-10">
                  <label
                    className={language === "melayu" ? "label active" : "label"}
                  >
                    Bahasa Malaysia
                  </label>
                </MDBCol>

                <MDBCol className="col-2">
                  <MDBRadio
                    name="flexRadioDefault"
                    value="melayu"
                    checked={language === "melayu"}
                    onChange={(e) => changeLanguage(e)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow
                className={
                  language === "chinese"
                    ? "language-radio active"
                    : "language-radio"
                }
              >
                <MDBCol className="col-10">
                  <label
                    className={
                      language === "chinese" ? "label active" : "label"
                    }
                  >
                    简体中文
                  </label>
                </MDBCol>
                <MDBCol className="col-2">
                  <MDBRadio
                    name="flexRadioDefault"
                    value="chinese"
                    checked={language === "chinese"}
                    onChange={(e) => changeLanguage(e)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow
                className={
                  language === "english"
                    ? "language-radio active"
                    : "language-radio"
                }
              >
                <MDBCol className="col-10">
                  <label
                    className={
                      language === "english" ? "label active" : "label"
                    }
                  >
                    English (international)
                  </label>
                </MDBCol>

                <MDBCol className="col-2">
                  <MDBRadio
                    name="flexRadioDefault"
                    value="english"
                    checked={language === "english"}
                    onChange={(e) => changeLanguage(e)}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
