import apiServices from "../../services/api.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const service_history = createAsyncThunk(
  "get/service_history",
  async () => {
    const response = await apiServices.getRoomServiceHistory();
    return response;
  }
);

const initialState = {
  history: [],
  loading: false,
};

const service_historySlice = createSlice({
  name: "service_history",
  initialState,
  extraReducers: {
    [service_history.pending]: (state, action) => {
      state.loading = true;
    },
    [service_history.fulfilled]: (state, action) => {
      state.loading = false;
      state.history = action.payload.aaData;
    },
  },
});

const { reducer } = service_historySlice;
export default reducer;
