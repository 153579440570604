import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBContainer,
  MDBFile,
} from "mdb-react-ui-kit";
import { Field, ErrorMessage, useFormikContext } from "formik";
import VerficationCodeButton from "./verfication.code.button";

export default function InputField({
  name,
  type,
  placeholder,
  className,
  vcode,
  label,
  sendCode,
  disabled,
  handleChange,
  setFieldValue,
  codeMessage,
  message,
  time,
  hidden,
}) {
  const { values, errors } = useFormikContext();
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <div>
      <MDBCol
        className={
          errors[name]
            ? `form-group error-border ${className}`
            : `form-group ${className}`
        }
      >
        <MDBRow>
          {handleChange ? (
            <Field
              as={MDBInput}
              name={name}
              type={type}
              label={placeholder}
              onKeyDown={(e) =>
                type === "number" &&
                exceptThisSymbols.includes(e.key) &&
                e.preventDefault()
              }
              onChange={() => handleChange(setFieldValue)}
              disabled={disabled}
            />
          ) : type === "dob" ? (
            <Field
              as={MDBInput}
              name={name}
              type="date"
              label={placeholder}
              onKeyDown={(e) =>
                type === "number" &&
                exceptThisSymbols.includes(e.key) &&
                e.preventDefault()
              }
              disabled={disabled}
            />
          ) : (
            <Field
              as={MDBInput}
              name={name}
              type={type}
              label={placeholder}
              onKeyDown={(e) =>
                type === "number" &&
                exceptThisSymbols.includes(e.key) &&
                e.preventDefault()
              }
              disabled={disabled}
            />
          )}
        </MDBRow>
      </MDBCol>
      <MDBContainer className="error-message-line">
        <MDBRow className="error-message-row">
          <MDBCol className={codeMessage === true ? "col-6" : ""}>
            {errors && errors[name] ? (
              <div className="text-danger text-right no-padding error-message form-group">
                {errors[name]}
              </div>
            ) : null}
          </MDBCol>
          {codeMessage === true ? (
            <MDBCol className="col-6">
              <span className="code-time">Resend code in {time}s</span>
            </MDBCol>
          ) : message === true ? (
            <MDBCol className="col-6">
              <span className="code-time">Resend code in {time}s</span>{" "}
            </MDBCol>
          ) : (
            ""
          )}
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
